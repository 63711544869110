import { UPDATE_SOCKET_STATUS } from './socket.actions';

const defaultState = {
  connected: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SOCKET_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
