import './IPsecConfig.module.less';

import InfoItem from 'components/InfoItem/InfoItem';
import _get from 'lodash/get';
import _upperFirtst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import React from 'react';

import { THIN_EDGE_ADVANCE, THIN_EDGE_PRO } from './constant';
import LocationList from './Location/LocationList';

const Review = ({ data, readOnly, ...styleOptions }) => {
  const type = _get(data, 'type', 'express');

  return (
    <div {...styleOptions}>
      <InfoItem readOnly={readOnly} label="Type" value={_upperFirtst(type)} />
      <InfoItem
        readOnly={readOnly}
        label="Apply Security Policy"
        value={_get(data, 'bound_firewall_policy') ? 'Enabled' : 'Disabled'}
      />
      <InfoItem readOnly={readOnly} label="Locations" />
      <LocationList data={_get(data, 'cloud.interface', [])} type={type} editable={false} />

      {/* {type === THIN_EDGE_PRO && (
        <InfoItem readOnly={readOnly} label="LAN IP/CIDR" value={_get(config, 'cpe_network')} />
      )}

      {type === THIN_EDGE_ADVANCE && (
        <>
          <InfoItem readOnly={readOnly} label="SASE Gateway IP/CIDR" value={_get(config, 'ip')} />
          <InfoItem
            readOnly={readOnly}
            label="DHCP Server"
            value={_get(config, 'dhcp_enable') ? 'Enabled' : 'Disabled'}
          />
          <InfoItem
            readOnly={readOnly}
            label="Address Range"
            value={
              <div>
                {_get(config, 'dhcp_server.ip_range', []).map(({ start_ip, end_ip }, index) => (
                  <p key={index}>{`${start_ip} - ${end_ip}`}</p>
                ))}
              </div>
            }
          />
          <InfoItem readOnly={readOnly} label="DNS Server" value={_get(config, 'dhcp_server.dns_service')} />
        </>
      )} */}
    </div>
  );
};

Review.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Review;
