import React, { Children, Fragment } from 'react';
import { isFragment } from 'react-is';

export const getChildren = (children, mapper) => {
  const childList = isFragment(children) ? children.props.children : children;
  const newChildren = Children.map(childList, mapper);
  if (isFragment(children)) {
    return <Fragment>{newChildren}</Fragment>;
  }
  return newChildren;
};
