import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAuthenticate } from 'redux/GlobalSetting/globalSetting.actions';
import { defaultRoute, loginPath } from 'routeConfig';
import bgWrapper from 'utils/bgWrapper';
import { getCookie, saveToken } from 'utils/util';

const PrePortal = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const setAuthentication = useCallback(
    isAuthenticated => dispatch(setAuthenticate(isAuthenticated)),
    [dispatch]
  );

  useEffect(() => {
    const tokenObj = {
      access_token: getCookie('access_token'),
      refresh_token: getCookie('refresh_token'),
    };

    if (!tokenObj.access_token) {
      history.push(loginPath);
      return;
    }

    const redircetPath = defaultRoute;
    saveToken(tokenObj);
    setAuthentication(true);
    history.push(redircetPath);
  }, [history, setAuthentication]);

  return null;
};

PrePortal.propTypes = {};

export default bgWrapper(PrePortal);
