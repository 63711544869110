import './InfoItem.module.less';

import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useState } from 'react';

// used to display site property in a user-friendly way
const InfoItem = props => {
  const {
    label,
    value,
    editable,
    align,
    editIcon,
    inputElement,
    onSave,
    readOnly,
    ...options
  } = props;
  const [editMode, setEditMode] = useState(false);

  const handleSave = useCallback(() => {
    _isFunction(onSave) && onSave().then(_ => setEditMode(false));
  }, [onSave]);

  return (
    <div
      styleName={clsx(['info-item', readOnly && 'read-only'], [options.styleName])}
      className={clsx(['flex-row', 'space-between'], [options.className])}
      style={options.style}
    >
      <Typography.Text>{label && <>{label}:</>}</Typography.Text>
      <div className="flex-row flex-item">
        <div
          styleName="content"
          className={align === 'right' ? 'flex-item' : ''}
          style={{ textAlign: align }}
        >
          {editMode ? inputElement : value}
        </div>

        <div styleName={'actions'}>
          {editable && !editMode && (
            <Tooltip title="Edit">
              <span
                styleName="edit-btn"
                style={_isEmpty(value) ? {} : { marginLeft: 16 }}
                onClick={() => setEditMode(true)}
              >
                {editIcon || <EditOutlined />}
              </span>
            </Tooltip>
          )}
          {editable && editMode && (
            <Fragment>
              <Button styleName="save-btn" size="small" type="primary" onClick={() => handleSave()}>
                Save
              </Button>
              <Button styleName="cancel-btn" size="small" onClick={() => setEditMode(false)}>
                Cancel
              </Button>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

InfoItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  editable: PropTypes.bool,
  editIcon: PropTypes.element,
  inputElement: PropTypes.element,
  onSave: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default InfoItem;
