import './FederatedSitesConfig.module.less';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import Wizard from 'components/Wizard';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import IPsecConfig from 'pages/Security/shared/IPsecVPN/IPsecConfig';
import { checkVpnData } from 'pages/Security/shared/IPsecVPN/IPsecConfig/IPsecConfig.service';
import IPsecConfigReview from 'pages/Security/shared/IPsecVPN/IPsecConfig/Review';
import { reviewSiteData } from 'pages/Security/shared/site.service';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const IPsecConfigModal = ({ visible, onOk, onCancel, onInterrupt, data, ...options }) => {
  const ipsecConfigRef = useRef();
  const [currentStep, setCurrentStep] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (_isEmpty(data)) {
      setFormData({});
    } else {
      setFormData(data);
    }
  }, [data]);

  const validateForm = useCallback(() => {
    return new Promise((resolve, reject) => {
      ipsecConfigRef.current &&
        ipsecConfigRef.current.validateFields((errors, values) => {
          if (!errors) {
            const payload = checkVpnData({
              ipsecgateway_enable: true,
              sslgateway_enable: true,
              ipsecgateway_customer_config: {
                cloud: {
                  interface: [values],
                },
              },
            });

            reviewSiteData(payload).subscribe(
              result => {
                setFormData(_get(result, 'ipsecgateway_customer_config'));
              },
              err => console.log({ err })
            );

            return resolve();
          }
          return reject();
        });
    });
  }, []);

  const steps = useMemo(
    () => [
      {
        title: 'VxLAN Interface',
        content: (
          <IPsecConfig
            className="flex-item"
            ref={ipsecConfigRef}
            data={_get(formData, 'cloud.interface[0]')}
            onChange={() => setIsChanged(true)}
          />
        ),
        validator: validateForm,
      },
      {
        title: 'Review',
        content: (
          <IPsecConfigReview
            className="flex-item"
            style={{ overflow: 'auto', margin: '0 32px' }}
            data={formData}
            readOnly
          />
        ),
      },
    ],
    [formData, validateForm]
  );

  const handleSubmit = useCallback(() => {
    onOk(formData);
  }, [formData, onOk]);

  const handleCancel = useCallback(() => {
    if (isChanged) {
      Modal.confirm({
        title: 'Confirm',
        content: 'You have unsaved changes. Are you sure you want to discard these changes?',
        okText: 'Discard',
        onOk: onCancel,
        centered: true,
        maskClosable: false,
      });
    } else {
      onCancel();
    }
    setIsChanged(false);
  }, [isChanged, onCancel]);

  return (
    <Modal
      title={'IPsec VPN Configuration'}
      centered
      autoFocusButton={false}
      maskClosable={false}
      destroyOnClose
      width={760}
      bodyStyle={{ height: '80vh', display: 'flex', flexDirection: 'column' }}
      okText={'Save'}
      footer={null}
      visible={visible}
      onCancel={handleCancel}
    >
      <Wizard
        steps={steps}
        currentStep={currentStep}
        trackerOptions={{ size: 'small' }}
        onStepChange={val => setCurrentStep(val)}
        styleName="wizard"
      >
        {
          <Wizard.Actions style={{ paddingTop: 16 }}>
            <Button action="prev">
              <LeftOutlined style={{ fontSize: 12 }} />
              &nbsp;Prev
            </Button>
            <Button type="primary" action="next">
              {currentStep === steps.length - 2 ? (
                'Review'
              ) : (
                <Fragment>
                  Next &nbsp;
                  <RightOutlined style={{ fontSize: 12 }} />
                </Fragment>
              )}
            </Button>
            {currentStep === steps.length - 1 && (
              <Button type="primary" action="confirm" onClick={handleSubmit}>
                Save
              </Button>
            )}
          </Wizard.Actions>
        }
      </Wizard>
    </Modal>
  );
};

IPsecConfigModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default IPsecConfigModal;
