import request from './request';

const resource = '/v1/system';

function getSystemVersion(...cb) {
  return request(
    {
      url: `${resource}/version`,
    },
    ...cb
  );
}

function sendEmail(type, paylaod, ...cb) {
  return request(
    {
      url: `${resource}/mails/send`,
      method: 'post',
      data: {
        type,
        ...paylaod,
      },
    },
    ...cb
  );
}

function getDownloadLinks() {
  return request({
    url: `/v1/portal/app-stores/ems`,
  });
}

export default {
  getSystemVersion,
  sendEmail,
  getDownloadLinks,
};
