import produce from 'immer';

import {
  UPDATE_LOADING,
  UPDATE_SITE_LIST,
  UPDATE_SITE_LIST_PAGE,
  UPDATE_SITE_LIST_SORT,
  UPDATE_SITE_LIST_TOTAL,
  UPDATE_SITE_REMAINING_SEATS,
  UPDATE_SITE_STATUS,
  UPDATE_TURBO_SITE_REMAINING_SEATS,
} from './Security.actions';

const defaultState = {
  loading: false,
  sort: '-_created',
  total: 0,
  currentPage: 1,
  pageSize: Number.MAX_SAFE_INTEGER,
  remainingSeats: 0,
  remainingTurboSeats: 0,
  sites: [],
};

export default (state = defaultState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case UPDATE_LOADING:
      case UPDATE_SITE_LIST_PAGE:
      case UPDATE_SITE_LIST_SORT:
      case UPDATE_SITE_LIST_TOTAL:
      case UPDATE_SITE_REMAINING_SEATS:
      case UPDATE_TURBO_SITE_REMAINING_SEATS:
      case UPDATE_SITE_LIST:
        return {
          ...state,
          ...action.payload,
        };
      case UPDATE_SITE_STATUS:
        const { sites } = draft;
        try {
          const index = sites.findIndex(item => item.id === action.payload.id);
          if (
            index > -1 &&
            Date.parse(action.payload._updated) >= Date.parse(sites[index]._updated)
          ) {
            sites[index] = { ...sites[index], ...action.payload };
          }
        } catch (err) {
          console.log({ err });
        }
        break;
      default:
        return state;
    }
  });
