import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@remote-user-portal/shared/constants';

import {
  REMOTE_USER_LOGOUT,
  UPDATE_AUTHENTICATED,
  UPDATE_TOKEN_EXPIRED,
  UPDATE_TOKENS,
} from './auth.actions';

const defaultState = {
  tokenExpired: false,
  accessToken: sessionStorage.getItem(ACCESS_TOKEN_KEY) || '',
  refreshToken: sessionStorage.getItem(REFRESH_TOKEN_KEY) || '',
  isAuthenticated: !!sessionStorage.getItem(ACCESS_TOKEN_KEY),
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REMOTE_USER_LOGOUT:
      sessionStorage.removeItem(ACCESS_TOKEN_KEY);
      sessionStorage.removeItem(REFRESH_TOKEN_KEY);
      return {
        ...state,
        tokenExpired: false,
        accessToken: '',
        refreshToken: '',
        isAuthenticated: false,
      };
    case UPDATE_TOKENS:
      const { accessToken, refreshToken } = action.payload;
      sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_TOKEN_EXPIRED:
    case UPDATE_AUTHENTICATED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
