import './AssignApplicationsModal.module.less';

import { Collapse, List } from 'antd';
import React, { Component } from 'react';

import { getDefaultIcon } from '../siteAppUtils';

const { Panel } = Collapse;

class AssignComfirmList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { apps, users, appUserMap } = this.props;

    return (
      <div styleName="assign-confirm-container">
        <Collapse defaultActiveKey={apps.map(item => item.id)}>
          {apps.map(appItem => {
            const assignedUsers = (appUserMap[appItem.id] || []).map(userId =>
              users.find(userItem => userItem.id === userId)
            );
            return (
              <Panel
                header={
                  <div>
                    <img
                      src={appItem.icon || getDefaultIcon(appItem.type)}
                      alt={appItem.name}
                      style={{ width: 32, height: 24 }}
                    />
                    <h3 style={{ display: 'inline-block', marginLeft: 16 }}>{appItem.name}</h3>
                  </div>
                }
                key={appItem.id}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={assignedUsers}
                  rowKey="id"
                  locale={{ emptyText: 'no users assigned' }}
                  renderItem={item => (
                    <List.Item>
                      <span>
                        <span styleName="confirm-user-name">{item.user_name}</span>
                        <span>{item.email}</span>
                        {/* <List.Item.Meta
                          title={item.user_name}
                          description={
                            <div styleName="user-desc">
                              <p>Email: {item.email}</p>
                              <p>Group: {item.group}</p>
                            </div>
                          }
                        /> */}
                      </span>
                    </List.Item>
                  )}
                />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    );
  }
}

export default AssignComfirmList;
