/**
 * di song
 * 06/25/2019
 * for filter
 */
export const FILTER_EQ = 'eq';
export const FILTER_NEQ = 'neq';
export const FILTER_LIKE = 'like';

export class QueryFormat {
  constructor() {
    this._page = 1;
    this._maxResults = 25;
    this._filters = [];
    this._sort = '-_created';
    this._field = '*';
  }

  set page(page) {
    this._page = page;
  }
  get page() {
    return this._page;
  }
  set maxResults(maxResults) {
    this._maxResults = maxResults;
  }
  get maxResults() {
    return this._maxResults;
  }
  set sort(sort) {
    this._sort = sort;
  }
  get sort() {
    return this._sort;
  }
  set field(field) {
    this._field = field;
  }
  get field() {
    return this._field;
  }
  set filters(filters) {
    this._filters = filters;
  }
  get filters() {
    return this._filters;
  }
  appendEqFilter(name, value) {
    this.appendFilter(name, value, FILTER_EQ);
  }
  appendNEqFilter(name, value) {
    this.appendFilter(name, value, FILTER_NEQ);
  }
  appendLikeFilter(name, value) {
    this.appendFilter(name, value, FILTER_LIKE);
  }
  appendFilter(name, value, op) {
    this._filters.push({
      name: name,
      op: op,
      val: value,
    });
  }
  removeFilter(index) {
    this._filters.splice(index, 1);
  }
  clearFilter() {
    this._filters.length = 0;
  }

  toJson() {
    return {
      q: {
        filters: this.filters,
        max_results: this.maxResults,
        page: this.page,
        sort: this.sort,
        field: this.field,
      },
    };
  }
  toString() {
    return JSON.stringify(this.toJson());
  }
}
