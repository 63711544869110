import './SiteCard.module.less';

import {
  AppstoreOutlined,
  AuditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  ImportOutlined,
  LoadingOutlined,
  ProfileOutlined,
  RedoOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import defaultSnapshot from 'assets/images/default-site.png';
import failedSnapshot from 'assets/images/default-site-failed.png';
import FailureDetail from 'components/FailureDetail';
import en from 'Languages/en.json';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RESOURCE_STATUS } from 'utils/constant';
import usePermissions from 'utils/hooks/usePermissions';
import useUserRole from 'utils/hooks/useUserRole';
import {
  getSiteStatus,
  getSiteStatusBadge,
  getSubjectStatus,
  getSubjectStatusLabel,
  getTypeIcon,
} from 'utils/services/site.service';
import { decompressSnapshot } from 'utils/util';

const SiteCard = props => {
  const tenantUser = useSelector(state => state.globalSetting.user);
  const {
    data,
    onEnterSite,
    onAccessAuditLog,
    onAccessSiteInfo,
    onEditSite,
    onAddAppForSite,
    onDeleteSite,
    onRecreate,
  } = props;
  const role = useUserRole();
  const permissions = usePermissions(role);
  const applicationAuth = _get(
    tenantUser,
    'tenant.company.license.security_entitlement.application',
    {}
  );

  const subjectStatus = getSubjectStatus(data);
  const subjectStatusLabel = getSubjectStatusLabel(data);
  const siteStatus = getSiteStatus(data);

  const siteStatusBadge = getSiteStatusBadge(data);

  const typeIcon = getTypeIcon(data);

  const allowEnter = permissions.security.enterSite && RESOURCE_STATUS.isRunning(subjectStatus);
  const allowRecreate = permissions.security.createSite && data.is_recreatable;
  const allowDelete = permissions.security.terminateSite && !RESOURCE_STATUS.isPending(siteStatus);
  const isDeleting = data.status === 'delete' && RESOURCE_STATUS.isPending(siteStatus);
  const isPending = RESOURCE_STATUS.isPending(siteStatus);
  const allowAccessInfo = !isDeleting && RESOURCE_STATUS.isRunning(subjectStatus);

  let subjectInfo;
  switch (true) {
    case RESOURCE_STATUS.isRunning(subjectStatus):
      subjectInfo = null;
      break;
    case isPending:
      subjectInfo = (
        <Fragment>
          <LoadingOutlined />
          &nbsp;
          {subjectStatusLabel ? subjectStatusLabel.replace(/-/g, ' ') : _startCase(subjectStatus)}
        </Fragment>
      );
      break;
    case RESOURCE_STATUS.isFailed(subjectStatus):
      subjectInfo = (
        <Fragment>
          {_startCase(subjectStatus)}&nbsp;
          <FailureDetail
            onClick={e => e && e.stopPropagation()}
            iconStyle={{ color: 'white' }}
            data={{
              resourceFailedType: data.resource_failed_type,
              resourceFailedMessage: data.resource_failed_message,
              timestamp: data._updated,
              action: data.resource_status,
              isRecreatable: data.is_recreatable,
            }}
            onRecreate={() => onRecreate(data)}
          ></FailureDetail>
        </Fragment>
      );
      break;
    default:
  }

  const snapshot = RESOURCE_STATUS.isFailed(subjectStatus)
    ? failedSnapshot
    : data.snapshot
    ? decompressSnapshot(data.snapshot)
    : defaultSnapshot;

  const handleEnterSite = useCallback(
    e => {
      e && e.stopPropagation();
      allowEnter && onEnterSite(data);
    },
    [allowEnter, data, onEnterSite]
  );

  const handleEditSite = useCallback(
    e => {
      e && e.stopPropagation();
      !isDeleting && onEditSite(data);
    },
    [data, isDeleting, onEditSite]
  );

  const handleAddAppForSite = useCallback(
    e => {
      e && e.stopPropagation();
      !isDeleting && allowEnter && onAddAppForSite(data);
    },
    [data, isDeleting, allowEnter, onAddAppForSite]
  );

  const handleAccessNetworkInfo = useCallback(
    e => {
      e && e.stopPropagation();
      allowAccessInfo && onAccessSiteInfo(data);
    },
    [allowAccessInfo, data, onAccessSiteInfo]
  );

  const handleAccessAuditLog = useCallback(
    e => {
      e && e.stopPropagation();
      onAccessAuditLog(data);
    },
    [data, onAccessAuditLog]
  );

  const handleRecreateSite = useCallback(
    e => {
      e && e.stopPropagation();
      allowRecreate && onRecreate(data);
    },
    [allowRecreate, data, onRecreate]
  );

  const handleTerminateSite = useCallback(
    e => {
      e && e.stopPropagation();
      allowDelete && onDeleteSite(data);
    },
    [allowDelete, data, onDeleteSite]
  );

  const secondaryActions = (
    <Menu onClick={({ domEvent }) => domEvent.stopPropagation()}>
      {applicationAuth.visibility && (
        <Menu.Item
          disabled={!allowEnter || isDeleting}
          onClick={({ domEvent }) => handleAddAppForSite(domEvent)}
          data-testid="site-add-app-btn"
        >
          <AppstoreOutlined />
          <span>Applications</span>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={({ domEvent }) => handleAccessAuditLog(domEvent)}
        data-testid="site-audit-btn"
      >
        <AuditOutlined />
        <span>Audit Log</span>
      </Menu.Item>

      <Menu.Item
        disabled={!allowRecreate}
        onClick={({ domEvent }) => handleRecreateSite(domEvent)}
        data-testid="recreate-site-btn"
      >
        <RedoOutlined />
        <span>{`Recreate ${en.TERMS.SITE}`}</span>
      </Menu.Item>

      <Menu.Item
        disabled={!allowDelete}
        onClick={({ domEvent }) => handleTerminateSite(domEvent)}
        data-testid="delete-site-btn"
      >
        <StopOutlined />
        <span>{`${en.ACTIONS.TERMINATE} ${en.TERMS.SITE}`}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div styleName="site-card" data-testid="site-card" onClick={handleEnterSite}>
      <div styleName="site-meta" data-testid="site-card-meta">
        <div>
          <span styleName="type-icon">{typeIcon}</span>
          <span styleName="title" className="ellipsis">
            {data.name}
          </span>
        </div>

        <div styleName="status" className="ellipsis">
          <span styleName="badge">{siteStatusBadge}</span>
          <span styleName="label">{data.state_label}</span>
        </div>
      </div>

      <div styleName="site-screenshot">
        {(isPending ||
          RESOURCE_STATUS.isStopped(subjectStatus) ||
          RESOURCE_STATUS.isFailed(subjectStatus)) && (
          <div
            styleName="mask"
            data-testid="site-card-mask"
            className="flex-row main-center cross-center"
          >
            {subjectInfo}
          </div>
        )}
        <img
          data-testid="site-card-screenshot"
          src={snapshot}
          alt="snapshot"
          style={{ width: '100%' }}
        />
      </div>

      <div
        styleName="site-actions"
        data-testid="site-card-actions"
        onClick={e => e && e.stopPropagation()}
      >
        <Tooltip title={`Enter ${en.TERMS.SITE}`}>
          <span
            styleName="action"
            className={!allowEnter && 'disabled'}
            onClick={handleEnterSite}
            data-testid="enter-site-btn"
          >
            <ImportOutlined />
          </span>
        </Tooltip>

        <Tooltip title={`${en.TERMS.SITE} Detail`}>
          <span
            styleName="action"
            className={isDeleting && 'disabled'}
            onClick={handleEditSite}
            data-testid="site-detail-btn"
          >
            <ProfileOutlined />
          </span>
        </Tooltip>

        <Tooltip title={en.TERMS.SITE_INFO}>
          <span
            styleName="action"
            className={!allowAccessInfo && 'disabled'}
            onClick={handleAccessNetworkInfo}
            data-testid="site-access-info-btn"
          >
            <ExclamationCircleOutlined />
          </span>
        </Tooltip>

        <Dropdown overlay={secondaryActions} onClick={e => e && e.stopPropagation()}>
          <span styleName="action" data-testid="secondary-actions-btn">
            <EllipsisOutlined />
          </span>
        </Dropdown>
      </div>
    </div>
  );
};

SiteCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEnterSite: PropTypes.func.isRequired,
  onEditSite: PropTypes.func.isRequired,
  onAddAppForSite: PropTypes.func.isRequired,
  onAccessAuditLog: PropTypes.func.isRequired,
  onAccessSiteInfo: PropTypes.func.isRequired,
  onDeleteSite: PropTypes.func.isRequired,
  onRecreate: PropTypes.func.isRequired,
};

export default SiteCard;
