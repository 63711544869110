import './FederatedSitesConfig.module.less';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { DEFAULT_SLAVES_QUOTA } from 'utils/constant';
import { defaultSorter } from 'utils/util';

const SiteConfigForm = forwardRef(({ data, onChange, cloudRegions, quota, onIpsecToggle }, ref) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // if count of slaves exceed the quota, set field to error
    const errors = form.getFieldError('count') || [];
    const quotaError = new Error(`The limit of federated sites is ${DEFAULT_SLAVES_QUOTA}`);
    if (quota < 0 && !errors.includes(quotaError)) {
      form.setFields([
        {
          name: 'count',
          errors: [...errors, quotaError],
        },
      ]);
    }
    if (quota >= 0) {
      form.setFields([
        {
          name: 'count',
          errors: null,
        },
      ]);
    }
  }, [form, quota]);

  return (
    <Form
      ref={ref}
      form={form}
      layout="inline"
      className="flex-row"
      styleName="slave-item-field"
      initialValues={data || {}}
      onValuesChange={(changedValues, allValues) => {
        onChange({ ...data, ...allValues });
      }}
    >
      <Form.Item
        key="region"
        name="region"
        rules={[{ required: true, message: 'Select a Cloud Region' }]}
        style={{ width: 225 - 16 }}
      >
        <Select placeholder="Select a Cloud Region" style={{ width: 225 - 16 }}>
          {[...cloudRegions].sort(defaultSorter('alias')).map((region, index) => (
            <Select.Option key={index} value={region.name} onChange={value => onChange}>
              <Tooltip>
                <span style={{ display: 'inline-block', width: '100%' }}>{region.alias}</span>
              </Tooltip>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        key="count"
        name="count"
        style={{ width: 64 }}
        rules={[{ required: true, message: 'Minimum is 1' }]}
      >
        <InputNumber min={1} style={{ width: 64 }} />
      </Form.Item>
    </Form>
  );
});

const SiteConfigField = forwardRef(
  ({ data, cloudRegions, onChange, onRemove, quota, removable }, ref) => {
    const formRef = useRef();

    const validateFields = useCallback(cb => {
      return formRef.current && formRef.current.validateFields();
    }, []);

    useImperativeHandle(ref, () => ({
      validateFields,
    }));

    return (
      <div className="flex-row space-between">
        <div className="flex-row">
          <SiteConfigForm
            ref={formRef}
            data={data}
            cloudRegions={cloudRegions}
            quota={quota}
            onChange={onChange}
          />
        </div>
        {removable && (
          <Button
            ghost
            size="small"
            type="danger"
            icon={<CloseOutlined />}
            style={{ marginTop: 8, marginLeft: 16 }}
            onClick={onRemove}
          />
        )}
      </div>
    );
  }
);

SiteConfigField.propTypes = {
  data: PropTypes.object.isRequired,
  cloudRegions: PropTypes.array.isRequired,
  quota: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  removable: PropTypes.bool,
};

export default SiteConfigField;
