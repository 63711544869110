import { fetchWrap } from 'utils/fetch';

const resource = '/v1/setting/permission/api-tokens';

function createAccessToken(scopeData, ...cb) {
  return fetchWrap(
    {
      url: resource,
      method: 'post',
      data: scopeData,
    },
    ...cb
  );
}

function getAccessTokens(params = {}, ...cb) {
  const defaultParams = { page: 1, max_results: 25, sort: '-_created' };
  let queryString = '';

  try {
    queryString = JSON.stringify({
      ...defaultParams,
      ...params,
    });
  } catch (e) {
    console.error('invalid json');
  }

  return fetchWrap(
    {
      url: resource,
      params: {
        q: queryString,
      },
    },
    ...cb
  );
}

function deleteAccessToken(tokenName, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${tokenName}`,
      method: 'delete',
    },
    ...cb
  );
}

export default { createAccessToken, getAccessTokens, deleteAccessToken };
