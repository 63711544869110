import { fetchWrap } from 'utils/fetch';

const resource = '/v1/analytics/logs';

function createLog(logSettingData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}`,
      method: 'post',
      data: logSettingData,
    },
    ...cb
  );
}

function disableLog(...cb) {
  return fetchWrap(
    {
      url: `${resource}`,
      method: 'delete',
    },
    ...cb
  );
}

function getLogInfo(...cb) {
  return fetchWrap(
    {
      url: `${resource}`,
    },
    ...cb
  );
}

function recreateLog(...cb) {
  return fetchWrap(
    {
      url: `${resource}/recreate`,
      method: 'post',
    },
    ...cb
  );
}

export default { createLog, getLogInfo, disableLog, recreateLog };
