import { Form, Switch } from 'antd';
import _get from 'lodash/get';
import IPsecConfig from 'pages/Security/shared/IPsecVPN/IPsecConfig';
import { fetchDefaultIpsecConfig } from 'pages/Security/shared/IPsecVPN/IPsecConfig/IPsecConfig.service';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const VpnConfig = forwardRef(({ data }, ref) => {
  const formRef = useRef();
  const [defaultValues, setDefaultValues] = useState({});
  const [isIpsecEnabled, setIsIpsecEnabled] = useState(false);

  useEffect(() => {
    fetchDefaultIpsecConfig().then(({ ipsecgateway_enable, ...defaultValues }) => {
      setDefaultValues(defaultValues);
      // update thin edge config once get the default config from server
      setIsIpsecEnabled(_get(data, 'isIpsecEnabled', ipsecgateway_enable));
    });
  }, [data]);

  const validate = useCallback(() => {
    if (isIpsecEnabled) {
      return formRef.current.validate().then(values => {
        return Promise.resolve({ isIpsecEnabled, vpnConfig: values });
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ isIpsecEnabled, vpnConfig: {} });
      });
    }
  }, [isIpsecEnabled]);

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <div className="flex-col flex-item" style={{ padding: '24px 0', overflow: 'auto' }}>
      <div>
        <Form.Item
          label="Thin Edge"
          labelAlign="left"
          labelCol={{ span: 6, xs: 24, sm: 4, md: 3 }}
          wrapperCol={{ span: 18, xs: 24, sm: 18 }}
          style={{ marginLeft: 32 }}
        >
          <Switch checked={isIpsecEnabled} onChange={value => setIsIpsecEnabled(value)} />
        </Form.Item>
        {isIpsecEnabled && <IPsecConfig ref={formRef} data={data} defaultValues={defaultValues} />}
      </div>
    </div>
  );
});

VpnConfig.propTypes = {
  data: PropTypes.object,
};

export default VpnConfig;
