import './Users.module.less';

import { Button, Form, Input, Space } from 'antd';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { validateMessages } from 'utils/util';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const ResetPasswordForm = ({ onSubmit, onCancel }) => {
  const formRef = useRef();
  const [pending, setPending] = useState(false);

  const handleSubmit = useCallback(
    values => {
      setPending(true);
      onSubmit(values).finally(() => setPending(false));
    },
    [onSubmit]
  );

  return (
    <Form
      ref={formRef}
      {...formItemLayout}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="password"
        label="New Password"
        key="password"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: 'Please input the new password!',
          },
          { min: 6 },
        ]}
      >
        <Input.Password disabled={pending} />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        key="confirm"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: 'Please confirm the password!',
          },

          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password disabled={pending} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button loading={pending} type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
