import { fetchWrap } from 'utils/fetch';

const resource = '/v1/setting/permission/roles';

/**
 * @description get roles
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function getRoles(...cb) {
  return fetchWrap(
    {
      url: `${resource}`,
    },
    ...cb
  );
}

/**
 * @description get role by role name
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {*} roleName
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function getRoleByName(roleName, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${roleName}`,
    },
    ...cb
  );
}

/**
 * @description create a role
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {*} roleData
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function createRole(roleData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}`,
      method: 'post',
      data: roleData,
    },
    ...cb
  );
}

/**
 * @description update a role
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {*} roleData
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function updateRole(roleName, roleData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${roleName}`,
      method: 'patch',
      data: roleData,
    },
    ...cb
  );
}

/**
 * @description delete a role
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {*} roleName
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function deleteRole(roleName, ...cb) {
  return fetchWrap({
    url: `${resource}/${roleName}`,
    method: 'delete',
  });
}

export default {
  getRoles,
  getRoleByName,
  createRole,
  updateRole,
  deleteRole,
};
