import './Login.module.less';

import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Tabs } from 'antd';
import socBadge from 'assets/images/aicpa-soc-certification.webp';
import saseLogo from 'assets/images/sase_logo.svg';
import SvgIcon from 'components/SvgIcon';
import en from 'Languages/en.json';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  fetchPortals,
  setAuthenticate,
  updateTenantUser,
} from 'redux/GlobalSetting/globalSetting.actions';
import { defaultRoute } from 'routeConfig';
import bgWrapper from 'utils/bgWrapper';
import storage from 'utils/storage';
import { getTenantUser, saveToken, setActiveTheme } from 'utils/util';

import { getRegisterUrl, standaloneLogin } from './login.service';
const { TabPane } = Tabs;

const Login = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [registerPending, setRegisterPending] = useState(false);
  const [ssoLoginPending, setSsoLoginPending] = useState(false);
  const [standaloneEnabled, setStandaloneEnabled] = useState(false);
  const [activeKey, setActiveKey] = useState('sso');
  const presetPrimaryAccount = _get(queryString.parse(window.location.search), 'account');

  useEffect(() => {
    setActiveTheme('dark');
    // override standalone login option
    const cachedStandaloneLogin = _get(
      storage.get('license'),
      'standalone_entitlement.visibility',
      false
    );
    const enableStandaloneLogin =
      _get(queryString.parse(window.location.search), 'standalone') === 'true' ||
      cachedStandaloneLogin;
    setStandaloneEnabled(enableStandaloneLogin);

    if (_get(queryString.parse(window.location.search)) === 'true') {
      setActiveKey('standalone');
    }
  }, []);

  const redirect = _get(queryString.parse(window.location.search), 'redirect');

  const alertNoRole = useCallback(() => {
    Modal.warning({
      title: 'No role assigned',
      content: 'You are assigned no role, please contact admin to assign you role.',
      closable: false,
      maskClosable: false,
      onOk: () => setLoading(false),
    });
  }, []);

  const enterPortal = useCallback(() => {
    const { state } = location;
    let redirectPath = defaultRoute;
    if (state && state.from.pathname === 'sso-login') {
      redirectPath = state.from.pathname;
    }
    dispatch(setAuthenticate(true));
    history.push(redirectPath);
  }, [dispatch, history, location]);

  const afterLogin = useCallback(
    tokenObj => {
      const tenantUser = getTenantUser(tokenObj.access_token);
      if (tenantUser.role.length === 0) {
        alertNoRole();
      } else {
        saveToken(tokenObj);
        if (tenantUser.auth_type === 'sso') {
          dispatch(fetchPortals());
        }
        dispatch(updateTenantUser(tenantUser));
        enterPortal();
      }
    },
    [alertNoRole, dispatch, enterPortal]
  );

  const handleStandaloneLogin = useCallback(
    values => {
      setLoading(true);
      const payload = {
        root_principal: values.rootPrincipal,
        principal: values.principal,
        password: values.password,
      };
      standaloneLogin(payload)
        .then(data => afterLogin(data))
        .finally(() => setLoading(false));
    },
    [afterLogin]
  );

  const ssoLogin = () => {
    setSsoLoginPending(true);
    window.location.href = '/pre-login';
    setSsoLoginPending(false);
  };

  const register = useCallback(() => {
    setRegisterPending(true);
    getRegisterUrl()
      .then(url => (window.location.href = url))
      .finally(() => setRegisterPending(false));
  }, []);

  const ssoButtons = useMemo(
    () => (
      <>
        <Button block styleName="action-btn login-btn" loading={ssoLoginPending} onClick={ssoLogin}>
          {en.LOGIN.SSO_LOGIN}
        </Button>
        <Button
          block
          styleName="action-btn register-btn"
          loading={registerPending}
          onClick={register}
        >
          {en.LOGIN.REGISTER}
        </Button>
      </>
    ),
    [register, registerPending, ssoLoginPending]
  );

  useEffect(() => {
    // redirect to the destination if routing from www.fortisase.com,
    switch (redirect) {
      case 'login':
        ssoLogin();
        break;
      case 'register':
        register();
        break;
      default:
    }
  }, [redirect, register]);

  return (
    <div className="flex-col" styleName="login">
      {redirect ? (
        <div style={{ margin: 'auto', color: '#fff', fontSize: 24 }}>
          <LoadingOutlined />
        </div>
      ) : (
        <div styleName="login-panel" data-testid="login-panel">
          <div styleName="logo" data-testid="logo">
            <img src={saseLogo} alt="logo" /> <strong>{en.SYSTEM.PRODUCT_NAME}</strong>
          </div>

          {standaloneEnabled ? (
            <Tabs
              activeKey={activeKey}
              animated={false}
              centered
              size="large"
              onChange={tabKey => setActiveKey(tabKey)}
            >
              <TabPane tab={en.LOGIN.SINGLE_SIGN_ON} key="sso">
                {ssoButtons}
              </TabPane>
              <TabPane tab={en.LOGIN.STAND_ALONE} key="standalone">
                <Form
                  onFinish={handleStandaloneLogin}
                  onFinishFailed={errorInfo => setLoading(false)}
                >
                  <Form.Item
                    name="rootPrincipal"
                    key="root"
                    rules={[{ required: true, message: en.LOGIN.ROOT_PRINCIPAL_EMPTY }]}
                    initialValue={presetPrimaryAccount}
                  >
                    <Input
                      prefix={<SvgIcon type="admin" />}
                      placeholder={en.LOGIN.ROOT_PRINCIPAL}
                    />
                  </Form.Item>
                  <Form.Item
                    name="principal"
                    key="principal"
                    rules={[{ required: true, message: en.LOGIN.PRINCIPAL_EMPTY }]}
                  >
                    <Input prefix={<UserOutlined />} placeholder={en.LOGIN.PRINCIPAL} />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    key="password"
                    rules={[{ required: true, message: en.LOGIN.PASSWORD_EMPTY }]}
                  >
                    <Input
                      prefix={<LockOutlined />}
                      type="password"
                      placeholder={en.LOGIN.PASSWORD}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" styleName="action-btn login-btn" loading={loading}>
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          ) : (
            <div>
              <div styleName="sso-title">Single Sign-on</div>
              {ssoButtons}
            </div>
          )}
        </div>
      )}

      {/* <div styleName="certification" className="flex-row main-center" data-testid="certification">
        <div className="flex-row cross-center">
          <img src={socBadge} />
          <div>
            {'FortiSASE complies with the '}
            <a href="https://us.aicpa.org" target="_blank" rel="noreferrer" data-testid="aicpa-link">
              AICPA SOC 2
            </a>
            {' standards of security, high availability and confidentiality. '}
            <a
              href="https://www.fortinet.com/corporate/about-us/product-certifications/service-organization-controls-soc2-compliance"
              target="_blank"
              rel="noreferrer"
              data-testid="report-link"
            >
              Request reports.
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

Login.propTypes = {};

export default bgWrapper(Login);
