import api from 'api';
import { ipv4 } from 'ip-address-utils';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { map } from 'rxjs/operators';
import { toStream } from 'utils/util';

/**
 * get default IPsec VPN config
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-07-09
 * @export
 * @returns stream
 */
export function fetchDefaultIpsecConfig() {
  return toStream(api.getDefaultIpsecConfig).pipe(map(res => _get(res, 'data.data', {})));
}

/**
 * fetch default IP ranges
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-07-09
 * @export
 * @param {*} address
 * @returns
 */
export function fetchDefaultIpRanges(address) {
  return toStream(api.getDefaultIpRanges, address).pipe(
    map(res => _get(res, 'data.data.ip_range', []))
  );
}

/**
 * get completed site data from the server-side
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-07-08
 * @export
 * @param {*} payload
 * @returns
 */
export function reviewSiteData(payload) {
  return api.reviewSiteData(payload).then(res => Promise.resolve(_get(res, 'data.data', {})));
}

export function getAvailableModels(license) {
  return _get(license, 'security_entitlement.site.models', ['small']);
}

export function getSslOptions(license) {
  return _get(license, 'security_entitlement.site.ssl_vpn_entitlement', {});
}

export function getIPsecOptions(license) {
  return _get(license, 'security_entitlement.site.ipsec_entitlement', {});
}
