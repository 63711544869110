import './SiteApplications.module.less';

import { Modal, Popconfirm, Table } from 'antd';
import api from 'api';
import React, { Component } from 'react';

import { getDefaultIcon, typeToLabelMap } from './siteAppUtils';

class UserAppsManagementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      userAppMap: {},
      visible: true,
      loading: false,
    };
    this.userColumns = [
      {
        title: 'User Name',
        dataIndex: 'user_name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Group',
        dataIndex: 'group',
      },
    ];
  }

  componentDidMount() {
    this.getUsersAndApps();
  }

  onCancel = () => {
    this.setState({ visible: false });
  };

  async getUsersAndApps() {
    const { siteId } = this.props;

    this.setState({ loading: true });
    try {
      const usersData = await api.getUsersBySite(siteId);
      const appsToUsersData = await api.getAssignApplicationsToUsers(siteId);
      const userAppMap = this.mapUserToApp(appsToUsersData.data.data);
      this.setState({ loading: false, usersData: usersData.data.data, userAppMap });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  mapUserToApp(appsToUsers) {
    const userAppMap = {};
    appsToUsers.forEach(appToUsersItem => {
      const appId = appToUsersItem.appid;
      const remoteUsers = appToUsersItem.remoter_users;
      remoteUsers.forEach(remoteUserItem => {
        if (userAppMap[remoteUserItem.id]) {
          userAppMap[remoteUserItem.id].push(appId);
        } else {
          userAppMap[remoteUserItem.id] = [appId];
        }
      });
    });
    return userAppMap;
  }

  afterClose = () => {
    this.props.afterClose && this.props.afterClose();
  };

  handleDeleteUserApp = (appId, userId) => {
    const appsToUsers = [
      {
        action: 'delete',
        appid: appId,
        remoter_users: [{ id: userId }],
      },
    ];
    api.assignApplicationsToUsers(this.props.siteId, appsToUsers).then(() => {
      this.getUsersAndApps();
    });
  };

  renderexpandedRow = userRecord => {
    const userId = userRecord.id;
    const { userAppMap } = this.state;
    const { appsData } = this.props;
    if (!userAppMap[userId]) return null;
    const dataSource = appsData.filter(appItem => userAppMap[userId].includes(appItem.id));
    const columns = [
      {
        title: 'App Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        render: (name, record) => (
          <div>
            <img
              src={record.icon || getDefaultIcon(record.type)}
              alt="icon"
              style={{ width: 32, height: 24, marginRight: 12 }}
            />
            <span>{name}</span>
          </div>
        ),
      },
      {
        title: 'App Type',
        dataIndex: 'type',
        key: 'type',
        width: 160,
        render: type => typeToLabelMap[type],
        // filters: ['web', 'ssh', 'rdp'].map((type) => ({ text: typeToLabelMap[type], value: type })),
        // onFilter: (value, record) => record.type === value,
      },
      {
        title: 'Action',
        key: 'action',
        render: record => (
          <Popconfirm
            title={`Are you sure to unassign app ${record.name} from user ${userRecord.user_name}`}
            onConfirm={() => this.handleDeleteUserApp(record.id, userId)}
          >
            <span className="link">Unassign</span>
          </Popconfirm>
        ),
      },
    ];
    return (
      <Table
        size="middle"
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    );
  };

  render() {
    const { visible, usersData, loading } = this.state;

    return (
      <Modal
        title="User Apps Management"
        visible={visible}
        width={800}
        maskClosable={false}
        afterClose={this.afterClose}
        onCancel={this.onCancel}
        footer={null}
      >
        <Table
          rowKey="id"
          loading={loading}
          columns={this.userColumns}
          dataSource={usersData}
          scroll={{ y: 600 }}
          expandedRowRender={record => this.renderexpandedRow(record)}
        />
      </Modal>
    );
  }
}

export default UserAppsManagementModal;
