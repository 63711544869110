import './SiteDetail.module.less';

import { CrownTwoTone, StopOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Table, Tooltip } from 'antd';
import FailureDetail from 'components/FailureDetail';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Resizable } from 'react-resizable';
import { RESOURCE_STATUS } from 'utils/constant';
import useEntitlements from 'utils/hooks/useEntitlements';
import { getSiteStatus, getSiteStatusBadge, getVpnConfig } from 'utils/services/site.service';

import IPsecInfo from '../shared/IPsecVPN/IPsecInfo';

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const FederatedSitesTable = ({ data, configMode, onTerminateSite }) => {
  const entitlements = useEntitlements();
  const { isIpsecEntitled } = entitlements;
  // const [modalVisible, setModalVisible] = useState(false);
  // const [detail, setDetail] = useState({});
  const [colWidths, setColWidths] = useState([170, 160, 150, 40]);
  // const viewDetail = useCallback((data) => {
  //   const { id, name } = data;
  //   getVpnConfig(id)
  //     .then(({ ipsec }) => setDetail({ ...ipsec, id, name }))
  //     .catch((err) => console.log({ err }));
  //   setModalVisible(true);
  // }, []);

  // const closeModal = useCallback(() => {
  //   setModalVisible(false);
  //   setDetail({});
  // }, []);

  // const enableIPsecChange = useCallback(
  //   (site) => {
  //     const status = getSiteStatus(site);
  //     return configMode && (RESOURCE_STATUS.isRunning(status) || RESOURCE_STATUS.isWarning(status));
  //   },
  //   [configMode]
  // );

  const showIPsecDetail = useCallback(site => {
    const status = getSiteStatus(site);
    return (
      (RESOURCE_STATUS.isRunning(status) || RESOURCE_STATUS.isWarning(status)) &&
      site.ipsecgateway_enable
    );
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          title: 'Name (Device Name)',
          dataIndex: 'name',
          width: colWidths[0],
          key: 'name',
          render: (text, record) => {
            const isMaster = record.role === 'backend_master';
            return (
              <div className="ellipsis" styleName={record._termination ? 'terminated' : null}>
                {isMaster ? (
                  <Tooltip title="Leader">
                    <CrownTwoTone twoToneColor="#faad14" />
                  </Tooltip>
                ) : (
                  <span style={{ display: 'inline-block', width: 14 }} />
                )}
                &nbsp;
                <Tooltip title={text}>{text}</Tooltip>
              </div>
            );
          },
        },
        {
          title: 'Region',
          dataIndex: 'region_alias',
          key: 'region',
          width: colWidths[1],
          render: (text, record) => (
            <span styleName={record._termination ? 'terminated' : null}>{text}</span>
          ),
        },
        // isIpsecEntitled && {
        //   title: 'IPsec VPN',
        //   key: 'ipsec',
        //   width: colWidths[2],
        //   render: (text, record) => (
        //     <span styleName={record._termination ? 'terminated' : null}>
        //       {record.ipsecgateway_enable ? (
        //         <Fragment>
        //           {showIPsecDetail(record) ? (
        //             <Button type="link" onClick={() => viewDetail(record)}>
        //               Detail
        //             </Button>
        //           ) : (
        //             'Enabled'
        //           )}
        //         </Fragment>
        //       ) : (
        //         'Disabled'
        //       )}
        //     </span>
        //   ),
        // },
        {
          title: 'Status',
          dataIndex: 'state_label',
          key: 'status',
          width: 140,
          className: 'ellipsis',
          render: (text, record) => {
            const badge = getSiteStatusBadge(record);
            return (
              <Fragment>
                <span title={text} styleName={record._termination ? 'terminated' : null}>
                  <span styleName="badge">{badge}</span>
                  &nbsp;
                  {text}
                  {record.resource_status === 'failed' && (
                    <FailureDetail
                      data={{
                        resourceFailedType: record.resource_failed_type,
                        resourceFailedMessage: record.resource_failed_message,
                        timestamp: record._updated,
                        action: record.status,
                        isRecreatable: record.is_recreatable,
                      }}
                      onRecreate={() => {}}
                    ></FailureDetail>
                  )}
                </span>

                {record._termination && (
                  <div styleName="termination-message">
                    This instance will be terminated after saving the changes.
                  </div>
                )}
              </Fragment>
            );
          },
        },
        {
          key: 'action',
          fixed: 'right',
          width: colWidths[3],
          render: (text, record) => {
            return record._termination || record.role === 'backend_master'
              ? ''
              : configMode && (
                  <Tooltip title="Terminate">
                    <Button
                      ghost
                      type="danger"
                      size="small"
                      icon={<StopOutlined />}
                      onClick={() => onTerminateSite(record)}
                    />
                  </Tooltip>
                );
          },
        },
      ]
        //filter unavailable columns
        .filter(col => !!col),
    [colWidths, configMode, onTerminateSite]
  );

  const backendSites = useMemo(
    () =>
      data.sort((a, b) => {
        if (Date.parse(a._created) < Date.parse(b._created)) {
          return -1;
        } else if (Date.parse(a._created) > Date.parse(b._created)) {
          return 1;
        } else {
          return 0;
        }
      }),
    [data]
  );

  const handleResize = useCallback(
    index => (e, { size: { width } }) => {
      if (index >= columns.length - 1) return;
      const newColWidths = colWidths.map((colWidth, i) => (i === index ? width : colWidth));
      setColWidths(newColWidths);
    },
    [colWidths, columns.length]
  );

  return (
    <Fragment>
      <Table
        styleName="federated-sites-table"
        rowKey="id"
        pagination={false}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        columns={columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index),
          }),
        }))}
        dataSource={backendSites}
        scroll={{ x: 605 }}
      />
      {/* <Modal
        title={`IPsec VPN`}
        width={'95%'}
        style={{
          maxWidth: 900,
          maxHeight: '95vh',
        }}
        bodyStyle={{
          height: 760,
          maxHeight: 'calc(95vh - 55px)',
          overflow: 'auto',
        }}
        footer={null}
        visible={modalVisible}
        centered
        autoFocusButton={null}
        maskClosable
        onCancel={closeModal}
      >
        {_isEmpty(detail) ? (
          <div style={{ height: '100%' }} className="flex-col main-center cross-center">
            <Spin />
          </div>
        ) : (
          <IPsecInfo data={detail} />
        )}
      </Modal> */}
    </Fragment>
  );
};

FederatedSitesTable.propTypes = {
  data: PropTypes.array.isRequired,
  configMode: PropTypes.bool.isRequired,
  onIPsecChange: PropTypes.func.isRequired,
  onIPsecEdit: PropTypes.func.isRequired,
  onTerminateSite: PropTypes.func.isRequired,
};

export default FederatedSitesTable;
