import './CreateTurboSite.module.less';

import { Divider, Table, Tag } from 'antd';
import InfoItem from 'components/InfoItem';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import IPsecConfigReview from 'pages/Security/shared/IPsecVPN/IPsecConfig/Review';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useMemo } from 'react';
import useEntitlements from 'utils/hooks/useEntitlements';
import { getSiteCloudProvider, getSiteTypeLabel } from 'utils/services/site.service';

const Review = props => {
  const { data, cloudRegions } = props;
  const entitlements = useEntitlements();
  const { isIpsecEntitled } = entitlements;

  const cloudProvider = getSiteCloudProvider(data, cloudRegions);
  const typeLabel = getSiteTypeLabel(data);
  const tagBadges = data.tags && data.tags.map((el, index) => <Tag key={index}>{el}</Tag>);

  const master = _get(data, 'config.master', {});
  const slaves = _get(data, 'config.slaves', []);
  const slavesCount = useMemo(() => {
    const count = slaves.reduce((result, item) => {
      result += item.number || 0;
      return result;
    }, 0);
    return count;
  }, [slaves]);

  const isIpsecEnabled = _get(slaves[0], 'ipsecgateway_enable', false);
  const ipsecConfig = _get(slaves[0], 'ipsecgateway_customer_config', {});

  const getDistrLabel = useCallback((data, list = []) => {
    const region = list.find(({ name }) => name === data.region_name);
    const pop = _get(region, 'fos_pops', []).find(({ name }) => name === data.pop_name);
    const regionLabel = _get(region, 'alias', '');
    const popLabel = _get(pop, 'alias', '');
    return { regionLabel, popLabel };
  }, []);

  const leaderSummary = <div>{getDistrLabel(master, cloudRegions).regionLabel}</div>;

  const slavesSummary = useMemo(() => {
    return (
      <Table
        rowKey="region_name"
        dataSource={slaves}
        pagination={false}
        style={{ marginLeft: -16 }}
      >
        <Table.Column
          title="Location"
          key="location"
          render={record => getDistrLabel(record, cloudRegions).regionLabel}
        />
        <Table.Column title="Count" dataIndex="number" key="count" />
      </Table>
    );
  }, [cloudRegions, getDistrLabel, slaves]);

  return (
    <div style={{ ...props.style }}>
      <Divider orientation="left">Site Info</Divider>
      <div styleName="info-section">
        <InfoItem label="Site Name" value={data.name} />
        <InfoItem label="Description" value={data.description} />
        <InfoItem label="Type" value={typeLabel} />
        <InfoItem label="Edge" value={cloudProvider} />

        <InfoItem label="Tags" value={tagBadges} />
      </div>

      <br />

      <Divider orientation="left">Federated Sites</Divider>
      <div styleName="info-section">
        {slavesSummary}
        <InfoItem label="Leader Region" value={leaderSummary} />
      </div>

      {isIpsecEntitled && (
        <Fragment>
          <br />
          <Divider orientation="left">VPN</Divider>
          <div styleName="info-section">
            <InfoItem label="Thin Edge" value={isIpsecEnabled ? 'Enabled' : 'Disabled'} />
            {isIpsecEnabled && <IPsecConfigReview data={ipsecConfig} />}
          </div>
        </Fragment>
      )}

      <br />
      <Divider orientation="left">Analytics</Divider>
      <div styleName="info-section">
        {
          // if the data has analytics, it's a created site
          _get(data, 'analytics', false) ? (
            <Fragment>
              <InfoItem label="Region" value={_get(data, 'analytics.region_alias')} />
              <InfoItem label="Type" value={_upperFirst(_get(data, 'analytics.resource_model'))} />
            </Fragment>
          ) : (
            <Fragment>
              {_get(data, 'log_enable') ? (
                <>
                  <InfoItem
                    label="Region"
                    value={
                      getDistrLabel(
                        { region_name: _get(data, 'log_config.region_name') },
                        cloudRegions
                      ).regionLabel
                    }
                  />
                  <InfoItem
                    label="Type"
                    value={_upperFirst(_get(data, 'log_config.resource_model'))}
                  />
                </>
              ) : (
                // displayed for log is disabled
                <InfoItem label="Analytics" value={'Disabled'} />
              )}
            </Fragment>
          )
        }
      </div>
    </div>
  );
};

Review.propTypes = {
  data: PropTypes.object.isRequired,
  cloudRegions: PropTypes.array.isRequired,
};

export default Review;
