import { LoadingOutlined } from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';
import api from 'api';
import SvgIcon from 'components/SvgIcon';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _startCase from 'lodash/startCase';
import React from 'react';
import { SITE_NAME_REGEX } from 'utils/commonRegex';
import { RESOURCE_STATUS, TYPES } from 'utils/constant';

export const isTurboSite = data => _get(data, 'role', '') === 'turbo';

// used to distinguish the site type (turbo/standalone)
export const getTypeIcon = data =>
  isTurboSite(data) ? (
    <Tooltip title="Turbo Site">
      <SvgIcon type="turbo-site" data-testid="turbo-site-icon" />
    </Tooltip>
  ) : (
    <Tooltip title="Standalone Site">
      <SvgIcon type="standalone-site" data-testid="standalone-site-icon" />
    </Tooltip>
  );

// subjectStatus is used to judge if a site considered to be ready to use
export const getSubjectStatus = data => _get(data, 'resource_state', '');

export const getSubjectStatusLabel = data => _get(data, 'state_label', '');

// siteStatus shows the overall status of a site
export const getSiteStatus = data => _get(data, 'resource_status', null);

export const getSiteStatusBadge = data => {
  const status = _get(data, 'resource_status', null);
  const isRunning = RESOURCE_STATUS.isRunning(status);
  const isPending = RESOURCE_STATUS.isPending(status);
  const isFailed = RESOURCE_STATUS.isFailed(status);
  const isWarning = RESOURCE_STATUS.isWarning(status);

  // backend site has a rollback status called terminated
  const isTerminated = status === 'terminated';

  switch (true) {
    case isRunning:
      return <Badge status="success" />;
    case isPending:
      return (
        <LoadingOutlined
          style={{ width: 16, lineHeight: '16px', textAlign: 'center', color: '#1890FF' }}
        />
      );
    case isFailed:
      return <Badge status="error" />;
    case isWarning:
      return <Badge status="warning" />;
    case isTerminated:
      return <Badge status="default" />;
    default:
      console.warn('Invalid Status');
      return <Badge status="default" />;
  }
};

// extract given key's values to one Set from sites in master and slave
export const getValSetFromAllSites = (record, dataIndex) => {
  const result = new Set();
  let sites = [];
  _get(record, 'config.slaves', []).forEach(item => {
    if (item.sites) {
      sites = [...sites, ...item.sites];
    }
  });
  sites.forEach(item => {
    result.add(item[dataIndex]);
  });
  return [...result];
};

export const getLeader = data => {
  const leader = _get(data, 'config.master.region_name', '');
  return leader;
};

// get all slave sites of a turbo site
export const getSlaves = data => {
  const slaveGroups = _cloneDeep(_get(data, 'config.slaves', []));
  const slaves = slaveGroups.reduce((arr, group) => {
    if (_isArray(group.sites)) {
      arr = [...arr, ...group.sites];
    }

    return arr;
  }, []);
  return slaves;
};

// get the master site of a turbo site
export const getMaster = data => {
  const sites = getSlaves(data);

  return sites.find(({ role }) => role === 'backend_master') || {};
};

export const getSiteTypeLabel = data => {
  const label = `${isTurboSite(data) ? 'Turbo' : 'Standalone'} - ${_startCase(
    data.resource_model
  )}`;
  return label;
};

/**
 * get the cloud provider choosen for a turbo site
 *
 * @param {Object} data site data
 * @param {Array} regions the cloud region list supported by FGC
 * @returns {string}
 */

export const getSiteCloudProvider = (data, regions = []) => {
  const master = _get(data, 'config.master', {});
  const found = regions.find(({ name }) => name === master.region_name);
  const cloudProvider = _get(found, 'edge', '');

  return cloudProvider;
};

export const getEdge = (regionName, regions = []) => {
  const found = regions.find(({ name }) => name === regionName);
  const edge = _get(found, 'edge', '');

  return edge;
};

// reduce the newSalves & existedSalves to an array with expected format
export const formatSlaveConfig = (newSlaves = [], currSlaves = []) => {
  if (!_isArray(newSlaves) || !_isArray(currSlaves)) {
    throw new Error('Invalid slave lists.');
  }

  let slaveList = currSlaves.reduce((result, item) => {
    const formattedItem = {
      id: item.id,
      ipsecgateway_enable: item.ipsecgateway_enable,
      ...(item.ipsecgateway_enable && {
        ipsecgateway_customer_config:
          item._ipsecgateway_customer_config || item.ipsecgateway_customer_config,
      }),
    };

    const group = result.find(({ region_name, pop_name }) => region_name === item.region_name);
    if (group) {
      if (!item._termination) {
        group.number += 1;
        group.sites.push(formattedItem);
      }
    } else {
      result.push({
        region_name: item.region_name,
        number: item._termination ? 0 : 1,
        sites: item._termination ? [] : [formattedItem],
      });
    }
    return result;
  }, []);

  slaveList = slaveList.concat(
    newSlaves.map(({ count, ipsecgateway_customer_config, ipsecgateway_enable, region }) => ({
      number: count,
      region_name: region,
      ipsecgateway_enable,
      ...(ipsecgateway_enable && { ipsecgateway_customer_config }),
    }))
  );

  return slaveList;
};

// the validation rules of site name when creating new site
export const getSiteNameRules = () => {
  const rules = [
    { required: true, message: 'This field is required.' },
    {
      pattern: SITE_NAME_REGEX,
      message:
        'A name can contain numbers (0-9), uppercase and lowercase letters (A-Z, a-z), and the special characters - and _. Other characters and space are not allowed.',
    },
    {
      max: 25,
      message: 'Must be under 25 characters',
    },
  ];
  return rules;
};

// get all pop model options enabled by tenant's license, if no license provided
// all options will be return
export const getPopModelOptions = license => {
  if (license) {
    const availableModels = _get(license, 'security_entitlement.site.models', ['small']);
    return TYPES.map(item => ({ ...item, disabled: !availableModels.includes(item.value) }));
  }
  return TYPES;
};

// group slaves by region & pop
export const slaveReducer = slaves => {
  const reduced = slaves.reduce((result, el) => {
    const group = result.find(({ region_name }) => region_name === el.region_name);
    if (group) {
      group.number += el.number;
    } else {
      result.push({ ...el });
    }
    return result;
  }, []);
  return reduced;
};

export const needQuerySyncStatus = (data = {}) =>
  isTurboSite(data) &&
  (RESOURCE_STATUS.isRunning(data.resource_status) ||
    RESOURCE_STATUS.isWarning(data.resource_status));

export const getCustomFosVersion = () => window.sessionStorage.getItem('fos_version');

export const getVpnConfig = siteId => api.getSiteConfig(siteId).then(res => _get(res, 'data.data'));
