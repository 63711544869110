import { fetchWrap } from 'utils/fetch';

const resource = '/v1/system';

/**
 * @description
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-26
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function getSystemVersion(...cb) {
  return fetchWrap(
    {
      url: `${resource}/version`,
    },
    ...cb
  );
}

function sendEmail(type, paylaod, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/mails/send`,
      method: 'post',
      data: {
        type,
        ...paylaod,
      },
    },
    ...cb
  );
}

function getMaintInfo(...cb) {
  return fetchWrap(
    {
      url: `${resource}/sys_maintenance/active`,
    },
    ...cb
  );
}

export default {
  getSystemVersion,
  sendEmail,
  getMaintInfo,
};
