import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { USER_LOGOUT } from 'redux/GlobalSetting/globalSetting.actions';
import globalSetting from 'redux/GlobalSetting/globalSetting.reducers';
import logs from 'redux/Logs/logs.reducers';
import security from 'redux/Security/Security.reducers';
import socket from 'redux/Socket/socket.reducers';
import thunk from 'redux-thunk';

const middleware =
  process.env.NODE_ENV !== 'production'
    ? [require('redux-immutable-state-invariant').default(), thunk]
    : [thunk];

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const appReducer = combineReducers({
  globalSetting,
  security,
  logs,
  socket,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default store;
