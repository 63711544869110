import './SubUserModal.module.less';

import { exclamationTriangleIcon } from '@neutrino/fa-icons/solid';
import { Collapse, Modal, Space } from 'antd';
import NuIcon from 'components/NuIcon';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

const SubUserModal = forwardRef(({ loginWithSSO }, ref) => {
  const [visible, setVisible] = useState(false);
  const [account, setAccount] = useState(null);
  const [disableSSO, setDisableSSO] = useState(false);

  useImperativeHandle(ref, () => ({
    showModal(record) {
      setAccount(record);
      setDisableSSO(false);
      setVisible(true);
    },
  }));

  const redirectToIAM = useCallback(() => {
    setDisableSSO(true);
    window.open('/logout');
  }, []);

  return (
    <Modal
      centered
      open={visible}
      closable={false}
      footer={null}
      data-testid="sub-user-prompt"
      getContainer={false}
      width={560}
      bodyStyle={{
        padding: '24px 64px',
        maxWidth: '90vw',
        maxHeight: 'calc(90vh - 108px)',
        overflow: 'auto',
        backgroundColor: 'rgb(250, 250, 250)',
        color: 'rgb(45, 45, 45)',
      }}
    >
      <div className="flex-col" styleName="prompt">
        <Space direction="vertical" size="large">
          <div className="flex-row main-center cross-center">
            <NuIcon
              icon={exclamationTriangleIcon}
              style={{
                width: 80,
                height: 80,
                color: 'rgb(var(--nu-theme-override-blue-icon,var(--nu-theme-color-icon-yellow)))',
              }}
              data-testid="info-icon"
            />
          </div>
          <div className="flex-col cross-center">
            <h2 data-testid="title" style={{ textAlign: 'center', color: 'rgb(45, 45,45)' }}>
              Transition to FortiCare Identity & Access Management (IAM) before August
            </h2>
            <p data-testid="description">
              Continued use of FortiCare sub user account SSO may yield unexpected behaviour and is
              not recommended. Follow the instructions below to transition to FortiCare IAM. If
              unsuccessful, contact your primary account holder to enable IAM.
            </p>
          </div>
          <Collapse
            defaultActiveKey={['guide']}
            expandIconPosition="right"
            bordered={false}
            style={{
              width: 480,
              margin: 'auto',
              maxWidth: '100%',
              borderColor: 'rgb(206, 206, 206)',
            }}
            styleName="collapse"
          >
            <Collapse.Panel key="guide" header="How to transition to FortiCare IAM">
              <ol data-testid="guide-content">
                <li>
                  Click on <em>Transition to IAM</em>.
                </li>
                <li>
                  When redirected, select <em>SSO Login</em>.
                </li>
                <li>
                  Click on <em>IAM Login</em>.
                </li>
              </ol>
            </Collapse.Panel>
          </Collapse>
          <div className="flex-row main-center">
            <Space>
              <button className="primary" data-testid="iam-login-btn" onClick={redirectToIAM}>
                Transition to IAM
              </button>
              <button
                data-testid="sso-login-btn"
                disabled={disableSSO}
                onClick={() => loginWithSSO(account)}
                style={{
                  background: 'transparent',
                  color: 'rgb(45, 45, 45)',
                  borderColor: 'rgb(206, 206, 206)',
                }}
              >
                Continue with SSO
              </button>
              <button
                data-testid="cancel-btn"
                onClick={() => setVisible(false)}
                style={{
                  background: 'transparent',
                  color: 'rgb(45, 45, 45)',
                  borderColor: 'rgb(206, 206, 206)',
                }}
              >
                Cancel
              </button>
            </Space>
          </div>
        </Space>
      </div>
    </Modal>
  );
});
SubUserModal.displayName = 'SubUserModal';
SubUserModal.propTypes = {
  loginWithSSO: PropTypes.func.isRequired,
};

export default SubUserModal;
