import defaultWebIcon from 'assets/images/application.png';
import defaultRdpIcon from 'assets/images/rdp.png';
import defaultSshIcon from 'assets/images/ssh.jpg';

export function getDefaultIcon(type) {
  switch (type) {
    case 'web':
      return defaultWebIcon;
    case 'ssh':
      return defaultSshIcon;
    case 'rdp':
      return defaultRdpIcon;
    default:
      return defaultWebIcon;
  }
}

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

export const appTypeOptions = ['web', 'ssh', 'rdp'];

export const typeToLabelMap = {
  web: 'WEB',
  ssh: 'SSH',
  rdp: 'RDP',
  all: 'ALL',
  starred: 'Starred',
};

export const securityOptions = [
  { name: 'rdp', help: 'Standard RDP encryption' },
  { name: 'nla', help: 'Network Level Authentication' },
  { name: 'tls', help: 'TLS encryption' },
  { name: 'any', help: 'Allow the server to choose the type of security' },
];

export const keyboardLayoutOptions = [
  { name: 'de-de-qwertz', help: 'German (qwertz)' },
  { name: 'en-gb-qwertz', help: 'English (UK)' },
  { name: 'en-us-qwertz', help: 'English (US)' },
  { name: 'es-es-qwertz', help: 'Spanish' },
  { name: 'fr-ca-qwertz', help: 'Canadian French (qwertz)' },
  { name: 'fr-fr-azerty', help: 'French (azerty)' },
  { name: 'fr-ch-qwertz', help: 'Swiss French (qwertz)' },
  { name: 'it-it-qwertz', help: 'Italian' },
  { name: 'ja-jp-qwertz', help: 'Japanese' },
  { name: 'pt-br-qwertz', help: 'Portuguese/Brazilian' },
  { name: 'sv-se-qwertz', help: 'Swedish' },
  { name: 'tr-tr-qwertz', help: 'Turkish' },
  { name: 'failsafe', help: 'Unknown Keyboard' },
];

export function shadowCopy(obj = {}) {
  const res = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] !== undefined) {
      res[key] = obj[key];
    }
  });
  return res;
}
