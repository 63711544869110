import { getChildren } from 'components/shared/helpers';
import PropTypes from 'prop-types';
import React, { cloneElement, useMemo } from 'react';

const Actions = props => {
  const { showPrev, showNext, goPrev, goNext, align, style, ...options } = props;

  const newChildren = useMemo(() => {
    return getChildren(props.children, child => {
      if (child && child.props && child.props.action === 'prev') {
        return <div>{showPrev && cloneElement(child, { onClick: goPrev })}</div>;
      }
      if (child && child.props && child.props.action === 'next') {
        return <div>{showNext && cloneElement(child, { onClick: goNext })}</div>;
      }
      if (child && child.props && child.props.action === 'confirm') {
        return child;
      }
      return null;
    });
  }, [goNext, goPrev, props.children, showNext, showPrev]);

  const layoutStyle = { display: 'flex', justifyContent: align || 'space-between' };

  return (
    <div style={{ ...layoutStyle, ...style }} {...options}>
      {' '}
      {newChildren}
    </div>
  );
};

Actions.propTypes = {
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool,
  goPrev: PropTypes.func,
  goNext: PropTypes.func,
  align: PropTypes.oneOf([
    'center',
    'start',
    'end',
    'left',
    'right',
    'space-between',
    'space-around',
    'space-evenly',
  ]), //https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content
};

export default Actions;
