import './Users.module.less';

import {
  CrownTwoTone,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tabs,
  Tooltip,
} from 'antd';
import api from 'api';
import Page from 'components/Page';
import PagenationInfo from 'components/PagenationInfo';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getColumnSearchProps from 'utils/getColumnSearchProps';
import useInterval from 'utils/hooks/useInterval';
import usePermissions from 'utils/hooks/usePermissions';
import useUserRole from 'utils/hooks/useUserRole';
import { defaultSorter } from 'utils/util';

import CreateUserForm from './CreateUserForm';
import EditUserProfileForm from './EditUserProfileForm';
import ResetPasswordForm from './ResetPasswordForm';
import {
  createUser,
  EDGE_ADMIN,
  fetchPrivilegedSites,
  fetchRoles,
  fetchUsers,
  getTeanantAndUsersListRelations,
  isProvisionProcessing,
  isStandaloneEntitled,
  SITE_ADMIN,
  SUPER_ADMIN,
  updateUser,
  VIEWER,
} from './user.service';

const REFRESH_INTERVAL = 15000;

const Users = props => {
  const pageRef = useRef();
  const dispatch = useDispatch();
  const tenantUser = useSelector(state => state.globalSetting.user);
  const [sites, setSites] = useState([]);
  const role = useUserRole();
  const permissions = usePermissions(role);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState();
  const [user, setUser] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useState('-_created');
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const principalSearchRef = useRef();
  const emailSearchRef = useRef();
  const nameSearchRef = useRef();

  const editUserPermissions = useMemo(() => {
    const isStandaloneUser = _get(user, 'auth_type', '') === 'standalone';
    const isSelf = tenantUser.id === user.id;
    const allowEditProfile = isStandaloneUser && (isSelf || role.includes(SUPER_ADMIN));
    const allowResetPassword = isStandaloneUser && (isSelf || role.includes(SUPER_ADMIN));
    const allowAssignRole = !isSelf && permissions.user.editUser.role;
    const allowAssignSite = !isSelf && permissions.user.editUser.site;

    return {
      allowEditProfile,
      allowResetPassword,
      allowAssignRole,
      allowAssignSite,
    };
  }, [permissions.user.editUser.role, permissions.user.editUser.site, role, tenantUser.id, user]);

  const viewOnly = _get(tenantUser, 'role', []).includes('Viewer');

  const tableHeight = _get(document.querySelector('.page-content'), 'offsetHeight', 600) - 85;

  const getPrivilegedSites = useCallback(() => {
    fetchPrivilegedSites(tenantUser.id).then(data => {
      setSites(data);
    });
  }, [tenantUser.id]);

  const getUsers = useCallback(
    ({ page, sort } = {}) => {
      setLoading(true);
      fetchUsers({ page, pageSize, sort })
        .then(data => {
          setUsers(_get(data, 'data', []));
          setTotal(_get(data, 'meta.total'));
          setPage(_get(data, 'meta.page'));
          setLoading(false);
        })
        .catch(err => setLoading(false));
    },
    [pageSize]
  );

  const showAddUserModal = useCallback(() => {
    getPrivilegedSites();
    setModalType('CREATE');
    setUser({});
    setModalVisible(true);
  }, [getPrivilegedSites]);

  const showEditUserModal = useCallback(
    target => {
      getPrivilegedSites();
      setModalType('UPDATE');
      setUser(target);
      setModalVisible(true);
    },
    [getPrivilegedSites]
  );

  const deleteUser = useCallback(
    userId => {
      setLoading(true);
      api
        .deleteUser(userId)
        .then(res => getUsers({ page, pageSize, sort }))
        .catch(err => setLoading(false));
    },
    [getUsers, page, pageSize, sort]
  );

  const resetPassword = useCallback(
    values => {
      const payload = {
        auth_type: user.auth_type,
        password: values.password,
      };
      return updateUser(user.id, payload)
        .then(data => {
          message.success('Password has been successfully changed.');
          getUsers({ page, pageSize, sort });
          setModalVisible(false);
          return Promise.resolve();
        })
        .catch(err => Promise.reject(err));
    },
    [getUsers, page, pageSize, sort, user.auth_type, user.id]
  );

  const handleCreateUser = useCallback(
    values => {
      const payload = {
        auth_type: 'standalone',
        principal: values.principal.trim(),
        name: values.name.trim(),
        email: values.email.trim(),
        role: [values.role],
        password: values.password,
        sites: [],
      };
      switch (values.role) {
        case SUPER_ADMIN:
          payload.sites = [];
          break;
        case EDGE_ADMIN:
          payload.sites = [...values.sites.map(id => ({ id }))];
          break;
        case SITE_ADMIN:
          payload.sites = [{ id: values.sites }];
          break;
        case VIEWER:
        default:
          payload.sites = [];
      }
      return createUser(payload)
        .then(data => {
          message.success('User created.');
          getUsers();
          setModalVisible(false);
          return Promise.resolve();
        })
        .catch(err => Promise.reject(err));
    },
    [getUsers]
  );

  const handleUpdateUser = useCallback(
    values => {
      const commonData = {
        auth_type: user.auth_type,
        old_role_list: user.role,
        new_role_list: [values.role],
        sites: [],
        current_sites: user.sites,
      };
      const payload =
        user.auth_type === 'sso'
          ? {
              ...commonData,
            }
          : {
              ...commonData,
              name: values.name,
              email: values.email,
            };

      switch (values.role) {
        case SUPER_ADMIN:
          payload.sites = [];
          break;
        case EDGE_ADMIN:
          payload.sites = [...values.sites.map(id => ({ id }))];
          break;
        case SITE_ADMIN:
          payload.sites = [{ id: values.sites }];
          break;
        case VIEWER:
        default:
          payload.sites = [];
      }

      return updateUser(user.id, payload)
        .then(data => {
          message.success('The user profile has been successfully updated.');
          getUsers({ page, pageSize, sort });
          setModalVisible(false);
          return Promise.resolve();
        })
        .catch(err => Promise.reject(err));
    },
    [getUsers, page, pageSize, sort, user.auth_type, user.id, user.role, user.sites]
  );

  const onSorterChange = useCallback(
    (pagination, filters, sorter) => {
      let _sort = '-_created';
      const sortKey = _get(sorter, 'column.dataIndex', null);
      if (sorter.order) {
        _sort = !!sortKey && sorter.order === 'descend' ? `-${sortKey}` : sortKey;
        setSort(_sort);
      }
      getUsers({ page, pageSize, sort });
    },
    [getUsers, page, pageSize, sort]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }, []);

  const handleReset = useCallback(clearFilters => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  }, []);

  const paginationProps = useMemo(
    () => ({
      showSizeChanger: true,
      size: 'small',
      pageSize,
      onChange: page => {
        setPage(page);
        getUsers({ page, pageSize, sort });
      },

      onShowSizeChange: (current, pageSize) => {
        setPageSize(pageSize);
        getUsers();
      },
    }),
    [getUsers, pageSize, sort]
  );

  // render modal by actions
  const modal = useMemo(() => {
    switch (modalType) {
      case 'CREATE':
        return (
          <Modal
            title="Add Standalone User"
            centered
            width={700}
            maskClosable={false}
            visible={modalVisible}
            footer={null}
            destroyOnClose
            onCancel={() => setModalVisible(false)}
          >
            <CreateUserForm
              data={{ root: tenantUser.tenant.root_principal }}
              roles={roles}
              sites={sites}
              onSubmit={handleCreateUser}
              onCancel={() => setModalVisible(false)}
            />
          </Modal>
        );

      case 'UPDATE':
      default:
        return (
          <Modal
            title="User Detail"
            centered
            width={700}
            maskClosable={false}
            visible={modalVisible}
            footer={null}
            destroyOnClose
            onCancel={() => setModalVisible(false)}
          >
            {user.auth_type === 'standalone' ? (
              <Tabs tabPosition="left">
                <Tabs.TabPane tab="User Profile" key="profile">
                  <EditUserProfileForm
                    data={user}
                    roles={roles}
                    sites={sites}
                    permissions={editUserPermissions}
                    onSubmit={handleUpdateUser}
                    onCancel={() => setModalVisible(false)}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="Reset Password"
                  key="password"
                  disabled={!editUserPermissions.allowResetPassword}
                >
                  <ResetPasswordForm
                    onSubmit={resetPassword}
                    onCancel={() => setModalVisible(false)}
                  />
                </Tabs.TabPane>
              </Tabs>
            ) : (
              <EditUserProfileForm
                data={user}
                roles={roles}
                sites={sites}
                permissions={editUserPermissions}
                onSubmit={handleUpdateUser}
                onCancel={() => setModalVisible(false)}
              />
            )}
          </Modal>
        );
    }
  }, [
    editUserPermissions,
    handleCreateUser,
    handleUpdateUser,
    modalType,
    modalVisible,
    resetPassword,
    roles,
    sites,
    tenantUser.tenant.root_principal,
    user,
  ]);

  const renderActionColumn = useCallback(
    record => {
      const {
        isRoot,
        isTenantViewer,
        isTenantSSOUser,
        isTenantSSOAdmin,
        isTenantSSOViewer,
        isTenantStandaloneViewer,
        isSelf,
        isRecordSSOUser,
      } = getTeanantAndUsersListRelations(tenantUser, record);

      // please refer Users/README.md
      if (
        isRoot ||
        isTenantSSOViewer ||
        (isTenantSSOAdmin && isSelf) ||
        (isTenantStandaloneViewer && !isSelf)
      ) {
        return <span styleName="disabled">N/A</span>;
      } else if ((!isTenantViewer && isRecordSSOUser) || (!isTenantSSOUser && isSelf)) {
        return (
          <Button
            type="link"
            styleName="link-button"
            disabled={isProvisionProcessing(record)}
            onClick={() => showEditUserModal(record)}
          >
            Edit
          </Button>
        );
      } else {
        return (
          <Space>
            <Button
              type="link"
              styleName="link-button"
              disabled={isProvisionProcessing(record)}
              onClick={() => showEditUserModal(record)}
            >
              Edit
            </Button>

            {permissions.user.deleteUser && (
              <Popconfirm
                title="Are you sure？"
                disabled={isProvisionProcessing(record)}
                onConfirm={() => deleteUser(record.id)}
              >
                <Button
                  type="link"
                  styleName="link-button"
                  disabled={isProvisionProcessing(record)}
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      }
    },
    [deleteUser, permissions.user.deleteUser, tenantUser, showEditUserModal]
  );

  const columns = useMemo(
    () =>
      [
        {
          title: 'Principal',
          dataIndex: 'principal',
          width: 200,
          fixed: 'left',
          sorter: defaultSorter('principal'),
          render: (text, record) => (
            <span>
              {text}{' '}
              {record.root && (
                <Tooltip title="Root User">
                  <CrownTwoTone twoToneColor="#faad14" />
                </Tooltip>
              )}
              &nbsp;
              {record.id === tenantUser.id && (
                <Tooltip title="Me">
                  <UserOutlined style={{ color: '#1890ff' }} />
                </Tooltip>
              )}
            </span>
          ),
          ...getColumnSearchProps(
            'principal',
            principalSearchRef,
            searchText,
            searchedColumn,
            handleSearch,
            handleReset
          ),
        },
        {
          title: 'Name',
          dataIndex: 'name',
          width: 150,
          sorter: defaultSorter('name'),
          ...getColumnSearchProps(
            'name',
            nameSearchRef,
            searchText,
            searchedColumn,
            handleSearch,
            handleReset
          ),
        },
        {
          title: 'Email',
          dataIndex: 'email',
          width: 200,
          sorter: defaultSorter('email'),
          ...getColumnSearchProps(
            'email',
            emailSearchRef,
            searchText,
            searchedColumn,
            handleSearch,
            handleReset
          ),
        },
        isStandaloneEntitled() && {
          title: 'Auth Type',
          dataIndex: 'auth_type',
          width: 100,
          sorter: defaultSorter('auth_type'),
          defaultSortOrder: 'ascend',
          filters: [
            {
              text: 'SSO',
              value: 'sso',
            },
            {
              text: 'Stand-alone',
              value: 'standalone',
            },
          ],
          onFilter: (value, record) => record.auth_type === value,
          render: text => (text === 'sso' ? 'SSO' : 'Standalone'),
        },
        {
          title: 'Role',
          dataIndex: 'role',
          width: 120,
          render: (text, record) => _get(record, 'role[0]'),
        },
        {
          title: 'Site(s)',
          dataIndex: 'sites',
          width: 150,
          render: (text, record) => {
            const assignedSites = _get(record, 'sites', []);

            const hasFailed = assignedSites.some(
              ({ user_provision_status }) => user_provision_status === 'failed'
            );

            const content = (
              <Table
                rowKey="id"
                size="small"
                dataSource={assignedSites}
                pagination={false}
                scroll={{ y: 300, x: 360 }}
                style={{ maxHeight: '95vh', maxWidth: 360, overflow: 'auto' }}
              >
                <Table.Column title="Site Name" key="name" dataIndex="name" />
                <Table.Column
                  title="User Provision Status"
                  key="user_provision_status"
                  render={({ user_provision_status }) => {
                    switch (user_provision_status) {
                      case 'done':
                        return <Badge status="success" text="Success" />;
                      case 'pending':
                        return <Badge status="processing" text="Pending" />;
                      case 'failed':
                        return <Badge status="error" text="Failed" />;
                      default:
                        return '';
                    }
                  }}
                />
              </Table>
            );

            return (
              <Popover
                getPopupContainer={() =>
                  document.querySelector(`.${pageRef.current.props.className}`)
                }
                overlayClassName="assigned-sites-detail"
                title={'Assigned Site(s)'}
                content={content}
              >
                <Badge count={assignedSites.length} className={!hasFailed && 'default-badge'} />
                {isProvisionProcessing(record) && (
                  <LoadingOutlined
                    style={{ color: 'rgb(24, 144, 255)', fontSize: 12, marginLeft: 8 }}
                  />
                )}
              </Popover>
            );
          },
        },

        {
          title: 'Actions',
          key: 'action',
          width: 150,
          fixed: 'right',
          render: (text, record) => renderActionColumn(record),
        },
      ]
        // filter unavailable columns
        .filter(col => !!col),
    [handleReset, handleSearch, renderActionColumn, searchText, searchedColumn, tenantUser.id]
  );

  useEffect(() => {
    getPrivilegedSites();
    getUsers();
    fetchRoles().then(data => setRoles(data));
  }, [dispatch, getPrivilegedSites, getUsers]);

  const needPoll = useMemo(
    () => users.some(user => user.sites.some(site => site.user_provision_status === 'pending')),
    [users]
  );

  useInterval(() => {
    needPoll && getUsers({ page, pageSize, sort });
  }, REFRESH_INTERVAL);

  const toolbar = useMemo(
    () => (
      <Fragment>
        {!viewOnly && isStandaloneEntitled() && permissions.user.createUser && (
          <Button icon={<PlusOutlined />} onClick={showAddUserModal}>
            Add User
          </Button>
        )}
      </Fragment>
    ),
    [viewOnly, permissions.user.createUser, showAddUserModal]
  );
  const pageHeaderExtra = useMemo(
    () => (
      <div>
        <Tooltip title="Refresh" placement="left">
          <ReloadOutlined onClick={() => getUsers({ page, pageSize, sort })} />
        </Tooltip>
      </div>
    ),
    [getUsers, page, pageSize, sort]
  );

  return (
    <Page styleName="user" ref={pageRef}>
      <Page.Header className="flex-row space-between" title={toolbar} extra={pageHeaderExtra} />
      <section className="content flex-col flex-item">
        <Table
          rowKey="principal"
          loading={loading}
          columns={columns}
          dataSource={users}
          size="middle"
          pagination={false}
          onChange={onSorterChange}
          scroll={{ y: tableHeight, x: 900 }}
        />
      </section>
      {modal}
      <Page.Footer>
        <PagenationInfo current={page} size={pageSize} total={total} dataLength={users.length} />
        <Pagination {...paginationProps} current={page} pageSize={pageSize} total={total} />
      </Page.Footer>
    </Page>
  );
};

Users.propTypes = {};

export default Users;
