import * as theming from '@neutrino/theming';
import { ThemingManager } from '@neutrino/theming/theming-manager';
import { camelCase } from 'lodash';

const themingManager = new ThemingManager(document, window);
export default themingManager;

export const LIGHT_THEME = 'CloudAppLight';
export const DARK_THEME = 'CloudAppDark';

export const updateTheme = () => {
  const localTheme = localStorage.getItem('currentTheme');
  const themeName = [LIGHT_THEME, DARK_THEME].includes(localTheme) ? localTheme : DARK_THEME;

  const themeObj = theming[`${camelCase(themeName)}Theme`];

  themingManager.setActiveTheme(themeObj);
};
