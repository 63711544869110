import './SiteDetail.module.less';

import { Button, message } from 'antd';
import Page from 'components/Page';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  checkTurboSiteSyncStatus,
  getSiteInfo,
  getSiteList,
  patchSite,
} from 'redux/Security/Security.actions';
import { getPath } from 'routeConfig';
import { SYNC_QUERY_INTERVAL } from 'utils/constant';
import { RESOURCE_STATUS } from 'utils/constant';
import usePermissions from 'utils/hooks/usePermissions';
import useUserRole from 'utils/hooks/useUserRole';
import { getSiteStatus, isTurboSite, needQuerySyncStatus } from 'utils/services/site.service';

import StandaloneSiteDetail from './StandaloneSiteDetail';
import TurboSiteDetail from './TurboSiteDetail';

const SiteDetail = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: siteId } = useParams();
  const site = useSelector(state => state.security.sites.find(el => el.id === siteId) || {});
  const role = useUserRole();
  const permissions = usePermissions(role);
  const siteStatus = getSiteStatus(site);
  const isFailed = RESOURCE_STATUS.isFailed(siteStatus);
  const readOnly = !permissions.security.editSite || isFailed;

  useEffect(() => {
    // breadcrumb will find the site by id from the list and read site name from it
    dispatch(getSiteList());
    dispatch(getSiteInfo(siteId));
  }, [dispatch, siteId]);

  useEffect(() => {
    if (needQuerySyncStatus(site)) {
      const polling = setInterval(() => {
        dispatch(checkTurboSiteSyncStatus(siteId));
      }, SYNC_QUERY_INTERVAL);

      return () => clearInterval(polling);
    }
  }, [dispatch, site, siteId]);

  const actions = (
    <Button type="link" size="small" onClick={() => history.goBack()}>
      Cancel
    </Button>
  );

  const updateSite = useCallback(
    payload => {
      return dispatch(patchSite(siteId, payload)).then(_ => {
        message.success(`Saved`);
        return Promise.resolve();
      });
    },
    [dispatch, siteId]
  );

  return (
    <Page styleName="site-detail">
      <Page.Header
        title={_get(site, 'name', '')}
        extra={actions}
        onBack={() => history.push(getPath('site-list'))}
      />
      <div styleName="content">
        {!_isEmpty(site) &&
          (isTurboSite(site) ? (
            <TurboSiteDetail data={site} patchSite={updateSite} readOnly={readOnly} />
          ) : (
            <StandaloneSiteDetail data={site} patchSite={updateSite} readOnly={readOnly} />
          ))}
      </div>
    </Page>
  );
};

export default SiteDetail;
