import { ACCESS_TOKEN_KEY } from '@remote-user-portal/shared/constants';
import { decodeToken } from '@remote-user-portal/shared/utils';
import _get from 'lodash/get';
import { DARK_THEME, LIGHT_THEME, updateTheme } from 'remote-user-portal/shared/themingManager';

import { UPDATE_LOGIN_QUERY, UPDATE_REMOTE_USER, UPDATE_THEME } from './account.actions';

const defaultState = {
  loginQuery: '',
  theme: localStorage.getItem('currentTheme') === DARK_THEME ? 'dark' : 'light',
  user: _get(decodeToken(sessionStorage.getItem(ACCESS_TOKEN_KEY)), 'user', {}),
  license: _get(
    decodeToken(sessionStorage.getItem(ACCESS_TOKEN_KEY)),
    'user.tenant.company.license',
    {}
  ),
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_REMOTE_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };
    case 'UPDATE_LICNESE':
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE_THEME':
      localStorage.setItem(
        'currentTheme',
        action.payload.theme === 'dark' ? DARK_THEME : LIGHT_THEME
      );
      // update theme variables based on the currentTheme stored in localStorage
      updateTheme();
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE_LOGIN_QUERY':
      sessionStorage.setItem('loginQuery', action.payload.loginQuery);
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
