import './AssignApplicationsModal.module.less';

import { AppstoreOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Steps } from 'antd';
import api from 'api';
import clsx from 'clsx';
import produce from 'immer';
import React, { Component } from 'react';

import AppsList from './AppsList';
import AssignComfirmList from './AssignConfirmList';
import UsersList from './UsersList';

const { Step } = Steps;

class AssignpplicationsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appsData: props.apps.map(item => ({ ...item })),
      selectedAppId: props.apps[0].id,
      usersData: [],
      appUserMap: {},
      visible: true,
      loading: false,
      assignLoading: false,
      current: 0,
    };
  }

  componentDidMount() {
    this.getUsersAndApps();
  }

  onCancel = () => {
    this.setState({ visible: false });
  };

  async getUsersAndApps() {
    const { siteId } = this.props;

    this.setState({ loading: true });
    try {
      const resUsersData = await api.getUsersBySite(siteId);
      const resAppsToUsersData = await api.getAssignApplicationsToUsers(siteId);

      const usersData = resUsersData.data.data;
      const appUserMap = this.getAppUserMap(resAppsToUsersData.data.data);
      this.setState({
        usersData,
        appUserMap,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  getAppUserMap(appsToUsers) {
    const appUserMap = {};
    appsToUsers.forEach(appToUsersItem => {
      appUserMap[appToUsersItem.appid] = appToUsersItem.remoter_users.map(item => item.id);
    });
    return appUserMap;
  }

  handleAssign = () => {
    const { appUserMap } = this.state;
    const appsToUsers = Object.keys(appUserMap).map(appId => ({
      action: 'replace',
      appid: appId,
      remoter_users: appUserMap[appId].map(userId => ({ id: userId })),
    }));
    console.log('appsToUsers', appsToUsers);
    this.setState({ assignLoading: true });
    api
      .assignApplicationsToUsers(this.props.siteId, appsToUsers)
      .then(() => {
        this.setState({ visible: false });
      })
      .finally(() => {
        this.setState({ assignLoading: false });
      });
  };

  onAppChange = selectedAppId => {
    this.setState({ selectedAppId });
  };

  onUserSelectedChange = (appId, userId, checked) => {
    const appUserMap = produce(this.state.appUserMap, draftAppUserMap => {
      if (checked) {
        if (draftAppUserMap[appId]) {
          draftAppUserMap[appId].push(userId);
        } else {
          draftAppUserMap[appId] = [userId];
        }
      } else {
        draftAppUserMap[appId] = draftAppUserMap[appId].filter(id => id !== userId);
      }
    });

    this.setState({ appUserMap });
  };

  afterClose = () => {
    this.props.afterClose && this.props.afterClose();
  };

  handleStepClick = type => {
    const { current } = this.state;
    switch (type) {
      case 'prev':
        this.setState({ current: current - 1 });
        break;
      case 'next':
        this.setState({ current: current + 1 });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      visible,
      appsData,
      usersData,
      loading,
      assignLoading,
      selectedAppId,
      appUserMap,
      current,
    } = this.state;

    return (
      <Modal
        title="Assign Applications"
        visible={visible}
        width={800}
        maskClosable={false}
        afterClose={this.afterClose}
        onCancel={this.onCancel}
        footer={[
          <Button
            key="pre"
            className={clsx({ hide: current === 0 })}
            onClick={() => this.handleStepClick('prev')}
          >
            Previous
          </Button>,
          <Button
            key="next"
            type="primary"
            className={clsx({ hide: current === 1 })}
            onClick={() => this.handleStepClick('next')}
          >
            Next
          </Button>,
          <Button
            key="assign"
            type="primary"
            onClick={this.handleAssign}
            loading={assignLoading}
            className={clsx({ hide: current !== 1 })}
          >
            Assign
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Steps style={{ marginBottom: 16 }} current={current}>
            <Step title="Assign Apps to Users" icon={<AppstoreOutlined />} />
            <Step title="Confirm Assignments" icon={<CheckCircleOutlined />} />
          </Steps>
          {current === 0 && (
            <div styleName="assign-choose-container">
              <AppsList
                apps={appsData}
                onAppChange={this.onAppChange}
                initialSelectedAppId={selectedAppId}
              />
              <UsersList
                users={usersData}
                appUserMap={appUserMap}
                onUserSelectedChange={this.onUserSelectedChange}
                selectedAppId={selectedAppId}
              />
            </div>
          )}
          {current === 1 && (
            <AssignComfirmList apps={appsData} users={usersData} appUserMap={appUserMap} />
          )}
        </Spin>
      </Modal>
    );
  }
}

export default AssignpplicationsModal;
