import '@ant-design/compatible/assets/index.css';

import { Form } from '@ant-design/compatible';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import _get from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const ConfigGenationForm = Form.create({})(({ type, form, onSubmit, onCancel }) => {
  const { getFieldDecorator, getFieldsError, validateFields, resetFields } = form;

  const fieldsError = getFieldsError();

  const hasError = useMemo(() => Object.keys(fieldsError).some(field => fieldsError[field]), [
    fieldsError,
  ]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values);
          onSubmit(values);
        }
      });
    },
    [onSubmit, validateFields]
  );

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      {type === 'advance' && (
        <Form.Item label="LAN Name">
          {getFieldDecorator('lan_name', {
            rules: [{ required: true, message: 'This field is required.' }],
          })(<Input size="small" />)}
        </Form.Item>
      )}
      <Form.Item label="WAN Name">
        {getFieldDecorator('wan_name', {
          rules: [{ required: true, message: 'This field is required.' }],
        })(<Input size="small" />)}
      </Form.Item>
      <Form.Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        }}
      >
        <Button
          size="small"
          type="primary"
          icon={<DownloadOutlined />}
          htmlType="submit"
          disabled={hasError}
        >
          Generate
        </Button>
        <Button size="small" style={{ marginLeft: 10 }} onClick={() => resetFields()}>
          Reset
        </Button>
        <Button size="small" style={{ marginLeft: 10 }} type="link" onClick={() => onCancel()}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
});

const IPsecConfigGeneration = ({ type, downloadConfig, style }) => {
  const [editMode, setEditMode] = useState(false);
  const formRef = useRef();
  return (
    <div style={style}>
      {editMode ? (
        <div>
          <ConfigGenationForm
            ref={formRef}
            type={type}
            onSubmit={downloadConfig}
            onCancel={() => setEditMode(false)}
          />
        </div>
      ) : (
        <Button type="primary" data-test="generate-fgt-config" onClick={() => setEditMode(true)}>
          Generate FortiGate Configuration
        </Button>
      )}
    </div>
  );
};

IPsecConfigGeneration.propTypes = {
  downloadConfig: PropTypes.func.isRequired,
};

export default IPsecConfigGeneration;
