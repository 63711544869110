import { fetchWrap } from 'utils/fetch';

const resource = '/v1/setting/permission/scopes';

/**
 * @description get all scopes
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function getScopes(...cb) {
  return fetchWrap(
    {
      url: `${resource}`,
    },
    ...cb
  );
}

export default {
  getScopes,
};
