import api from '@remote-user-portal/api';
import jwtDecode from 'jwt-decode';
import _get from 'lodash/get';

import { cacheToken, setAuthenticated } from '../Auth/auth.actions';

export const UPDATE_REMOTE_USER = 'UPDATE_REMOTE_USER';
export const UPDATE_LICNESE = 'UPDATE_LICNESE';
export const UPDATE_LOGIN_QUERY = 'UPDATE_LOGIN_QUERY';
export const UPDATE_THEME = 'UPDATE_THEME';

export const changeTheme = theme => disptch => {
  disptch({
    type: UPDATE_THEME,
    payload: { theme },
  });
};

export const updateRemoteUser = user => ({
  type: UPDATE_REMOTE_USER,
  payload: { user },
});

export const updateLicense = license => ({
  type: UPDATE_LICNESE,
  payload: { license },
});

export const cacheLoginQuery = queryString => ({
  type: UPDATE_LOGIN_QUERY,
  payload: { loginQuery: queryString },
});

export const activateRemoteUser = (user_id, payload, token) => dispatch =>
  api.activateAccount(user_id, payload, token);

export const verifyToken = (type, token) => dispatch => api.verifyToken(type, token);

export const login = payload => dispatch =>
  api.login(payload).then(res => {
    const { access_token, refresh_token } = _get(res, 'data', {});

    dispatch(cacheToken({ access_token, refresh_token }));

    let user = {};
    try {
      const decodeObj = jwtDecode(access_token);
      user = _get(decodeObj, 'user', {});
    } catch (error) {
      console.error('parse token error');
    }
    dispatch(updateRemoteUser(user));
    dispatch(updateLicense(_get(user, 'tenant.company.license', {})));
    dispatch(setAuthenticated(true));
  });

export const resetPassword = (user_id, payload, token) => dispatch =>
  api.resetPassword(user_id, payload, token);

export const changePassword = (user_id, payload) => dispatch =>
  api.changePassword(user_id, payload).then(res => {
    const user = res.data;
    dispatch(updateRemoteUser(user));
  });

export const requestPasswordReset = payload => dispatch => api.sendEmail('reset_password', payload);
