import { fetchWrap } from 'utils/fetch';

const resource = '/v1/security/applications';

/** 
 * @param {} appData
{
  "name": "fortinet_oa",          # required
  "description": "for test",      # not required
  "tags": ['test'],               # not required
  "icon": "",                     # not required
  "starred": false,               # not required, default is false
  "scope": "site",                # required, options is tenant/site/user
  "type": "ssh",                  # required, options is ssh/web/rdp
  "config": {                     # required, the parameters decided by the category
      "host": ""
  },
  "owner_site": "xxxxx",             # only required for site/user scope app
  "owner_site_name": "xxxx",         # it could be existed either owner_site or owner_site_name
  "owner_remote_user": "xxxxx",      # only required for user scope app
  "owner_remoter_user_name": "xxx",  # it could be existed either owner_remote_user or owner_remote_user_name
}
*/
function addSiteApplication(appData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}`,
      method: 'post',
      data: appData,
    },
    ...cb
  );
}

/**
* @param {} appData
{
  "name": "fortinet_oa",          # not required
  "description": "for test",      # not required
  "tags": ['test'],               # not required
  "icon": "",                     # not required
  "starred": false,               # not required, default is false
  "config": {                     # not required, the parameters decided by the category
      "host": ""
  }
}
*/
function updateSiteApplication(appId, appData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${appId}`,
      method: 'patch',
      data: appData,
    },
    ...cb
  );
}

function deleteSiteApplication(appId, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${appId}`,
      method: 'delete',
    },
    ...cb
  );
}

function getSiteApplicationBy(appId, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${appId}`,
    },
    ...cb
  );
}

/**
 * @params {siteId, remoteUserId}
 * url parameters: site_id=xxxx&remote_user_id=xxxxx
 */
function getSiteApplications(siteId, ...cb) {
  return fetchWrap(
    {
      url: `${resource}?site_id=${siteId}`,
    },
    ...cb
  );
}

function getUsersBySite(siteId, ...cb) {
  return fetchWrap(
    {
      url: `/v1/security/sites/${siteId}/remote-users`,
    },
    ...cb
  );
}

/**
 * @param {} appsToUsers
{
  "assigned_users":[
    {
      "action": "replace"      #can be add/delete/replace, default is replace
      "appid":"xxxxx",
      "remoter_users":[
        {
          "id": "xxxxx"
        }
      ]
    }
  ]
}
*/
function assignApplicationsToUsers(siteId, appsToUsers, ...cb) {
  return fetchWrap(
    {
      url: `/v1/security/sites/${siteId}/applications/assigned_users`,
      method: 'patch',
      data: { assigned_users: appsToUsers },
    },
    ...cb
  );
}

function getAssignApplicationsToUsers(siteId, ...cb) {
  return fetchWrap(
    {
      url: `/v1/security/sites/${siteId}/applications/assigned_users`,
    },
    ...cb
  );
}

export default {
  addSiteApplication,
  deleteSiteApplication,
  updateSiteApplication,
  getSiteApplicationBy,
  getSiteApplications,
  getUsersBySite,
  assignApplicationsToUsers,
  getAssignApplicationsToUsers,
};
