import { fetchWrap } from 'utils/fetch';
import { QueryFormat } from 'utils/queryFormat';

const resource = '/v1/security/sites';

/**
 * @description - create a site
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {Object.<Site>} siteData
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function createSite(siteData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}`,
      method: 'post',
      data: {
        ...siteData,
        show_all_regions:
          window.sessionStorage.getItem('show_all_regions') === 'true' ? true : false,
      },
    },
    ...cb
  );
}

/**
 * @description - get site list
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-16
 * @param {number} page
 * @param {number} max_results
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function getSites(params = { page: 1, max_results: 25, sort: '-_created' }, ...cb) {
  const query = new QueryFormat();
  query.page = params.page;
  query.maxResults = params.max_results;
  query.sort = params.sort;

  return fetchWrap(
    {
      url: `${resource}`,
      params: query.toJson(),
    },
    ...cb
  );
}

/**
 * @description - get site data by id
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {string} siteId
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function getSite(siteId, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${siteId}`,
    },
    ...cb
  );
}

/**
 * @description - update site data
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {string} siteId
 * @param {Object} siteData
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function updateSite(siteId, siteData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${siteId}`,
      method: 'patch',
      data: {
        ...siteData,
        show_all_regions:
          window.sessionStorage.getItem('show_all_regions') === 'true' ? true : false,
      },
    },
    ...cb
  );
}

/**
 * @description
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {string} siteId
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function deleteSite(siteId, ...cb) {
  return fetchWrap({ url: `${resource}/${siteId}`, method: 'delete' }, ...cb);
}

/**
 *
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-05-07
 * @param {Array<string>} siteIds
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function deleteSites(siteIds, ...cb) {
  return fetchWrap(
    {
      url: `${resource}`,
      method: 'delete',
      params: {
        id: siteIds.join(','),
      },
    },
    ...cb
  );
}

/**
 *
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-06-11
 * @param {string} siteId
 * @param {Object} payload
 * @param {function} cb
 * @returns
 */
function downloadConfig(siteId, payload, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${siteId}/configuration`,
      method: 'post',
      data: payload,
    },
    ...cb
  );
}

/**
 * update site status
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-05-03
 * @param {*} siteId
 * @param {*} status
 * @param {callback} successCallback
 * @param {callback} errorCallback
 */
function changeSiteStatus(siteId, status, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${siteId}/status`,
      method: 'patch',
      data: {
        status,
      },
    },
    ...cb
  );
}

/**
 *
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-06-12
 * @param {string} siteId
 * @param {function} cb
 * @returns
 */
function getSiteConfig(siteId, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${siteId}/configuration`,
    },
    ...cb
  );
}

function getDefaultIpsecConfig(...cb) {
  return fetchWrap(
    {
      url: `${resource}/vpn/setting/template`,
    },
    ...cb
  );
}

function getDefaultIpRanges(ip, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/ipsec/config/addresses`,
      params: {
        ip,
      },
    },
    ...cb
  );
}

function reviewSiteData(payload, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/vpn/setting/thin-edge/advance`,
      method: 'post',
      data: payload,
    },
    ...cb
  );
}

function getUserStatus(siteId, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${siteId}/user-status`,
    },
    ...cb
  );
}

function recreateSite(id, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${id}/recreate`,
      method: 'post',
    },
    ...cb
  );
}

function checkTurboSiteSyncStatus(id, ...cb) {
  return fetchWrap({ url: `${resource}/${id}/sync_status`, method: 'post' }, ...cb);
}

function terminateSiteLog(id, ...cb) {
  return fetchWrap(
    { url: `${resource}/${id}/analytics`, method: 'delete' },
    ...cb
  ).then(({ data }) => Promise.resolve(data));
}

function enableSiteLog(id, payload, ...cb) {
  return fetchWrap(
    { url: `${resource}/${id}/analytics`, method: 'post', data: payload },
    ...cb
  ).then(({ data }) => Promise.resolve(data));
}

export default {
  createSite,
  getSites,
  getSite,
  updateSite,
  deleteSite,
  deleteSites,
  downloadConfig,
  changeSiteStatus,
  getSiteConfig,
  getDefaultIpsecConfig,
  getDefaultIpRanges,
  reviewSiteData,
  getUserStatus,
  recreateSite,
  checkTurboSiteSyncStatus,
  terminateSiteLog,
  enableSiteLog,
};
