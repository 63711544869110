import Icon from '@ant-design/icons';
import { ReactComponent as Admin } from 'assets/icons/admin.svg';
import { ReactComponent as AdminScope } from 'assets/icons/admin-scope.svg';
import { ReactComponent as Detail } from 'assets/icons/detail.svg';
import { ReactComponent as Expand } from 'assets/icons/expand-from-corner.svg';
import { ReactComponent as GripLines } from 'assets/icons/grip-lines.svg';
import { ReactComponent as Log } from 'assets/icons/log.svg';
import { ReactComponent as FortiCloudServices } from 'assets/icons/menu_icon.svg';
import { ReactComponent as Notification } from 'assets/icons/notification.svg';
import { ReactComponent as QRCode } from 'assets/icons/QR.svg';
import { ReactComponent as Retry } from 'assets/icons/retry.svg';
import { ReactComponent as Signin } from 'assets/icons/signin.svg';
import { ReactComponent as Sliders } from 'assets/icons/sliders.svg';
import { ReactComponent as StandaloneSite } from 'assets/icons/standalone-site.svg';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import { ReactComponent as FortiCloudSupport } from 'assets/icons/support24.svg';
import { ReactComponent as TurboSite } from 'assets/icons/turbo-site.svg';
import React from 'react';

const SvgIcon = ({ type, ...props }) => {
  let component = null;
  switch (type) {
    case 'grip-lines':
      component = GripLines;
      break;
    case 'star':
      component = Star;
      break;
    case 'sliders':
      component = Sliders;
      break;
    case 'admin':
      component = Admin;
      break;
    case 'admin-scope':
      component = AdminScope;
      break;
    case 'expand':
      component = Expand;
      break;
    case 'signin':
      component = Signin;
      break;
    case 'detail':
      component = Detail;
      break;
    case 'log':
      component = Log;
      break;
    case 'retry':
      component = Retry;
      break;
    case 'qr-code':
      component = QRCode;
      break;
    case 'notification':
      component = Notification;
      break;

    case 'turbo-site':
      component = TurboSite;
      break;

    case 'standalone-site':
      component = StandaloneSite;
      break;

    case 'forticloud-services':
      component = FortiCloudServices;
      break;
    case 'forticloud-support':
      component = FortiCloudSupport;
      break;
    default:
      break;
  }
  return <Icon component={component} {...props} />;
};

export default SvgIcon;
