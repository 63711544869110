import './PagenationInfo.module.less';

import React from 'react';

const PagenationInfo = ({ current, size, dataLength, total }) => {
  let from = 0,
    to = 0;

  if (!(current === 1 && dataLength === 0)) {
    from = (current - 1) * size + 1;
    to = from + dataLength - 1;
  }

  return (
    <span styleName="info-text">
      {from} to {to} of {total}
    </span>
  );
};

export default PagenationInfo;
