import './Exception.module.less';

import { Button } from 'antd';
import exceptionImg from 'assets/images/exception.svg';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { defaultRoute } from 'routeConfig';

const Exception = ({ type = 'Error', ...resProps }) => {
  return (
    <div styleName="exception" className="center">
      <img styleName="exception-img" src={exceptionImg} alt="404" />
      <div styleName="exception-text">
        <h1>{type}</h1>
        <p styleName="content">Whoops! Page can not be opened</p>
        <p>
          <Button type="primary" onClick={() => resProps.history.push(defaultRoute)}>
            Back to Home
          </Button>
        </p>
      </div>
    </div>
  );
};

export default withRouter(Exception);
