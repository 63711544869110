import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import globalSetting from 'redux/GlobalSetting/globalSetting.reducers';
import thunk from 'redux-thunk';

import account from './redux/Account/account.reducers';
import auth from './redux/Auth/auth.reducers';

const middleware =
  process.env.NODE_ENV !== 'production'
    ? [require('redux-immutable-state-invariant').default(), thunk]
    : [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  globalSetting,
  auth,
  account,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default store;
