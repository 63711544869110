import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const useUserRole = () => {
  const user = useSelector(state => state.globalSetting.user);
  const role = _get(user, 'role', []);
  return role;
};

useUserRole.propTypes = {};

export default useUserRole;
