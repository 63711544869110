import './AssignApplicationsModal.module.less';

import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input, List } from 'antd';
import React, { Component } from 'react';

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupMap: this.getUserGroup(props.users),
      userNameFilter: '',
    };
  }

  getUserGroup(usersData) {
    const groupMap = {};
    usersData.forEach(item => {
      const group = item.group;
      if (!groupMap[group]) {
        groupMap[group] = {
          count: 1,
          checkedCount: 0,
        };
      } else {
        groupMap[group].count++;
      }
    });
    return groupMap;
  }

  toggleSelectUser(userId, checked) {
    const { selectedAppId } = this.props;
    this.props.onUserSelectedChange(selectedAppId, userId, checked);
  }

  handleSearch(name) {
    this.setState({ userNameFilter: name.trim().toLowerCase() });
  }

  render() {
    const { userNameFilter } = this.state;
    const { appUserMap, selectedAppId } = this.props;
    const assignedUsers = appUserMap[selectedAppId] || [];
    const users = this.props.users.filter(userItem =>
      userItem.user_name.toLowerCase().includes(userNameFilter)
    );

    return (
      <div styleName="list-container">
        <div styleName="search">
          <h3>Users</h3>
          <Input
            prefix={<SearchOutlined />}
            styleName="search-input"
            size="small"
            style={{ width: 200 }}
            allowClear
            placeholder="Search by name"
            onChange={e => this.handleSearch(e.target.value)}
          />
        </div>
        <div styleName="users-list">
          <List
            itemLayout="horizontal"
            dataSource={users}
            rowKey="id"
            renderItem={item => (
              <List.Item
                actions={[
                  <Checkbox
                    key="checked"
                    checked={assignedUsers.includes(item.id)}
                    onChange={e => this.toggleSelectUser(item.id, e.target.checked)}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={item.user_name}
                  description={
                    <div styleName="user-desc">
                      <p>Email: {item.email}</p>
                      <p>Group: {item.group}</p>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  }
}

export default UsersList;
