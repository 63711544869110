import './CreateTurboSite.module.less';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Page from 'components/Page';
import Wizard from 'components/Wizard';
import en from 'Languages/en.json';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { THIN_EDGE_ADVANCE } from 'pages/Security/shared/IPsecVPN/IPsecConfig/constant';
import { checkVpnData } from 'pages/Security/shared/IPsecVPN/IPsecConfig/IPsecConfig.service';
import { reviewSiteData } from 'pages/Security/shared/site.service';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRegions } from 'redux/GlobalSetting/globalSetting.actions';
import { createSite, getSiteList } from 'redux/Security/Security.actions';
import { getPath } from 'routeConfig';
import useEntitlements from 'utils/hooks/useEntitlements';
import { getCustomFosVersion } from 'utils/services/site.service';

import LogConfig from './LogConfig';
import Result from './Result';
import Review from './Review';
import SiteInfo from './SiteInfo';
import SiteRegionConfig from './SiteRegionConfig';
import VpnConfig from './VpnConfig';

const CreateTurboSite = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const entitlements = useEntitlements();
  const isIpsecEntitled = entitlements.isIpsecEntitled;
  const cloudRegions = useSelector(state => state.globalSetting.regions || []);

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getSiteList()); // for getting remaining seats
  }, [dispatch]);

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [siteInfo, setSiteInfo] = useState({});
  const [siteConfig, setSiteConfig] = useState({});
  const [vpnConfig, setVpnConfig] = useState({});
  const [logConfig, setLogConfig] = useState({
    log_enable: true,
  });
  const [result, setResult] = useState({});
  const infoRef = useRef();
  const configRef = useRef();
  const vpnRef = useRef();
  const logRef = useRef();

  // get all the regions under the cloud provider selected for the turbo site
  const availableRegions = useMemo(() => {
    return cloudRegions.filter(el =>
      siteInfo.cloud_provider ? el.edge === siteInfo.cloud_provider : !!el
    );
  }, [cloudRegions, siteInfo.cloud_provider]);

  const siteData = useMemo(() => {
    const data = {
      ...siteInfo,
      ...logConfig,
      role: 'turbo',
      config: {
        master: {
          region_name: _get(siteConfig, 'master.region'),
        },
        slaves: _get(siteConfig, 'slaves', []).map(({ region, count }) => ({
          region_name: region,
          number: count,
          ipsecgateway_enable: !!vpnConfig.isIpsecEnabled,
          ...(vpnConfig.isIpsecEnabled && {
            ipsecgateway_customer_config: {
              type: vpnConfig.type,
              cloud: vpnConfig.cloud,
              bound_firewall_policy: vpnConfig.bound_firewall_policy,
            },
          }),
        })),
      },
    };
    return data;
  }, [
    logConfig,
    siteConfig,
    siteInfo,
    vpnConfig.bound_firewall_policy,
    vpnConfig.cloud,
    vpnConfig.isIpsecEnabled,
    vpnConfig.type,
  ]);

  const initialSteps = useMemo(() => {
    const _steps = [
      {
        title: 'Turbo Site Info',
        content: (
          <SiteInfo
            ref={infoRef}
            data={siteInfo}
            cloudRegions={cloudRegions}
            style={{ margin: '50px 0' }}
          />
        ),
        validator: () => {
          return (
            infoRef.current &&
            infoRef.current.validateFields().then(values => {
              setSiteInfo(values);
            })
          );
        },
      },
      {
        title: 'Federated Sites',
        content: (
          <SiteRegionConfig
            ref={configRef}
            data={siteConfig}
            cloudRegions={availableRegions}
            style={{ margin: '50px 0' }}
          />
        ),
        validator: () =>
          configRef.current && configRef.current.validate().then(values => setSiteConfig(values)),
      },
      isIpsecEntitled && {
        title: 'VPN',
        content: <VpnConfig ref={vpnRef} data={vpnConfig} />, //TODO: add default template
        validator: async () => {
          return (
            vpnRef.current &&
            vpnRef.current.validate().then(async values => {
              const payload = checkVpnData({
                ipsecgateway_enable: values.isIpsecEnabled,
                sslgateway_enable: true,
                ipsecgateway_customer_config: values.vpnConfig,
              });

              if (values.vpnConfig.type === THIN_EDGE_ADVANCE) {
                const autoFilledData = await reviewSiteData(payload);
                setVpnConfig({
                  isIpsecEnabled: values.isIpsecEnabled,
                  cloud: _get(autoFilledData, 'ipsecgateway_customer_config.cloud'),
                  type: values.vpnConfig.type,
                  bound_firewall_policy: values.vpnConfig.bound_firewall_policy,
                });
              } else {
                setVpnConfig({
                  isIpsecEnabled: values.isIpsecEnabled,
                  ...values.vpnConfig,
                });
              }
            })
          );
        },
      },
      {
        title: 'Analytics',
        content: <LogConfig ref={logRef} data={logConfig} style={{ margin: '50px 0' }} />,
        validator: () => {
          return (
            logRef.current &&
            logRef.current.validate().then(values => {
              setLogConfig(values);
            })
          );
        },
      },
      {
        title: submitted ? 'Submitted' : 'Review',
        content: submitted ? (
          <Result
            data={result}
            cloudRegions={availableRegions}
            onRedirect={() => history.push(getPath('site-list'))}
          />
        ) : (
          <Review data={siteData} cloudRegions={availableRegions} style={{ margin: '40px 0' }} />
        ),
      },
    ].filter(step => !!step);

    return _steps;
  }, [
    availableRegions,
    cloudRegions,
    history,
    isIpsecEntitled,
    logConfig,
    result,
    siteConfig,
    siteData,
    siteInfo,
    submitted,
    vpnConfig,
  ]);

  const [steps, setSteps] = useState(initialSteps);

  useEffect(() => {
    setSteps(initialSteps);
  }, [initialSteps]);

  const [currentStep, setCurrentStep] = useState(0);

  const handleSubmit = useCallback(() => {
    const fosVersion = getCustomFosVersion();
    setSubmitting(true);
    !submitting &&
      dispatch(createSite({ ...siteData, fos_version: fosVersion }))
        .then(result => {
          console.log({ result }, 'site result');
          setResult(result);
          setSubmitted(true);
          // scroll to page top
          document
            .querySelector('[class*="wizard-context"]')
            .scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        })
        .catch(err => console.log(err))
        .finally(() => setSubmitting(false));
  }, [dispatch, siteData, submitting]);

  const cancelBtn = (
    <Button type="link" size="small" onClick={() => history.goBack()}>
      Cancel
    </Button>
  );

  return (
    <Page>
      <Page.Header
        title={`New ${en.TERMS.TURBO_SITE}`}
        extra={cancelBtn}
        onBack={() => history.push(getPath('site-list'))}
      />

      <div className="flex-col flex-item" styleName="page-body">
        <Wizard
          steps={steps}
          currentStep={currentStep}
          trackerOptions={{ size: 'small' }}
          onStepChange={val => setCurrentStep(val)}
          styleName="wizard"
        >
          {!submitted && (
            <Wizard.Actions>
              <Button action="prev">
                <LeftOutlined style={{ fontSize: 12 }} />
                &nbsp;Prev
              </Button>
              <Button type="primary" action="next">
                {currentStep === steps.length - 2 ? (
                  'Review'
                ) : (
                  <Fragment>
                    Next &nbsp;
                    <RightOutlined style={{ fontSize: 12 }} />
                  </Fragment>
                )}
              </Button>
              {currentStep === steps.length - 1 && (
                <Button type="primary" action="confirm" onClick={handleSubmit}>
                  Create
                </Button>
              )}
            </Wizard.Actions>
          )}
        </Wizard>
      </div>
    </Page>
  );
};

export default CreateTurboSite;
