import { message } from 'antd';
import axios from 'axios';
import _get from 'lodash/get';
import { setAuthenticate } from 'redux/GlobalSetting/globalSetting.actions';
import store from 'store';

import { saveToken } from './util';

let isRefreshing = false;
let refreshSubscribers = [];

const refreshTokenURL = '/v1/system/refreshtoken';
const systemInfoUrl = '/v1/system/sys_maintenance/active';
const tokenRemovalUrl = '/v1/system/token/removal';

// use interceptor to handle 401 before it is catched by error handlers in application
axios.interceptors.response.use(
  response => response,
  error => {
    const { config } = error;
    const status = _get(error, 'response.status');
    const originalRequest = config;
    const bodyCode = _get(error, 'response.data.code');
    //when scope is empty will return error status 401 and error data: { code: 400 }
    // in this case do not need to refresh token
    if (status === 401 && bodyCode === 400) {
      handle401();
      return Promise.reject(error);
    }

    // if system info query got 401, no need to refresh token
    if (status === 401 && config.url === systemInfoUrl) {
      handle401();
      return Promise.reject(error);
    }

    if (
      status === 401 &&
      config.url !== refreshTokenURL &&
      config.url !== systemInfoUrl &&
      config.url !== tokenRemovalUrl
    ) {
      if (!isRefreshing) {
        // refresh access token
        refreshToken();
      }

      const retryOriginRequest = new Promise((resolve, reject) => {
        subscribeTokenRefresh(token => {
          originalRequest.baseURL = '/';
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginRequest;
    } else {
      return Promise.reject(error);
    }
  }
);

//websocket reconnect use cb
export function refreshToken(cb) {
  isRefreshing = true;
  axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
    },
    baseURL: '/api',
    url: refreshTokenURL,
    data: {
      refresh_token: sessionStorage.getItem('refresh_token'),
    },
  })
    .then(res => {
      saveToken(res.data.data);
      const access_token = sessionStorage.getItem('access_token');
      onRefreshed(access_token);
      cb && cb();
    })
    .catch(error => {
      isRefreshing = false;
      handle401();
    });
}

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function clearSubscribes() {
  refreshSubscribers = [];
}

function onRefreshed(token) {
  isRefreshing = false;
  refreshSubscribers.map(cb => cb(token));
  refreshSubscribers = [];
}

const nullFun = () => {};

export const handle401 = (() => {
  return () => {
    clearSubscribes();
    store.dispatch(setAuthenticate(false));
  };
})();

const handingError = (error, errorCb) => {
  // console.log('handingError', Object.keys(error), error.response);
  if (error.response) {
    let errMessage =
      _get(error, 'response.data.error.message') || _get(error, 'response.statusText');
    message.error(errMessage);
  }
  errorCb(error);
};

export const fetchWrap = (config = {}, successCb = data => data, errorCb = nullFun) => {
  return axios({
    method: 'get',
    baseURL: '/api',
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
    },
    ...config,
  })
    .then(res => {
      successCb(res);
      return Promise.resolve(res);
    })
    .catch(error => {
      handingError(error, errorCb);
      throw error;
    });
};

export function postForm(path, params = {}) {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', path);

  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export * as axios from 'axios';
