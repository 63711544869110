import './SiteDetail.module.less';

import { Button, Divider, Input, Popconfirm, Tag, Typography } from 'antd';
import api from 'api';
import clsx from 'clsx';
import EditableTagGroup from 'components/EditableTagGroup';
import InfoItem from 'components/InfoItem';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import IPsecConfigReview from 'pages/Security/shared/IPsecVPN/IPsecConfig/Review';
import IPsecConfigModal from 'pages/Security/TurboSite/components/FederatedSitesConfig/IPsecConfigModal';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from 'redux/GlobalSetting/globalSetting.actions';
import { getSiteInfo, getSiteList } from 'redux/Security/Security.actions';
import { DEFAULT_SLAVES_QUOTA, RESOURCE_STATUS } from 'utils/constant';
import {
  formatSlaveConfig,
  getLeader,
  getMaster,
  getSiteCloudProvider,
  getSiteStatus,
  getSiteStatusBadge,
  getSiteTypeLabel,
  getSlaves,
} from 'utils/services/site.service';

import { getIPsecOptions } from '../shared/site.service';
import FederatedSitesConfig from '../TurboSite/components/FederatedSitesConfig';
import { MasterConfigForm } from '../TurboSite/CreateTurboSite/SiteRegionConfig';
import FederatedSitesTable from './FederatedSitesTable';
import SiteLogConfigModal from './SiteLogConfigModal';

const TurboSiteDetail = props => {
  const { data, readOnly, patchSite } = props;
  const dispatch = useDispatch();
  const [configMode, setConfigMode] = useState(false);
  const [currSlaves, setCurrSlaves] = useState([]);
  const [newSlaves, setNewSlaves] = useState([]);
  const [leader, setLeader] = useState(getLeader(data));
  const [target, setTarget] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const breakpoint = useSelector(state => state.globalSetting.breakpoint);
  const cloudRegions = useSelector(state => state.globalSetting.regions || []);
  const descInputRef = useRef();
  const tagsInputRef = useRef();
  const newSlavesRef = useRef();
  const leaderRef = useRef();
  const logConfigRef = useRef();
  const tenantUser = useSelector(state => state.globalSetting.user);
  const license = _get(tenantUser, 'tenant.company.license', {});
  const showIPsec = _get(getIPsecOptions(license), 'visibility');

  const isConfigurable =
    !readOnly &&
    !RESOURCE_STATUS.isPending(getSiteStatus(data)) &&
    !RESOURCE_STATUS.isFailed(getSiteStatus(data));

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getSiteList());
  }, [dispatch]);

  const remainingSiteSeats = useSelector(state => state.security.remainingSeats);

  const cloudProvider = getSiteCloudProvider(data, cloudRegions);
  const typeLabel = getSiteTypeLabel(data);
  const tagBadges = data.tags && data.tags.map((el, index) => <Tag key={index}>{el}</Tag>);

  const disableConfigureSlave = readOnly || RESOURCE_STATUS.isPending(getSiteStatus(data));
  const allowLeaderChange = RESOURCE_STATUS.isRunning(getSiteStatus(data));

  const slaves = getSlaves(data);
  const sites = [...slaves];

  const leaderRegions = useMemo(() => {
    const sites = [
      ...currSlaves
        .filter(
          ({ _termination, resource_status }) =>
            !_termination &&
            (RESOURCE_STATUS.isRunning(resource_status) ||
              RESOURCE_STATUS.isWarning(resource_status))
        )
        .map(({ region_name }) => ({ region: region_name })),
    ];

    // leader can be selected from the regions selected by federated sites
    return cloudRegions.filter(item => sites.some(({ region }) => region === item.name));
  }, [cloudRegions, currSlaves]);

  useEffect(() => {
    if (leaderRegions.length === 1) {
      const leaderRegion = leaderRegions[0].name; // the only region that can be set as leader
      leaderRef.current && leaderRef.current.setFieldsValue({ region: leaderRegion });
    }
  }, [leaderRegions]);

  const handleSave = useCallback(value => patchSite(value), [patchSite]);

  const onEidt = useCallback(() => {
    setCurrSlaves(slaves);
    setConfigMode(true);
  }, [slaves]);

  const offEdit = useCallback(() => {
    setNewSlaves([]);
    setConfigMode(false);
  }, []);

  const saveSlaveConfig = useCallback(
    async value => {
      try {
        const newSites = await newSlavesRef.current.validate();
        const slaveConfig = formatSlaveConfig(
          newSites,
          currSlaves // existed slaves that are not marked with termination flag will be added to the payload
        );
        const payload = {
          config: {
            master: {
              region_name: leader,
            },
            slaves: slaveConfig,
          },
        };

        setSubmitting(true);
        !submitting &&
          handleSave(payload)
            .then(() => {
              setSubmitting(false);
              offEdit();
            })
            .catch(err => {
              setSubmitting(false);
            });
      } catch (err) {
        console.log({ err });
      }
    },
    [currSlaves, handleSave, leader, offEdit, submitting]
  );

  const totalSlaves = useMemo(() => {
    const newSlavesCount = newSlaves.reduce((result, item) => {
      result += item.count || 0;
      return result;
    }, 0);

    return currSlaves.length + newSlavesCount;
  }, [currSlaves.length, newSlaves]);

  const slaveQuota = DEFAULT_SLAVES_QUOTA - totalSlaves;

  const handleDisableLog = useCallback(() => {
    api.terminateSiteLog(data.id).then(() => dispatch(getSiteInfo(data.id)));
  }, [data.id, dispatch]);

  const handleTerminate = useCallback(
    item => {
      setCurrSlaves(
        currSlaves.map(obj => {
          if (obj.id === item.id) {
            obj._termination = true;
          }
          return obj;
        })
      );
    },
    [currSlaves]
  );

  const handleIPsecChange = useCallback(
    ({ id }, enabled) => {
      const sites = currSlaves.map(obj => {
        if (obj.id === id) {
          if (enabled && !obj.ipsecgateway_customer_config && !obj._ipsecgateway_customer_config) {
            setTarget(obj);
            setModalVisible(true);
          }
          obj.ipsecgateway_enable = enabled;
        }
        return obj;
      });
      setCurrSlaves(sites);
    },
    [currSlaves]
  );

  const handleIPsecEdit = useCallback(site => {
    setTarget(site);
    setModalVisible(true);
  }, []);

  const ipsecModal = useMemo(() => {
    const updateTargetConfig = values => {
      target._ipsecgateway_customer_config = values;
      setModalVisible(false);
    };
    const handleModalClose = () => {
      if (!target._ipsecgateway_customer_config) {
        target.ipsecgateway_enable = false;
      }
      setModalVisible(false);
      setTarget({});
    };

    return (
      <IPsecConfigModal
        data={_get(target, '_ipsecgateway_customer_config', {})}
        visible={modalVisible}
        onOk={updateTargetConfig}
        onCancel={handleModalClose}
      />
    );
  }, [modalVisible, target]);

  return (
    <div styleName={readOnly && 'read-only'} style={{ paddingBottom: 24 }}>
      <Divider orientation="left">Site Info</Divider>
      <div styleName="info-section">
        <InfoItem label="Site Name" value={data.name} />
        <InfoItem
          label="Description"
          value={data.description}
          inputElement={<Input.TextArea ref={descInputRef} defaultValue={data.description} />}
          editable={!readOnly}
          onSave={() =>
            handleSave({ description: descInputRef.current.resizableTextArea.props.value })
          }
        />
        <InfoItem label="Type" value={typeLabel} />
        <InfoItem label="Edge" value={cloudProvider} />

        <InfoItem
          label="Tags"
          value={tagBadges}
          inputElement={<EditableTagGroup ref={tagsInputRef} value={data.tags || []} />}
          editable={!readOnly}
          onSave={() => handleSave({ tags: tagsInputRef.current.state.tags })}
        />

        <InfoItem
          label="Status"
          value={
            <span>
              {getSiteStatusBadge(data)} {data.state_label}
            </span>
          }
        />
      </div>

      <br />

      <Divider orientation="left">Thin Edge</Divider>
      <div styleName="info-section">
        {showIPsec && (
          <Fragment>
            <InfoItem label="Thin Edge" value={data.ipsecgateway_enable ? 'Enabled' : 'Disabled'} />
            {data.ipsecgateway_enable && (
              <IPsecConfigReview data={_get(data, 'ipsecgateway_customer_config')} />
            )}
          </Fragment>
        )}
      </div>

      <br />

      <Divider orientation="left">Analytics</Divider>

      <div styleName="info-section">
        {_get(data, 'analytics.log_enable') ? (
          <Fragment>
            <InfoItem label="Region" value={_get(data, 'analytics.region_alias')} />
            <InfoItem label="Type" value={_upperFirst(_get(data, 'analytics.resource_model'))} />
            <InfoItem
              label="Status"
              value={
                <span>
                  {getSiteStatusBadge(_get(data, 'analytics', {}))}{' '}
                  {_get(data, 'analytics.state_label')}
                </span>
              }
            />
            <Popconfirm
              title="Are you sure to terminate Analytics for this site?"
              okText="Yes"
              onConfirm={handleDisableLog}
            >
              <Button disabled={!isConfigurable}>Terminate</Button>
            </Popconfirm>
          </Fragment>
        ) : (
          <Fragment>
            {_get(data, 'analytics.state_label') && (
              <InfoItem
                label="Status"
                value={
                  <span>
                    {getSiteStatusBadge(_get(data, 'analytics', {}))}{' '}
                    {_get(data, 'analytics.state_label')}
                  </span>
                }
              />
            )}
            <Button
              disabled={!isConfigurable}
              onClick={() => logConfigRef.current.showModal(data.id)}
            >
              Configure
            </Button>
            <SiteLogConfigModal ref={logConfigRef} />
          </Fragment>
        )}
      </div>

      <br />

      <Divider orientation="left">Federated Sites</Divider>
      <div style={{ marginLeft: 54, marginRight: 54 }}>
        <div style={{ marginLeft: -16 }}>
          <FederatedSitesTable
            configMode={configMode}
            onTerminateSite={handleTerminate}
            onIPsecChange={handleIPsecChange}
            onIPsecEdit={handleIPsecEdit}
            data={configMode ? currSlaves : sites}
          />
        </div>

        {configMode && (
          <div styleName="slaves-config-panel" style={{ overflow: 'auto' }}>
            <FederatedSitesConfig
              ref={newSlavesRef}
              quota={slaveQuota}
              seats={remainingSiteSeats}
              cloudRegions={cloudRegions.filter(({ edge }) => edge === cloudProvider)}
              onChange={values => setNewSlaves(values)}
            />
            <div style={{ padding: 16 }}>
              <Divider />
              <div
                className={clsx({
                  'flex-row space-between': breakpoint !== 'xs',
                  'flex-col-reverse': breakpoint === 'xs',
                })}
                style={{ marginBottom: 24 }}
              >
                <div className="flex-row cross-center" style={{ marginTop: -30 }}>
                  Leader:
                  <MasterConfigForm
                    ref={leaderRef}
                    data={{ region: leader }}
                    cloudRegions={leaderRegions}
                    disabled={!allowLeaderChange}
                    onChange={values => setLeader(values.region)}
                  />
                </div>

                <Typography.Text strong styleName="total" className={slaveQuota < 0 && 'error'}>
                  Total: {totalSlaves}
                </Typography.Text>
              </div>

              <div className="flex-row main-end cross-center">
                <Button type="primary" onClick={saveSlaveConfig} loading={submitting}>
                  Save
                </Button>
                <Button onClick={offEdit} style={{ marginLeft: 24 }}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
        <br />

        {!configMode && (
          <Button disabled={disableConfigureSlave} onClick={onEidt}>
            Configure
          </Button>
        )}
      </div>

      {ipsecModal}
    </div>
  );
};

TurboSiteDetail.propTypes = {
  data: PropTypes.object.isRequired,
  patchSite: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default TurboSiteDetail;
