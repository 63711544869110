import './SiteList.module.less';

import {
  AppstoreFilled,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
  TableOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Divider,
  Dropdown,
  Empty,
  Input,
  InputNumber,
  Menu,
  Modal,
  Space,
  Spin,
  Switch,
  Tooltip,
} from 'antd';
import api from 'api';
import clsx from 'clsx';
import Page from 'components/Page';
import SiteCard from 'components/SiteCard';
import SvgIcon from 'components/SvgIcon';
import en from 'Languages/en.json';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _words from 'lodash/words';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateSiteListOptions } from 'redux/GlobalSetting/globalSetting.actions';
import {
  deleteSite,
  deleteSites,
  getSiteList,
  recreateSite,
} from 'redux/Security/Security.actions';
import { getPath } from 'routeConfig';
import { RESOURCE_STATUS } from 'utils/constant';
import useHKey from 'utils/hooks/useHKey';
import usePermissions from 'utils/hooks/usePermissions';
import useUserRole from 'utils/hooks/useUserRole';

import SiteTable from './SiteTable';

const { Search } = Input;

const confirm = Modal.confirm;

const defaultModalConfig = {
  centered: true,
  autoFocusButton: null,
  maskClosable: false,
};

const SiteList = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.globalSetting.user);
  const siteListOptions = useSelector(state => state.globalSetting.siteListOptions);
  const loading = useSelector(state => state.security.loading);
  const breakpoint = useSelector(state => state.globalSetting.breakpoint);

  const remainingSiteSeats = useSelector(state => state.security.remainingSeats);
  const remainingTurboSeats = useSelector(state => state.security.remainingTurboSeats);
  const { storeHKey } = useHKey();
  const role = useUserRole();
  const permissions = usePermissions(role);
  const allowTerminate = _get(permissions, 'security.terminateSite', false);

  const viewMode = _get(siteListOptions, 'siteListViewMode', 'table');
  const isTableView = viewMode === 'table';
  const isCardView = viewMode === 'card';

  const [pageSettingsVisible, setPageSettingsVisible] = useState(false);
  const [columnConfigVisible, setColumnConfigVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterSettings, setFilterSettings] = useState({
    visible: false,
    standaloneCheck: true,
    turboCheck: true,
    searchString: '',
  });

  const { turboCheck, standaloneCheck, searchString } = filterSettings;

  const sites = useSelector(state => state.security.sites)
    .filter(site => {
      return (
        site.name.includes(searchString) ||
        (_isArray(site.tags) && site.tags.join().includes(searchString))
      );
    })
    .filter(site => {
      if (standaloneCheck && !turboCheck) {
        return site.role === 'standalone';
      } else if (!standaloneCheck && turboCheck) {
        return site.role === 'turbo';
      } else {
        return true;
      }
    });

  useEffect(() => {
    if (!permissions.security.accessible) {
      history.push(getPath('users'));
    }
    dispatch(getSiteList());
  }, [dispatch, history, permissions.security.accessible]);

  useEffect(() => {
    // check if all sites are still available

    let needUpdate = false;

    const list = selectedItems.filter(site => {
      const found = sites.find(({ id }) => id === site.id);
      if (!found) {
        needUpdate = true;
      }
      return !!found;
    });

    if (needUpdate) {
      setSelectedItems(list);
    }
  }, [selectedItems, sites]);

  useEffect(() => {
    const { autoReload, interval } = siteListOptions;
    if (autoReload) {
      const timeout = setInterval(() => dispatch(getSiteList()), interval * 1000);
      return () => clearInterval(timeout);
    }
  }, [dispatch, siteListOptions]);

  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectedItems(selectedRows);
      },
      getCheckboxProps: record => ({
        disabled: !allowTerminate || RESOURCE_STATUS.isPending(record.resource_status), // Column configuration not to be checked
        name: record.name,
      }),
    }),
    [allowTerminate]
  );

  const onSorterChange = useCallback(
    (pagination, filters, sorter) => {
      const sortKey = _get(sorter, 'column.dataIndex', null);
      const sort = !!sortKey && sorter.order === 'descend' ? `-${sortKey}` : sortKey;
      dispatch(getSiteList({ sort }));
    },
    [dispatch]
  );

  const handleCreateSite = useCallback(
    type => {
      if (type === 'turbo') {
        if (remainingSiteSeats > 0 && remainingTurboSeats > 0) {
          const path = type === 'turbo' ? getPath('create-turbo-site') : getPath('create-site');
          history.push(path);
          return;
        }
      }

      if (type === 'standalone' && remainingSiteSeats > 0) {
        const path = getPath('create-site');
        history.push(path);
        return;
      }

      Modal.warning({
        centered: true,
        title: 'Site Limit Reached',
        content:
          'You have reached the Maximum Site Limit in your license. To create more sites, please upgrade your license.',
      });
    },
    [history, remainingSiteSeats, remainingTurboSeats]
  );

  const handleRecreateSite = useCallback(site => dispatch(recreateSite(site)), [dispatch]);

  const handleEnterSite = useCallback(
    site => {
      api
        .getUserStatus(site.id)
        // check user_status
        // if user_status is not 'done', popup error
        .then(res => {
          const { user_status } = _get(res, 'data.data', {});
          if (user_status === 'done') {
            storeHKey();
            const path = getPath('site-fos', { id: site.id });
            history.push(path);
          } else {
            Modal.error({
              ...defaultModalConfig,
              title: 'Something went wrong, please try again later.',
              content: 'Access Denied',
              okText: 'Close',
              okType: 'default',
            });
          }
        });
    },
    [history, storeHKey]
  );

  const handleEditSite = useCallback(
    site => {
      const path = getPath('site-detail', { id: site.id });
      history.push(path);
    },
    [history]
  );

  const routeToNetworkAccessInfo = useCallback(
    site => {
      const path = getPath('site-network-access-info', { id: site.id });
      history.push(path);
    },
    [history]
  );

  const handleAddAppForSite = useCallback(
    site => {
      const path = getPath('site-applications', { id: site.id });
      history.push(path);
    },
    [history]
  );

  const handleDeleteSite = useCallback(
    site => {
      confirm({
        ...defaultModalConfig,
        title: `Are you sure to ${en.ACTIONS.TERMINATE.toLowerCase()} this ${en.TERMS.SITE.toLowerCase()}: ${
          site.name
        }?`,
        content: `${_words(
          en.ACTIONS.TERMINATE
        )} the ${en.TERMS.SITE.toLowerCase()} will also delete its data.`,
        okText: en.ACTIONS.TERMINATE,
        okButtonProps: {
          type: 'danger',
        },
        onOk: () => dispatch(deleteSite(site)),
      });
    },
    [dispatch]
  );

  const handleDeleteSites = useCallback(
    sites => {
      if (!selectedItems.length) return;

      dispatch(deleteSites(sites))
        .then(() => setSelectedItems([]))
        .catch(err => setSelectedItems([]));
    },
    [dispatch, selectedItems.length]
  );

  const handleAccessAuditLog = useCallback(
    site => {
      const filters = [
        {
          filterBy: 'visited_id',
          operator: '=',
          filterValue: site.id,
          path: 'Common.visited_id',
        },
        {
          filterBy: 'visited_type',
          operator: '=',
          filterValue: 'Security Site',
          path: 'Common.visited_type',
        },
      ];

      const filtersStr = JSON.stringify(filters);
      const queryParams = `filters=${encodeURIComponent(filtersStr)}`;
      const url = `${getPath('audit')}?${queryParams}`;

      history.push(url);
    },
    [history]
  );

  const switchViewMode = useCallback(
    mode => {
      try {
        if (mode === 'table' && selectedItems && selectedItems.length) {
          rowSelection.selectedRowKeys = selectedItems.map(({ name }) => name);
        }
      } catch (err) {
        console.log(err);
      }

      dispatch(updateSiteListOptions({ ...siteListOptions, siteListViewMode: mode }));
    },
    [dispatch, rowSelection, selectedItems, siteListOptions]
  );

  const createBtn = useMemo(() => {
    return (
      <Button
        type="primary"
        className="primary"
        icon={<PlusOutlined />}
        onClick={() => handleCreateSite('turbo')}
      >
        {en.ACTIONS.CREATE}
      </Button>
    );
  }, [handleCreateSite]);

  const actionPanel = useMemo(() => {
    const selectedCount = selectedItems && selectedItems.length > 0 ? selectedItems.length : null;
    const isDeletable = (allowTerminate && !selectedItems) || !selectedItems.length;

    return (
      <span styleName="action-panel" style={breakpoint === 'xs' ? { marginTop: '16px' } : null}>
        {createBtn}

        {isTableView && (
          <span>
            <Badge count={selectedCount}>
              <Button
                icon={<DeleteOutlined />}
                disabled={isDeletable}
                onClick={() =>
                  confirm({
                    ...defaultModalConfig,
                    title: `Are you sure to ${en.ACTIONS.TERMINATE.toLowerCase()} these ${en.TERMS.SITE_PLURAL.toLowerCase()}?`,
                    content: (
                      <Fragment>
                        {selectedItems.map(({ id, name }) => (
                          <p key={id}>{name}</p>
                        ))}
                      </Fragment>
                    ),
                    okText: en.ACTIONS.TERMINATE,
                    okButtonProps: {
                      type: 'danger',
                    },
                    onOk: () => handleDeleteSites(selectedItems),
                  })
                }
              >
                {en.ACTIONS.TERMINATE}
              </Button>
            </Badge>
          </span>
        )}
      </span>
    );
  }, [allowTerminate, breakpoint, createBtn, handleDeleteSites, isTableView, selectedItems]);

  const pageSettings = useMemo(() => {
    let newSettings = { ...siteListOptions };

    const pageSettingsMenu = (
      <Menu selectable={false} style={{ minWidth: 200 }}>
        <Menu.Item className="flex-row space-between cross-center no-hover">
          <span>Auto Reload</span>
          <Switch
            size="small"
            defaultChecked={siteListOptions.autoReload}
            onChange={checked => (newSettings = { ...newSettings, autoReload: checked })}
          />
        </Menu.Item>

        <Menu.Item className="flex-row space-between cross-center no-hover">
          <span>Interval (sec)</span>
          <InputNumber
            size="small"
            min={5}
            step={5}
            defaultValue={siteListOptions.interval}
            onChange={value => (newSettings = { ...newSettings, interval: value })}
            style={{ maxWidth: 64 }}
          />
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item
          disabled={isCardView}
          onClick={() => {
            setPageSettingsVisible(false);
            setColumnConfigVisible(true);
          }}
        >
          <Tooltip title={isCardView ? 'Not available for card layout' : ''}>
            <span>Show/Hide Columns</span>
          </Tooltip>
        </Menu.Item>
      </Menu>
    );
    return (
      <Tooltip title="Custom Settings" placement="topLeft">
        <Dropdown
          trigger={['click']}
          overlay={pageSettingsMenu}
          visible={pageSettingsVisible}
          onVisibleChange={flag => {
            dispatch(updateSiteListOptions(newSettings));
            setPageSettingsVisible(flag);
          }}
        >
          <SvgIcon type="sliders" className="util-btn" />
        </Dropdown>
      </Tooltip>
    );
  }, [dispatch, isCardView, pageSettingsVisible, siteListOptions]);

  const toolPanel = useMemo(() => {
    const handleSearchQuery = _debounce(value => {
      setFilterSettings({ ...filterSettings, searchString: value });
    }, 500);

    return (
      <div styleName="tool-panel" className="flex-row cross-center">
        <Search
          placeholder="Search by Name or Tag"
          onChange={e => handleSearchQuery(e.target.value)}
          style={{ width: breakpoint === 'xs' ? '100%' : 220, marginRight: 20 }}
          styleName="search"
        />

        {/* {showTurbo && siteFilter} */}

        <Tooltip title="Refresh" placement="top">
          <ReloadOutlined className="util-btn" onClick={() => dispatch(getSiteList())} />
        </Tooltip>

        {isTableView && (
          <Tooltip title="Card View" placement="top">
            <AppstoreFilled className="util-btn" onClick={() => switchViewMode('card')} />
          </Tooltip>
        )}

        {isCardView && (
          <Tooltip title="Table View" placement="top">
            <TableOutlined className="util-btn" onClick={() => switchViewMode('table')} />
          </Tooltip>
        )}

        {pageSettings}
      </div>
    );
  }, [breakpoint, dispatch, filterSettings, isCardView, isTableView, pageSettings, switchViewMode]);

  return (
    <Page styleName="site-list">
      <div
        styleName="header"
        className={clsx(breakpoint === 'xs' ? 'flex-col' : 'flex-row space-between cross-center')}
      >
        {toolPanel}
        <Space>
          <span>Total: {sites.length}</span>
          <Divider type="vertical" />
          {actionPanel}
        </Space>
      </div>

      {loading && <Spin styleName="spinner" />}
      <Fragment>
        {isTableView && (
          <SiteTable
            user={user}
            sites={sites}
            configTbModalVisible={columnConfigVisible}
            closeConfigTbModal={() => setColumnConfigVisible(false)}
            rowSelection={rowSelection}
            onChange={onSorterChange}
            onEnterSite={handleEnterSite}
            onEditSite={handleEditSite}
            onAddAppForSite={handleAddAppForSite}
            onAccessAuditLog={handleAccessAuditLog}
            onAccessSiteInfo={routeToNetworkAccessInfo}
            onDeleteSite={handleDeleteSite}
            onRecreate={handleRecreateSite}
          ></SiteTable>
        )}

        {isCardView && (
          <div className="flex-row flex-item wrap">
            {sites.length === 0 && !loading ? (
              <Empty style={{ margin: 'auto' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              sites.map(site => (
                <SiteCard
                  key={site.id}
                  data={site}
                  onEnterSite={handleEnterSite}
                  onEditSite={handleEditSite}
                  onAddAppForSite={handleAddAppForSite}
                  onAccessAuditLog={handleAccessAuditLog}
                  onAccessSiteInfo={routeToNetworkAccessInfo}
                  onDeleteSite={handleDeleteSite}
                  onRecreate={handleRecreateSite}
                />
              ))
            )}
          </div>
        )}
      </Fragment>
    </Page>
  );
};

SiteList.propTypes = {};

export default SiteList;
