const Lockr = {};
if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (elt /*, from*/) {
    var len = this.length >>> 0;
    var from = Number(arguments[1]) || 0;
    from = from < 0 ? Math.ceil(from) : Math.floor(from);
    if (from < 0) from += len;
    for (; from < len; from++) {
      if (from in this && this[from] === elt) return from;
    }
    return -1;
  };
}
Lockr.prefix = '';
Lockr._getPrefixedKey = function (key, options) {
  options = options || {};
  if (options.noPrefix) {
    return key;
  } else {
    return this.prefix + key;
  }
};
Lockr.set = function (key, value, options) {
  console.log(`🥭 LICENSE DATA key: ${key}`);
  console.log(`🥭 LICENSE DATA value: ${value}`);
  console.log(`🥭 LICENSE DATA value: ${options}`);
  var query_key = this._getPrefixedKey(key, options);
  try {
    sessionStorage.setItem(query_key, JSON.stringify({ data: value }));
  } catch (e) {
    if (console)
      console.warn(
        "Lockr didn't successfully save the '{" +
          key +
          ': ' +
          value +
          "}' pair, because the sessionStorage is full."
      );
  }
};
Lockr.get = function (key, missing, options) {
  var query_key = this._getPrefixedKey(key, options),
    value;
  try {
    value = JSON.parse(sessionStorage.getItem(query_key));
  } catch (e) {
    if (sessionStorage[query_key]) {
      value = { data: sessionStorage.getItem(query_key) };
    } else {
      value = null;
    }
  }
  if (!value) {
    return missing;
  } else if (typeof value === 'object' && typeof value.data !== 'undefined') {
    return value.data;
  }
};
Lockr.sadd = function (key, value, options) {
  var query_key = this._getPrefixedKey(key, options),
    json;
  var values = Lockr.smembers(key);
  if (values.indexOf(value) > -1) {
    return null;
  }
  try {
    values.push(value);
    json = JSON.stringify({ data: values });
    sessionStorage.setItem(query_key, json);
  } catch (e) {
    console.log(e);
    if (console)
      console.warn(
        "Lockr didn't successfully add the " +
          value +
          ' to ' +
          key +
          ' set, because the sessionStorage is full.'
      );
  }
};
Lockr.smembers = function (key, options) {
  var query_key = this._getPrefixedKey(key, options),
    value;
  try {
    value = JSON.parse(sessionStorage.getItem(query_key));
  } catch (e) {
    value = null;
  }
  return value && value.data ? value.data : [];
};
Lockr.sismember = function (key, value, options) {
  return Lockr.smembers(key).indexOf(value) > -1;
};
Lockr.keys = function () {
  var keys = [];
  var allKeys = Object.keys(sessionStorage);
  if (Lockr.prefix.length === 0) {
    return allKeys;
  }
  allKeys.forEach(function (key) {
    if (key.indexOf(Lockr.prefix) !== -1) {
      keys.push(key.replace(Lockr.prefix, ''));
    }
  });
  return keys;
};
Lockr.getAll = function (includeKeys) {
  var keys = Lockr.keys();
  if (includeKeys) {
    return keys.reduce(function (accum, key) {
      var tempObj = {};
      tempObj[key] = Lockr.get(key);
      accum.push(tempObj);
      return accum;
    }, []);
  }
  return keys.map(function (key) {
    return Lockr.get(key);
  });
};
Lockr.srem = function (key, value, options) {
  var query_key = this._getPrefixedKey(key, options),
    json,
    index;
  var values = Lockr.smembers(key, value);
  index = values.indexOf(value);
  if (index > -1) values.splice(index, 1);
  json = JSON.stringify({ data: values });
  try {
    sessionStorage.setItem(query_key, json);
  } catch (e) {
    if (console) console.warn("Lockr couldn't remove the " + value + ' from the set ' + key);
  }
};
Lockr.rm = function (key) {
  var queryKey = this._getPrefixedKey(key);
  sessionStorage.removeItem(queryKey);
};
Lockr.flush = function () {
  if (Lockr.prefix.length) {
    Lockr.keys().forEach(function (key) {
      sessionStorage.removeItem(Lockr._getPrefixedKey(key));
    });
  } else {
    sessionStorage.clear();
  }
};
window.Lockr = Lockr;
export default Lockr;
