import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { THIN_EDGE_PRO } from '../constant';
import EditLocationModal from './EditLocationModal';

const LocationList = forwardRef(({ type, data = [], editable, style }, ref) => {
  const [locations, setLocations] = useState(data);
  const editLocationModalRef = useRef();
  const [action, setAction] = useState(null);
  const [targetIndex, setTargetIndex] = useState(null); // used for updating location data in the list
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useImperativeHandle(ref, () => ({
    getLocations: () => {
      return locations;
    },
  }));

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: keys => setSelectedRowKeys(keys),
    }),
    [selectedRowKeys]
  );

  const addLocation = useCallback(e => {
    console.log({ e });
    e.preventDefault();
    setAction('ADD');
    editLocationModalRef.current.showModal();
  }, []);

  const editLocation = useCallback((data, index) => {
    setAction('EDIT');
    setTargetIndex(index);
    editLocationModalRef.current.showModal(data);
  }, []);

  const removeLocation = useCallback(
    index => {
      const updatedLocations = [...locations];
      updatedLocations.splice(index, 1);
      setLocations(updatedLocations);
    },
    [locations]
  );

  const removeMultiLocations = useCallback(
    names => {
      const updatedLocations = locations.filter(item => !selectedRowKeys.includes(item.name));
      setLocations(updatedLocations);
      setSelectedRowKeys([]);
    },
    [locations, selectedRowKeys]
  );

  const handleLocationEdit = data => {
    switch (action) {
      case 'ADD':
        setLocations([...locations, data]);
        break;
      case 'EDIT':
        const updatedLocations = _set(locations, `[${targetIndex}]`, data);

        setLocations([...updatedLocations]);
        break;
      default:
    }
    setAction(null);
    setTargetIndex(null);
  };

  const table = useMemo(
    () => (
      <Table
        rowKey="name"
        dataSource={locations}
        rowSelection={editable && rowSelection}
        size="small"
        pagination={false}
        style={style}
      >
        <Table.Column title="Name" dataIndex="name" key="name" />
        <Table.Column title="Description" dataIndex="description" key="description" />
        <Table.Column
          title="Geo Location"
          key="location"
          render={record => {
            const country = _get(record, 'address.country');
            const state = _get(record, 'address.state');
            const city = _get(record, 'address.city');

            return `${country || '-'}/${state || '-'}/${city || '-'}`;
          }}
        />
        <Table.Column title="Owner" dataIndex="owner" key="owner" />
        {type === THIN_EDGE_PRO && (
          <>
            <Table.Column
              title="NAT"
              dataIndex="nat_enable"
              key="nat"
              render={enabled => (enabled ? 'Enabled' : 'Disabled')}
            />
            <Table.Column
              title="LAN IP/CIDR"
              dataIndex="cpe_networks"
              key="cpe_network"
              render={value =>
                _isArray(value) && !_isEmpty(value)
                  ? value.map(({ cpe_network }) => cpe_network).join(', ')
                  : '-'
              }
            />
          </>
        )}

        {editable && (
          <Table.Column
            key="actions"
            render={(text, record, index) => (
              <Space>
                <Button type="link" onClick={() => editLocation(record, index)}>
                  Edit
                </Button>
                <Button type="link" danger onClick={() => removeLocation(index)}>
                  Remove
                </Button>
              </Space>
            )}
          />
        )}
      </Table>
    ),
    [editLocation, editable, locations, removeLocation, rowSelection, style, type]
  );

  return editable ? (
    <>
      <Space
        direction="vertical"
        className="flex-col"
        style={{ width: '100%', padding: '16px 24px', overflow: 'auto' }}
      >
        <div style={{ marginLeft: -6 }}>{table}</div>

        <Space style={{ marginLeft: 8 }}>
          <Button ghost type="primary" icon={<PlusOutlined />} onClick={addLocation}>
            Add Location
          </Button>

          {selectedRowKeys.length > 0 && (
            <Button icon={<DeleteOutlined />} onClick={removeMultiLocations}>
              Delete Locations
            </Button>
          )}
        </Space>
      </Space>

      <EditLocationModal ref={editLocationModalRef} type={type} onFinish={handleLocationEdit} />
    </>
  ) : (
    <div style={{ marginLeft: -8 }}>{table}</div>
  );
});

LocationList.propTypes = {
  data: PropTypes.array,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

export default LocationList;
