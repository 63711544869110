import './SiteList.module.less';

import {
  AppstoreOutlined,
  AuditOutlined,
  DownOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  ProfileOutlined,
  RedoOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Badge, Dropdown, Menu, Popover, Tag } from 'antd';
import defaultSnapshot from 'assets/images/default-site.png';
import failedSnapshot from 'assets/images/default-site-failed.png';
import ConfigTable from 'components/ConfigTable';
import FailureDetail from 'components/FailureDetail';
import en from 'Languages/en.json';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useMemo } from 'react';
import { RESOURCE_STATUS } from 'utils/constant';
import usePermissions from 'utils/hooks/usePermissions';
import useUserRole from 'utils/hooks/useUserRole';
import {
  getSiteStatus,
  getSubjectStatus,
  getValSetFromAllSites,
  isTurboSite,
} from 'utils/services/site.service';
import { decompressSnapshot, defaultSorter, toLocalTime } from 'utils/util';

const SiteTable = ({
  sites,
  onEnterSite,
  onEditSite,
  onAddAppForSite,
  onAccessAuditLog,
  onAccessSiteInfo,
  onDeleteSite,
  onRecreate,
  user,
  ...options
}) => {
  const role = useUserRole();
  const permissions = usePermissions(role);
  const applicationAuth = _get(user, 'tenant.company.license.security_entitlement.application', {});

  const isDeleting = useCallback(site => {
    const siteStatus = getSiteStatus(site);
    return site.status === 'delete' && RESOURCE_STATUS.isPending(siteStatus);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: en.SYSTEM.NAME,
        dataIndex: 'name',
        key: 'name',
        onCell: () => {
          return {
            className: 'ellipsis',
          };
        },
        render: (text, record) => <span title={text}>{text}</span>,
        sorter: defaultSorter('name'),
        mustBeShown: true,
      },
      {
        title: en.SYSTEM.SNAPSHOT,
        dataIndex: 'snapshot',
        key: 'snapshot',
        // width: '20%',
        onCell: () => {
          return {
            className: 'ellipsis',
          };
        },
        render: (text, record) => (
          <img
            src={
              RESOURCE_STATUS.isFailed(record.resource_status)
                ? failedSnapshot
                : record.snapshot
                ? decompressSnapshot(record.snapshot)
                : defaultSnapshot
            }
            alt="snapshot"
            style={{ height: 53, width: 120 }}
          />
        ),
        hide: true,
      },
      {
        title: en.SYSTEM.DESCRIPTION,
        dataIndex: 'description',
        key: 'description',
        // width: '20%',
        onCell: () => {
          return {
            className: 'ellipsis',
          };
        },
        render: (text, record) => <span title={text}>{text}</span>,
      },

      {
        title: 'Region',
        dataIndex: 'region_alias',
        key: 'region',
        onCell: () => {
          return {
            className: 'ellipsis',
          };
        },
        render: (text, record) => {
          if (!isTurboSite(record)) {
            return <span title={text}>{text}</span>;
          } else {
            const regionsArr = getValSetFromAllSites(record, 'region_alias');
            const content = (
              <div>
                {regionsArr.map(region => (
                  <p key={region}>{region}</p>
                ))}
              </div>
            );
            return regionsArr.length > 1 ? (
              <Popover content={content}>
                <span className="link">{regionsArr.length}</span>
              </Popover>
            ) : (
              <span title={regionsArr[0]}>{regionsArr[0]}</span>
            );
          }
        },
        hide: true,
      },
      {
        title: 'Region ID',
        dataIndex: 'region_name',
        key: 'region_id',
        onCell: () => {
          return {
            className: 'ellipsis',
          };
        },
        render: (text, record) => {
          if (!isTurboSite(record)) {
            return <span title={text}>{text}</span>;
          } else {
            const regionIDsArr = getValSetFromAllSites(record, 'region_name');
            const content = (
              <div>
                {regionIDsArr.map(regionID => (
                  <p key={regionID}>{regionID}</p>
                ))}
              </div>
            );
            return regionIDsArr.length > 1 ? (
              <Popover content={content}>
                <span className="link">{regionIDsArr.length}</span>
              </Popover>
            ) : (
              <span title={regionIDsArr[0]}>{regionIDsArr[0]}</span>
            );
          }
        },
        sorter: defaultSorter('region_name'),
        hide: true,
      },
      {
        title: 'FOS POP',
        dataIndex: 'pop_alias',
        key: 'pop',
        onCell: () => {
          return {
            className: 'ellipsis',
          };
        },
        render: (text, record) => {
          if (!isTurboSite(record)) {
            return <span title={text}>{text}</span>;
          } else {
            const popsArr = getValSetFromAllSites(record, 'pop_alias');
            const content = (
              <div>
                {popsArr.map(pop => (
                  <p key={pop}>{pop}</p>
                ))}
              </div>
            );
            return popsArr.length > 1 ? (
              <Popover content={content}>
                <span className="link">{popsArr.length}</span>
              </Popover>
            ) : (
              <span title={popsArr[0]}>{popsArr[0]}</span>
            );
          }
        },
        hide: true,
      },
      {
        title: 'FOS POP ID',
        dataIndex: 'pop_name',
        key: 'pop_id',
        onCell: () => {
          return {
            className: 'ellipsis',
          };
        },
        render: (text, record) => {
          if (!isTurboSite(record)) {
            return <span title={text}>{text}</span>;
          } else {
            const popIDsArr = getValSetFromAllSites(record, 'pop_name');
            const content = (
              <div>
                {popIDsArr.map(popID => (
                  <p key={popID}>{popID}</p>
                ))}
              </div>
            );
            return popIDsArr.length > 1 ? (
              <Popover content={content}>
                <span className="link">{popIDsArr.length}</span>
              </Popover>
            ) : (
              <span title={popIDsArr[0]}>{popIDsArr[0]}</span>
            );
          }
        },
        sorter: defaultSorter('pop_name'),
        hide: true,
      },
      {
        title: 'Type',
        dataIndex: 'resource_model',
        key: 'type',
        onCell: () => {
          return {
            className: 'ellipsis capitalize',
          };
        },
        render: (text, record) => {
          const { role } = record;
          return (
            <span title={text}>
              {role} - {text}
            </span>
          );
        },
        sorter: defaultSorter('resource_model'),
      },
      {
        title: en.SYSTEM.TAG_PLURAL,
        dataIndex: 'tags',
        key: 'tags',
        render: text => {
          const tags = text || [];
          return (
            <span>
              {tags.map(tag => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </span>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'resource_status',
        key: 'status',
        onCell: () => {
          return {
            className: 'ellipsis capitalize',
          };
        },
        render: (text, record) => {
          let status;
          const { resource_status, state_label } = record;
          switch (resource_status.toLowerCase()) {
            case RESOURCE_STATUS.RUNNING:
              status = 'success';
              break;
            case RESOURCE_STATUS.PENDING:
              status = 'processing';
              break;
            case RESOURCE_STATUS.WARNING:
              status = 'warning';
              break;
            case RESOURCE_STATUS.FAILED:
              status = 'error';
              break;
            default:
              status = 'default';
          }

          return (
            <span title={resource_status}>
              {resource_status === RESOURCE_STATUS.PENDING ? (
                <span>
                  <LoadingOutlined styleName="loading-icon" />{' '}
                  {state_label ? state_label.replace(/-/g, ' ') : resource_status}
                </span>
              ) : (
                <Fragment>
                  <Badge status={status} text={state_label} />
                  {resource_status === 'failed' && (
                    <Fragment>
                      <FailureDetail
                        data={{
                          resourceFailedType: record.resource_failed_type,
                          resourceFailedMessage: record.resource_failed_message,
                          timestamp: record._updated,
                          action: record.status,
                          isRecreatable: record.is_recreatable,
                        }}
                        onRecreate={() => onRecreate(record)}
                      ></FailureDetail>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </span>
          );
        },
        sorter: defaultSorter('resource_status'),
      },
      {
        title: 'Created',
        dataIndex: '_created',
        key: 'created',
        defaultSortOrder: 'descend',
        onCell: () => {
          return {
            className: 'ellipsis',
          };
        },
        render: (text, record) => {
          const localeTimeString = toLocalTime(text);
          return <span title={localeTimeString}>{localeTimeString}</span>;
        },
        sorter: (a, b) => {
          try {
            const t1 = new Date(a._created).getTime();
            const t2 = new Date(b._created).getTime();
            return t1 - t2;
          } catch (err) {
            return -1;
          }
        },
        hide: true,
      },
      {
        title: 'Updated',
        dataIndex: '_updated',
        key: 'updated',
        onCell: () => {
          return {
            className: 'ellipsis',
          };
        },
        render: (text, record) => {
          const localeTimeString = toLocalTime(text);
          return <span title={localeTimeString}>{localeTimeString}</span>;
        },
        sorter: (a, b) => {
          try {
            const t1 = new Date(a._updated).getTime();
            const t2 = new Date(b._updated).getTime();
            return t1 - t2;
          } catch (err) {
            return -1;
          }
        },
        hide: true,
      },
      {
        title: en.SYSTEM.ACTION_PLURAL,
        key: 'actions',
        render: (text, record) => {
          const menu = (
            <Menu>
              <Menu.Item
                key="0"
                disabled={
                  !permissions.security.enterSite ||
                  !RESOURCE_STATUS.isRunning(getSubjectStatus(record))
                }
                onClick={() => onEnterSite(record)}
              >
                <span>
                  {/* <SvgIcon type="signin" /> */}
                  <ImportOutlined style={{ transform: 'scale(-1)' }} />
                  &nbsp;Enter {en.TERMS.SITE}
                  {/* &nbsp;
                  {getSubjectStatus(record) === 'running' && !isAdmin && record.user_status !== 'done' && (
                    <Icon type="exclamation-circle" style={{ color: '#f5222d' }} />
                  )} */}
                </span>
              </Menu.Item>
              <Menu.Item
                key="1"
                disabled={!permissions.security.editSite && isDeleting(record)}
                onClick={() => onEditSite(record)}
              >
                <span>
                  <ProfileOutlined />
                  &nbsp;{en.TERMS.SITE} Detail
                </span>
              </Menu.Item>
              {applicationAuth.visibility && (
                <Menu.Item
                  key="site-application"
                  disabled={
                    isDeleting(record) || !RESOURCE_STATUS.isRunning(getSubjectStatus(record))
                  }
                  onClick={() => onAddAppForSite(record)}
                >
                  <span>
                    <AppstoreOutlined />
                    &nbsp;Applications
                  </span>
                </Menu.Item>
              )}
              <Menu.Item
                key="2"
                disabled={
                  isDeleting(record) || !RESOURCE_STATUS.isRunning(getSubjectStatus(record))
                }
                onClick={() => onAccessSiteInfo(record)}
              >
                <span>
                  <InfoCircleOutlined />
                  &nbsp;{en.TERMS.SITE_INFO}
                </span>
              </Menu.Item>

              <Menu.Item key="3" onClick={() => onAccessAuditLog(record)}>
                <span>
                  <AuditOutlined />
                  &nbsp;Audit Log
                </span>
              </Menu.Item>

              <Menu.Divider />

              <Menu.Item
                key="4"
                disabled={!permissions.security.enterSite || !record.is_recreatable}
                onClick={() => onRecreate(record)}
              >
                <span>
                  <RedoOutlined />
                  &nbsp;Recreate {en.TERMS.SITE}
                </span>
              </Menu.Item>

              <Menu.Item
                key="5"
                disabled={
                  !permissions.security.terminateSite ||
                  RESOURCE_STATUS.isPending(getSubjectStatus(record))
                }
                onClick={() => {
                  onDeleteSite(record);
                }}
              >
                <span>
                  <StopOutlined />
                  &nbsp;{en.ACTIONS.TERMINATE} {en.TERMS.SITE}
                </span>
              </Menu.Item>
            </Menu>
          );

          return (
            <Dropdown
              overlay={menu}
              overlayClassName={`site-list-actions-dropdown`}
              trigger={['click']}
            >
              <span className="link">
                Actions <DownOutlined />
              </span>
            </Dropdown>
          );
        },
        mustBeShown: true,
      },
    ],
    [
      applicationAuth.visibility,
      isDeleting,
      onAccessAuditLog,
      onAccessSiteInfo,
      onAddAppForSite,
      onDeleteSite,
      onEditSite,
      onEnterSite,
      onRecreate,
      permissions.security.editSite,
      permissions.security.enterSite,
      permissions.security.terminateSite,
    ]
  );

  return (
    <ConfigTable
      rowKey="name"
      localStorageId="siteListConfig"
      minColumnWidth={150}
      dataSource={sites}
      columns={columns}
      scroll={{ y: 'max-content' }}
      pagination={false}
      allowReset={true}
      {...options}
    />
  );
};

SiteTable.propTypes = {
  sites: PropTypes.array,
  onEnterSite: PropTypes.func,
  onEditSite: PropTypes.func,
  onAddAppForSite: PropTypes.func,
  onAccessAuditLog: PropTypes.func,
  onAccessSiteInfo: PropTypes.func,
  onDeleteSite: PropTypes.func,
  onRecreate: PropTypes.func,
};

export default SiteTable;
