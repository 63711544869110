import './SiteApplications.module.less';
import '@ant-design/compatible/assets/index.css';

import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Input, InputNumber, message, Modal, Radio, Select, Switch, Upload } from 'antd';
import adminUserApi from 'api';
import React, { Component, Fragment } from 'react';
import remoteUserApi from 'remote-user-portal/api';
import { validRDPHost, validSSH, validURL } from 'utils/commonRegex';

import {
  appTypeOptions,
  formItemLayout,
  keyboardLayoutOptions,
  securityOptions,
  shadowCopy,
  typeToLabelMap,
} from './siteAppUtils';

class AddApplicationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      uploadImgLoading: false,
      loading: false,
      appType: this.modalTypeIncluds('edit') ? props.appData.type : 'web',
      imageUrl: this.modalTypeIncluds('edit') ? props.appData.icon : '',
      useSSLVpnCredentialsValue: this.modalTypeIncluds('edit')
        ? !!props.appData.config.useSSLVpnCredentials
        : false,
      offerToSavePassword: this.modalTypeIncluds('edit')
        ? !!props.appData.config.offerToSavePassword
        : false,
    };
  }

  modalTypeIncluds(type) {
    return this.props.modalType.includes(type);
  }

  onCancel = () => {
    this.setState({ visible: false });
  };

  handleAction = () => {
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        let config = {};
        switch (values.appType) {
          case 'web':
            config = shadowCopy(values.web);
            break;
          case 'ssh':
            config = shadowCopy(values.ssh);
            break;
          case 'rdp':
            config = shadowCopy(values.rdp);
            break;
          default:
            break;
        }
        this.setState({ loading: true });
        if (this.modalTypeIncluds('edit') && !this.modalTypeIncluds('clone')) {
          this.updateSiteApplication(values, config);
        } else if (this.modalTypeIncluds('clone')) {
          this.cloneSiteApplication(values, config);
        } else {
          this.addSiteApplication(values, config);
        }
      }
    });
  };

  updateSiteApplication = (values, config) => {
    const { scope = 'site', appData } = this.props;
    const params = {
      name: values.appName,
      description: values.description,
      icon: this.state.imageUrl,
      config: config,
    };
    if (appData.name === values.appName) {
      // application do not allow same name
      delete params.name;
    }
    if (scope === 'site') {
      params.config.offerToSavePassword = this.state.offerToSavePassword;
    }
    const apiUpdateFn =
      scope === 'user' ? remoteUserApi.updateRemoteUserApp : adminUserApi.updateSiteApplication;
    apiUpdateFn(
      appData.id,
      params,
      () => {
        this.setState({ visible: false });
        this.props.afterAddCb && this.props.afterAddCb();
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };

  cloneSiteApplication = (values, config) => {
    const { appData, ownerParams = {} } = this.props;
    const params = {
      name: values.appName,
      description: values.description,
      type: values.appType,
      icon: this.state.imageUrl || appData.icon,
      scope: 'user',
      config: config,
      ...ownerParams,
    };
    remoteUserApi.addRemoteUserApp(
      params,
      () => {
        this.setState({ visible: false });
        this.props.afterAddCb && this.props.afterAddCb();
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };

  addSiteApplication = (values, config) => {
    const { scope = 'site', ownerParams = {} } = this.props;
    const params = {
      name: values.appName,
      description: values.description,
      type: values.appType,
      icon: this.state.imageUrl,
      scope: scope,
      config: config,
      ...ownerParams,
    };
    if (scope === 'site') {
      params.config.offerToSavePassword = this.state.offerToSavePassword;
    }
    const apiAddFn =
      scope === 'user' ? remoteUserApi.addRemoteUserApp : adminUserApi.addSiteApplication;
    apiAddFn(
      params,
      () => {
        this.setState({ visible: false });
        this.props.afterAddCb && this.props.afterAddCb();
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };

  afterClose = () => {
    this.props.afterClose && this.props.afterClose();
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      console.log('reader.result', callback(reader.result));
      callback(reader.result);
    });
    reader.readAsDataURL(img);
  };

  validator = (validateFn, invalidMsg) => (rule, value, callback) => {
    if (value && !validateFn(value)) {
      callback(invalidMsg);
    } else {
      callback();
    }
  };

  beforeUpload(file) {
    const imgTypes = ['image/jpeg', 'image/png', 'image/vnd.microsoft.icon'];
    const isValidImg = imgTypes.includes(file.type);
    if (!isValidImg) {
      message.error('You can only upload JPG/PNG/ICO file!');
    }

    const isInSizeLimit = file.size / 1024 / 1024 < 0.1;
    if (!isInSizeLimit) {
      message.error('Image must smaller than 100 KB!');
    }
    return isValidImg && isInSizeLimit;
  }

  handleIconChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ uploadImgLoading: true });
    }
  };

  onRequest = info => {
    this.getBase64(info.file, imageUrl => {
      this.setState({
        imageUrl,
        uploadImgLoading: false,
      });
    });
  };

  handleAppTypeChange = appType => {
    this.setState({ appType });
  };

  hanleRdpCredientialsChange = checked => {
    this.setState({ useSSLVpnCredentialsValue: checked });
  };

  hanleOfferToSavePassword = checked => {
    this.setState({ offerToSavePassword: checked });
  };

  render() {
    const { scope = 'site', appData } = this.props;
    const isModalEdit = this.modalTypeIncluds('edit');
    const {
      visible,
      appType,
      imageUrl,
      useSSLVpnCredentialsValue,
      offerToSavePassword,
      loading,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    const uploadButton = (
      <div>
        <LegacyIcon type={this.state.uploadImgLoading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Modal
        title={`${this.props.modelTitle} Application`}
        width={640}
        bodyStyle={{ height: 500, overflow: 'auto' }}
        visible={visible}
        okText="Add"
        maskClosable={false}
        afterClose={this.afterClose}
        onCancel={this.onCancel}
        footer={[
          <Button key="cancel" onClick={this.onCancel}>
            Cancel
          </Button>,
          <Button key="submit" loading={loading} type="primary" onClick={this.handleAction}>
            {isModalEdit ? 'Update' : 'Add'}
          </Button>,
        ]}
      >
        <Form {...formItemLayout}>
          <Form.Item label="App Type">
            {getFieldDecorator('appType', {
              initialValue: appType,
            })(
              <Radio.Group
                onChange={e => this.handleAppTypeChange(e.target.value)}
                disabled={isModalEdit}
              >
                {appTypeOptions.map(item => (
                  <Radio key={item} value={item}>
                    {typeToLabelMap[item]}
                  </Radio>
                ))}
              </Radio.Group>
            )}
          </Form.Item>

          {scope === 'site' && appType !== 'web' && (
            <Form.Item label="Offer To Save Password">
              {getFieldDecorator('offerToSavePassword', {
                initialValue: offerToSavePassword,
                valuePropName: 'checked',
              })(<Switch onChange={this.hanleOfferToSavePassword} />)}
            </Form.Item>
          )}

          <Form.Item label="App Name">
            {getFieldDecorator('appName', {
              initialValue: isModalEdit ? appData.name : '',
              rules: [
                {
                  required: true,
                  message: 'Please input App Name',
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator('description', {
              initialValue: isModalEdit ? appData.description : '',
            })(<Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />)}
          </Form.Item>

          <Form.Item label="App Icon">
            <Upload
              name="avatar"
              listType="picture-card"
              styleName="icon-uploader"
              showUploadList={false}
              customRequest={this.onRequest}
              beforeUpload={this.beforeUpload}
              onChange={this.handleIconChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          {appType === 'web' && (
            <Form.Item label="URL">
              {getFieldDecorator('web.url', {
                initialValue: isModalEdit ? appData.config.url : '',
                rules: [
                  {
                    required: true,
                    message: 'Please input URL',
                  },
                  {
                    validator: this.validator(validURL, 'Invalid URL'),
                  },
                ],
              })(<Input />)}
            </Form.Item>
          )}

          {appType === 'ssh' && (
            <Fragment>
              <Form.Item label="Host">
                {getFieldDecorator('ssh.host', {
                  initialValue: isModalEdit ? appData.config.host : '',
                  rules: [
                    {
                      required: true,
                      message: 'Please input host',
                    },
                    {
                      validator: this.validator(validSSH, 'Invalid SSH Address'),
                    },
                  ],
                })(<Input placeholder="hostname:port" />)}
              </Form.Item>
              <Form.Item label="User Name">
                {getFieldDecorator('ssh.username', {
                  initialValue: isModalEdit ? appData.config.username : '',
                })(<Input autoComplete="off" />)}
              </Form.Item>

              <Form.Item label="Password">
                {getFieldDecorator('ssh.password', {
                  initialValue: isModalEdit ? appData.config.password : '',
                })(<Input.Password autoComplete="new-password" />)}
              </Form.Item>
            </Fragment>
          )}

          {appType === 'rdp' && (
            <Fragment>
              <Form.Item label="Host">
                {getFieldDecorator('rdp.host', {
                  initialValue: isModalEdit ? appData.config.host : '',
                  rules: [
                    {
                      required: true,
                      message: 'Please input host',
                    },
                    {
                      validator: this.validator(validRDPHost, 'Invalid RDP IP or Host'),
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item label="Port">
                {getFieldDecorator('rdp.port', {
                  initialValue: isModalEdit ? appData.config.port : 3389,
                })(<InputNumber min={0} max={65535} />)}
              </Form.Item>
              <Form.Item label="Use SSL-VPN Credentials">
                {getFieldDecorator('rdp.useSSLVpnCredentials', {
                  initialValue: useSSLVpnCredentialsValue,
                  valuePropName: 'checked',
                })(<Switch onChange={this.hanleRdpCredientialsChange} />)}
              </Form.Item>

              {!useSSLVpnCredentialsValue && (
                <Fragment>
                  <Form.Item label="User Name">
                    {getFieldDecorator('rdp.username', {
                      initialValue: isModalEdit ? appData.config.username : '',
                    })(<Input autoComplete="off" />)}
                  </Form.Item>

                  <Form.Item label="Password">
                    {getFieldDecorator('rdp.password', {
                      initialValue: isModalEdit ? appData.config.password : '',
                    })(<Input.Password autoComplete="new-password" />)}
                  </Form.Item>
                </Fragment>
              )}

              <Form.Item label="Keyboard Layout">
                {getFieldDecorator('rdp.keyboardLayout', {
                  initialValue: isModalEdit ? appData.config.keyboardLayout : 'en-us-qwertz',
                })(
                  <Select>
                    {keyboardLayoutOptions.map(item => (
                      <Select.Option key={item.name}>{item.help}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Security">
                {getFieldDecorator('rdp.security', {
                  initialValue: isModalEdit ? appData.config.security : 'rdp',
                })(
                  <Select>
                    {securityOptions.map(item => (
                      <Select.Option key={item.name}>{item.help}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Preconnection ID">
                {getFieldDecorator('rdp.preconnectionId', {
                  initialValue: isModalEdit ? appData.config.preconnectionId : 2147483648,
                })(<Input />)}
              </Form.Item>

              <Form.Item label="Preconnection Blob">
                {getFieldDecorator('rdp.preconnectionBlob', {
                  initialValue: isModalEdit ? appData.config.preconnectionBlob : '',
                })(<Input />)}
              </Form.Item>

              <Form.Item label="Load Balancing Information">
                {getFieldDecorator('rdp.loadBalancingInfo', {
                  initialValue: isModalEdit ? appData.config.loadBalancingInfo : '',
                })(<Input />)}
              </Form.Item>
            </Fragment>
          )}
        </Form>
      </Modal>
    );
  }
}

export default Form.create({})(AddApplicationModal);
