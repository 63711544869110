export const TYPES = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
];

export const RESOURCE_STATUS = {
  PENDING: 'pending',
  RUNNING: 'running',
  FAILED: 'failed',
  TERMINATED: 'terminated',
  WARNING: 'warning',
  STOPPED: 'stopped',
  isPending: function (status) {
    return status === this.PENDING;
  },
  isRunning: function (status) {
    return status === this.RUNNING;
  },
  isFailed: function (status) {
    return status === this.FAILED;
  },
  isTerminated: function (status) {
    return status === this.TERMINATED;
  },
  isWarning: function (status) {
    return status === this.WARNING;
  },
  isStopped: function (status) {
    return status === this.STOPPED;
  },
};

export const DOMAIN = document.domain.split('.').slice(-2).join('.');
export const DEFAULT_SLAVES_QUOTA = 8;

export const SYNC_QUERY_INTERVAL = 10 * 1000;

export const ADVANCED_MODE = 'advanced';
export const EXPRESS_MODE = 'express';
