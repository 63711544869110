import './IPsecInfo.module.less';

import { Divider, Tabs, Typography } from 'antd';
import api from 'api';
import InfoItem from 'components/InfoItem/InfoItem';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import IPsecConfigGeneration from './IPsecConfigGeneration';
const { Paragraph, Text } = Typography;

const IPsecInfo = ({ data, ...styleOptions }) => {
  const { id: siteId, name: siteName, type } = data;

  const downloadConfig = useCallback(
    (payload = {}) => {
      const module = 'FGT';
      api.downloadConfig(
        siteId,
        payload,
        res => {
          const element = document.createElement('a');
          const file = new Blob([res.data], { type: 'text/plain' });
          element.href = URL.createObjectURL(file);
          element.download = `${siteName}_${module}.conf`;
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
          element.remove();
        },
        err => console.log({ err })
      );
    },
    [siteId, siteName]
  );

  return (
    <Tabs styleName="tabs" defaultActiveKey="0" tabPosition={'top'}>
      {_get(data, 'interface', []).map((location, index) => {
        return (
          <Tabs.TabPane
            tab={location.name}
            key={`${index}`}
            style={{ height: '100%', overflow: 'auto' }}
          >
            <div {...styleOptions}>
              {type === 'express' || type === 'pro' ? (
                <>
                  <Divider orientation="left"> Metadata </Divider>
                  <section styleName="info-section">
                    <InfoItem
                      readOnly
                      label={<Text>Name</Text>}
                      value={
                        <Paragraph data-test="location-name">
                          <span data-test="ipsec-gateway-url">{_get(location, 'name', '')}</span>
                        </Paragraph>
                      }
                    />
                    <InfoItem
                      readOnly
                      label={<Text>Description</Text>}
                      value={<Paragraph>{_get(location, 'description', '')}</Paragraph>}
                    />
                    <InfoItem
                      readOnly
                      label={<Text>Geo Location</Text>}
                      value={
                        <Paragraph>
                          {(() => {
                            const country = _get(location, 'address.country');
                            const state = _get(location, 'address.state');
                            const city = _get(location, 'address.city');

                            return `${country || '-'}/${state || '-'}/${city || '-'}`;
                          })()}
                        </Paragraph>
                      }
                    />
                    <InfoItem
                      readOnly
                      label={'Owner'}
                      value={<Paragraph>{_get(location, 'owner', '')}</Paragraph>}
                    />
                  </section>

                  <Divider orientation="left"> Networks </Divider>
                  <section styleName="info-section">
                    <InfoItem
                      readOnly
                      label={<Text>IPSec Gateway FQDN</Text>}
                      value={
                        <Paragraph copyable data-test="ipsec-gateway">
                          {_get(location, 'ipsec_domain_name')}
                        </Paragraph>
                      }
                    />
                    <InfoItem
                      readOnly
                      label={<Text>IPSec Gateway Address</Text>}
                      value={<Paragraph copyable>{location['ipsec_public_ip']}</Paragraph>}
                    />
                    <InfoItem
                      readOnly
                      label={<Text>LAN IP/CIDR</Text>}
                      value={
                        <Paragraph copyable>
                          {(_get(location, 'cpe_networks') || [])
                            .map(({ cpe_network }) => cpe_network)
                            .join(', ')}
                        </Paragraph>
                      }
                    />
                    <InfoItem
                      readOnly
                      label={'SASE Internal Network'}
                      value={
                        <Paragraph copyable>
                          {(_get(location, 'internal_network') || []).join(', ')}
                        </Paragraph>
                      }
                    />
                  </section>

                  <Divider orientation="left"> Phase 1 Proposal </Divider>
                  <section styleName="info-section">
                    <InfoItem
                      readOnly
                      label={<Text>Authentication Pre-shared Key</Text>}
                      value={<Paragraph copyable>{data['tunnel_psk']}</Paragraph>}
                    />
                    <InfoItem
                      readOnly
                      label={<Text>IKE Version</Text>}
                      value={<Paragraph>{data['ike_version']}</Paragraph>}
                    />
                    {type === 'pro' && (
                      <InfoItem
                        readOnly
                        label={<Text>Peer ID</Text>}
                        value={<Paragraph>{_get(location, 'peerid', '')}</Paragraph>}
                      />
                    )}
                    {(type === 'express' || type === 'pro') && (
                      <>
                        <InfoItem
                          readOnly
                          label={<Text>Algorithms</Text>}
                          value={
                            <Paragraph copyable>{_get(data, 'phase1.algorithm', '')}</Paragraph>
                          }
                        />
                        <InfoItem
                          readOnly
                          label={<Text>Diffie-Hellman Groups</Text>}
                          value={<Paragraph>{_get(data, 'phase1.dh_group', '')}</Paragraph>}
                        />
                      </>
                    )}
                  </section>
                </>
              ) : (
                <>
                  <InfoItem
                    readOnly
                    label={<Text>Gateway Domain Name</Text>}
                    value={<Paragraph copyable>{data['ipsec_domain_name']}</Paragraph>}
                  />
                  <InfoItem
                    readOnly
                    label={<Text>Pre-shared Key</Text>}
                    value={<Paragraph copyable>{data['tunnel_psk']}</Paragraph>}
                  />
                </>
              )}
              {data['ike_version'] === 2 ? (
                <></>
              ) : (
                <>
                  <InfoItem
                    readOnly
                    label={<Text>XAuth Username</Text>}
                    value={<Paragraph copyable>{data['user_name']}</Paragraph>}
                  />
                  <InfoItem
                    readOnly
                    label={<Text>XAuth Password</Text>}
                    value={<Paragraph copyable>{data['user_password']}</Paragraph>}
                  />
                </>
              )}

              {type === 'advance' && (
                <>
                  <InfoItem
                    readOnly
                    label="VxLAN VNI"
                    value={<Paragraph copyable>{data['vxlan_vni']}</Paragraph>}
                  />
                  <InfoItem
                    readOnly
                    label="LAN VTEP IP"
                    value={<Paragraph copyable>{data['lan_vtep_ip']}</Paragraph>}
                  />
                  <InfoItem
                    readOnly
                    label="Cloud VTEP IP"
                    value={<Paragraph copyable>{data['cloud_vtep_ip']}</Paragraph>}
                  />
                </>
              )}
              <InfoItem
                readOnly
                label={type === 'express' || type === 'pro' ? '' : 'IPSec VPN Configuration'}
                value={
                  <IPsecConfigGeneration
                    type={type}
                    downloadConfig={downloadConfig}
                    style={type === 'express' || type === 'pro' ? { marginLeft: 24 } : {}}
                  />
                }
              />
            </div>
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
};

IPsecInfo.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IPsecInfo;
