import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutAction } from 'redux/GlobalSetting/globalSetting.actions';
import { ROOT_PATH } from 'remote-user-portal/shared/constants';
import { fetchWrap, postForm } from 'utils/fetch';
import { isTokenExpired, resetLocalStorage } from 'utils/util';

const Logout = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.globalSetting.user);
  const portals = useSelector(state => state.globalSetting.portals);
  const [pending, setPending] = useState(false);

  const logoutFortiCare = useCallback(() => {
    const apps = portals?.apps?.user_menu_items || [];
    const logoutApp = apps.find(app => app.display_name === 'Logout');
    if (logoutApp?.url) {
      postForm(`${logoutApp.url}?ReturnApp=${portals.url || window.location.origin}/saml/logout`);
    } else {
      history.push('/login');
      setPending(false);
    }
  }, [history, portals]);

  // revoke access token
  useEffect(() => {
    const accessToken = sessionStorage.getItem('access_token');
    accessToken &&
      !isTokenExpired(accessToken) &&
      fetchWrap({
        method: 'post',
        url: `/v1/system/token/removal`,
      }).catch(err => {
        console.log({ err });
      });
  }, []);

  useEffect(() => {
    if (!pending) {
      setPending(true);

      // send logout request to web server to remove auth cookie
      fetchWrap({
        method: 'post',
        url: `/v1/system/logout`,
      })
        .then(() => {
          resetLocalStorage();
          dispatch(logoutAction());

          // if is not standalone user, logout forticare
          const isStandalone = user.auth_type === 'standalone';
          if (isStandalone) {
            // redirect to login page
            if (window.location.pathname.includes(ROOT_PATH)) {
              history.push(`${ROOT_PATH}/login`);
            } else {
              history.push('/login');
            }
            setPending(false);
          } else {
            logoutFortiCare();
          }
        })
        .catch(err => {
          console.log({ err });
        });
    }
  }, [dispatch, history, logoutFortiCare, pending, user.auth_type]);

  return <div></div>;
};

Logout.propTypes = {};

export default Logout;
