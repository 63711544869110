import api from 'api';
import _get from 'lodash/get';

export const UPDATE_LOG_STATUS = 'UPDATE_LOG_STATUS';

export const updateLogStatus = logStatusData => ({
  type: UPDATE_LOG_STATUS,
  payload: { ...logStatusData },
});

export const getLogInfo = () => dispatch => {
  api.getLogInfo(res => {
    const data = _get(res, 'data.data', {});

    const logStatusData = mappingData(data);
    dispatch(updateLogStatus(logStatusData));
  });
};

export const createLog = data => dispatch => {
  return api.createLog(data, res =>
    dispatch(updateLogStatus({ resourceState: res.data.data.state_label }))
  );
};

export const recreateLog = () => dispatch =>
  api.recreateLog().then(res => {
    const data = _get(res, 'data.data', {});

    const logStatusData = mappingData(data);
    dispatch(updateLogStatus(logStatusData));
  });

export const disableLog = () => dispatch => {
  return api.disableLog(res =>
    dispatch(updateLogStatus({ resourceState: res.data.data.state_label }))
  );
};

export function mappingData(data) {
  // action is used to determinate if the "Recreate" button need to be shown
  // when a process is failed
  // please refer to the FailtureDetail component for more detail
  const action = data.log_enable ? 'start' : 'delete';

  const logStatusData = {
    logsStatus: data.log_enable,
    resourceStatus: data.resource_status,
    resourceState: data.state_label,
    resourceFailedMessage: data.resource_failed_message,
    resourceFailedType: data.resource_failed_type,
    type: data.resource_model,
    regionName: data.region_name,
    regionAlias: data.region_alias,
    timestamp: data._updated || new Date().getTime(),
    action: action,
    isRecreatable: data.is_recreatable,
    isEditable: data.is_editable,
  };
  return logStatusData;
}
