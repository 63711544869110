import './Page.module.less';

import React from 'react';

const Header = props => {
  const { children, className, styleName, ...restProps } = props;
  return (
    <section
      styleName={`page-footer  ${styleName || ''}`}
      className={`flex-row main-end cross-center ${className || ''}`}
      {...restProps}
    >
      {children}
    </section>
  );
};

export default Header;
