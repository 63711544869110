import 'moment-timezone';
import 'moment-duration-format';

import { cloudAppDarkTheme, cloudAppLightTheme, ThemingManager } from '@neutrino/theming';
import { Typography } from 'antd';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import LZString from 'lz-string';
import moment from 'moment';
import React from 'react';
import { closeSocket } from 'redux/Socket/socket.actions';
import { ROOT_PATH } from 'remote-user-portal/shared/constants';
import { from } from 'rxjs';

import history from '@/history';
import store from '@/store';

import { postForm } from './fetch';
import storage from './storage';

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const { Paragraph } = Typography;
const jwtDecode = require('jwt-decode');

const themingManager = new ThemingManager(document, window);
const FORTICARE_URL = 'https://support.fortinet.com';

export const validateMessages = {
  required: '${label} is required!',
  types: {
    email: 'Not a validated email!',
    number: 'Not a validated number!',
  },
  string: {
    len: '${label} must be exactly ${len} characters',
    min: '${label} must be at least ${min} characters',
    max: '${label} cannot be longer than ${max} characters',
    range: '${label} must be between ${min} and ${max} characters',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

export function toStream(fn, ...params) {
  return from(_isEmpty(params) ? fn() : fn(...params));
}

export function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function deleteCookie(cname) {
  document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
 * @description - The breakpoints follow the Ant Design rules {@link https://ant.design/components/grid/}
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-18
 * @export
 * @returns
 */
export function getBreakpoint() {
  const documentElement = document.documentElement;
  const body = document.getElementsByTagName('body')[0];
  const width = window.innerWidth || documentElement.clientWidth || body.clientWidth;

  let breakpoint;
  switch (true) {
    case width < 576:
      breakpoint = 'xs';
      break;
    case width >= 576 && width < 768:
      breakpoint = 'sm';
      break;
    case width >= 768 && width < 992:
      breakpoint = 'md';
      break;
    case width >= 992 && width < 1200:
      breakpoint = 'lg';
      break;
    case width >= 1200 && width < 1600:
      breakpoint = 'xl';
      break;
    case width >= 1600:
      breakpoint = 'xxl';
      break;
    default:
      breakpoint = 'lg';
  }
  return breakpoint;
}

/**
 * Detect is mobile
 */
export function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1
  );
}

export function saveToken({ access_token, refresh_token }) {
  sessionStorage.setItem('access_token', access_token);
  sessionStorage.setItem('refresh_token', refresh_token);

  try {
    const decodeObj = jwtDecode(access_token);
    const license = _get(decodeObj, 'user.tenant.company.license', null);
    if (license) {
      storage.set('license', license);
    }
  } catch (error) {
    console.error('parse token error');
  }
}

export function getTenantUser(access_token = sessionStorage.getItem('access_token')) {
  let user = {};
  try {
    const decodeObj = jwtDecode(access_token);
    user = { ...decodeObj.user, contact: decodeObj.contact };
  } catch (error) {
    console.error('parse token error');
  }
  return user;
}

export function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function compressSnapshot(snapshot) {
  return LZString.compressToUTF16(snapshot);
}

export function decompressSnapshot(snapshot) {
  const reg = new RegExp('^data:image/');
  if (reg.exec(snapshot)) {
    return snapshot;
  } else {
    return LZString.decompressFromUTF16(snapshot);
  }
}

export const resetLocalStorage = () => {
  // clear sessionStorage except auditTableColumns and auditTableCheckedKesy, ConfigTableOptions, license
  const { theme, isHeaderHide } = storage.get('globalSetting');
  ['access_token', 'refresh_token', 'globalSetting'].forEach(key => storage.rm(key));
  storage.set('globalSetting', { theme, isHeaderHide });
};

export function logout() {
  window.location.href = '/logout';
}

export function redirectForticare() {
  window.location.href = FORTICARE_URL;
}

export function parseDashString(str, capitalized = false) {
  if (str === '') return;
  return str
    .split(/-|_/)
    .map(item => (capitalized ? _capitalize(item) : item))
    .join(' ');
}

export function copyStringToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function toLocalTime(dateStr, options) {
  return new Date(dateStr).toLocaleString('en-US', options);
}

export function renderMaintTime(data) {
  const time = toLocalTime(data.start_time, { timeZoneName: 'short' });
  const startTime = time === 'Invalid Date' ? '' : time;
  return (
    startTime &&
    data.etc && (
      <div>
        <Paragraph type="secondary" style={{ marginBottom: '0.5em' }}>
          {startTime && <small>Start Time: {startTime}</small>}&nbsp;&nbsp;
          {data.etc && <small>EST: {data.etc}</small>}
        </Paragraph>
      </div>
    )
  );
}

export function defaultSorter(prop) {
  return (a, b) => (_get(a, prop) === _get(b, prop) ? 0 : _get(a, prop) > _get(b, prop) ? 1 : -1);
}

export function changeBodyTheme(theme) {
  document.body.classList.remove(...['dark', 'light']);
  document.body.classList.add(theme);
}

export function setActiveTheme(theme) {
  if (theme === 'dark') {
    themingManager.setActiveTheme(cloudAppDarkTheme);
  } else {
    themingManager.setActiveTheme(cloudAppLightTheme);
  }
  document.body.classList.remove(...['dark', 'light']);
  document.body.classList.add(theme);
}

export function isPromise(promise) {
  return !!promise && typeof promise.then === 'function';
}

export const getTimeZoneInfo = () => {
  const timestamp = moment.now();
  const localtz = moment.tz.guess();
  const timeZoneAbbr = moment.tz.zone(localtz).abbr(timestamp);
  const offset = moment().utcOffset();

  return {
    timeZoneAbbr,
    utcOffset: `UTC${moment.duration(offset, 'minutes').format('HH:mm')}`,
  };
};

export const localToUtc = localDate => {
  var utcMoment = moment.utc(moment(localDate, DATE_FORMAT).utc()).format();

  return utcMoment;
};

export const utcToLocal = (utcDate, format = DATE_FORMAT) => {
  const { timeZoneAbbr } = getTimeZoneInfo();
  const localtime = moment.utc(utcDate).toDate();
  return `${moment(localtime).format(format)}, ${timeZoneAbbr}`;
};

// This key is used to store and retrieve what URL fgc-web-ng should navigate to for SASE-UI.
export const SASE_UI_REDIRECT_ROUTE_KEY = 'saseUIRedirectRoute';

export const isTokenExpired = token => {
  if (!token) {
    throw new Error('Not a valid token');
  }

  const decodeObj = jwtDecode(token);
  const expiry = decodeObj?.exp || 0;
  return Date.now() > expiry * 1000;
};
