import { fetchWrap } from 'utils/fetch';

const resource = '/v1/setting/permission/users';

/**
 * @description - create a standalone user
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {Object.<User>} userData
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function createUser(userData, ...cb) {
  return fetchWrap(
    {
      url: resource,
      method: 'post',
      data: userData,
    },
    ...cb
  );
}

/**
 * @description delete a user
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {*} id
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function deleteUser(id, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${id}`,
      method: 'delete',
    },
    ...cb
  );
}

/**
 * @description update a user
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {*} userData
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function updateUser(id, userData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${id}`,
      method: 'patch',
      data: userData,
    },
    ...cb
  );
}

/**
 * @description - get user list
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function getUserList(params = {}, ...cb) {
  const defaultParams = { page: 1, max_results: 25, sort: '-_created' };
  let queryString = '';

  try {
    queryString = JSON.stringify({
      ...defaultParams,
      ...params,
    });
  } catch (e) {
    console.error('invalid json');
  }

  return fetchWrap(
    {
      url: resource,
      params: {
        q: queryString,
      },
    },
    ...cb
  );
}

/**
 * @description get a user
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {*} id
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function getUser(id, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/${id}`,
    },
    ...cb
  );
}

/**
 * @description asssign role to user
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-04-16
 * @param {*} id
 * @param {*} new_role_list
 * @param {*} old_role_list
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function assignRole(id, new_role_list, old_role_list, ...cb) {
  return fetchWrap({
    url: `${resource}/${id}/roles`,
    method: 'post',
    data: {
      role_list: new_role_list,
      old_role_list: old_role_list,
    },
    ...cb,
  });
}

function getUserResourceStatus(...cb) {
  return fetchWrap(
    {
      url: `${resource}/resource/status`,
    },
    ...cb
  );
}

const getSitesByUser = id => fetchWrap({ url: `${resource}/${id}/sites` });

export default {
  createUser,
  deleteUser,
  updateUser,
  getUserList,
  getUser,
  assignRole,
  getUserResourceStatus,
  getSitesByUser,
};
