import './Users.module.less';

import { Button, Divider, Form, Input, Select, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { validateMessages } from 'utils/util';

import { EDGE_ADMIN, SITE_ADMIN, SUPER_ADMIN, VIEWER } from './user.service';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

const CreateUserForm = ({ onSubmit, onCancel, data, roles, sites }) => {
  const formRef = useRef();
  const { root } = data;
  const [role, setRole] = useState();
  const [pending, setPending] = useState(false);

  const siteOptions = useMemo(
    () =>
      sites.map(item => (
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      )),
    [sites]
  );

  const onRoleChange = useCallback(value => {
    const { setFieldsValue } = formRef.current;
    setRole(value);
    switch (value) {
      case SUPER_ADMIN:
        setFieldsValue({ sites: '*' });
        break;
      case EDGE_ADMIN:
        setFieldsValue({ sites: [] });
        break;
      case SITE_ADMIN:
        setFieldsValue({ sites: null });
        break;
      case VIEWER:
      default:
        setFieldsValue({ sites: '' });
    }
  }, []);

  const selectAllSites = useCallback(() => {
    const { setFieldsValue } = formRef.current;
    setFieldsValue({ sites: sites.map(({ id }) => id) });
  }, [sites]);

  const siteField = useMemo(() => {
    let fieldName = 'sites';
    switch (role) {
      case SUPER_ADMIN:
        return (
          <Form.Item
            name={fieldName}
            label="Site"
            key={fieldName}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select disabled>
              <Select.Option value={'*'}>All</Select.Option>
            </Select>
          </Form.Item>
        );
      case EDGE_ADMIN:
        return (
          <Form.Item
            name={fieldName}
            label="Site(s)"
            key={fieldName}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              disabled={pending}
              mode="tags"
              allowClear
              placeholder="Please select sites"
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div className="ant-select-item" styleName="select-all-item">
                    <div
                      style={{
                        flex: 'auto',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      onClick={selectAllSites}
                    >
                      Select All
                    </div>
                  </div>
                </div>
              )}
            >
              {siteOptions}
            </Select>
          </Form.Item>
        );
      case SITE_ADMIN:
        return (
          <Form.Item
            name={fieldName}
            label="Site"
            key={fieldName}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select disabled={pending} placeholder="Please select a site">
              {siteOptions}
            </Select>
          </Form.Item>
        );
      case VIEWER:
      default:
        return (
          <Form.Item name={fieldName} label="Site" key={fieldName}>
            <Select disabled>
              <Select.Option value={''}>None</Select.Option>
            </Select>
          </Form.Item>
        );
    }
  }, [pending, role, selectAllSites, siteOptions]);

  const handleSubmit = useCallback(
    values => {
      setPending(true);
      onSubmit(values).finally(() => setPending(false));
    },
    [onSubmit]
  );

  return (
    <Form
      ref={formRef}
      {...formItemLayout}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
    >
      <Form.Item name="root" label="Primary Account" key="rootPrincipal" initialValue={root}>
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="principal"
        label="Principal"
        key="principal"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input disabled={pending} />
      </Form.Item>

      <Form.Item
        name="name"
        label="Name"
        key="userName"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input disabled={pending} />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        key="email"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
          },
          {
            type: 'email',
          },
        ]}
      >
        <Input disabled={pending} />
      </Form.Item>

      <Form.Item
        name="role"
        label="Role"
        key="role"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select disabled={pending} placeholder="Select a role" onChange={onRoleChange}>
          {' '}
          {roles.map(role => (
            <Select.Option key={role.name}>{role.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      {siteField}

      <Form.Item
        name="password"
        label="Password"
        key="password"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: 'Please input password!',
          },
          { min: 6 },
        ]}
      >
        <Input.Password disabled={pending} />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        key="confirm"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: 'Please confirm the password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password disabled={pending} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button loading={pending} type="primary" htmlType="submit">
            Add
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

CreateUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.object,
  roles: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
};

export default CreateUserForm;
