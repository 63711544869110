import { ToolTwoTone } from '@ant-design/icons';
import { notification as notify, Typography } from 'antd';
import api from 'api';
import _get from 'lodash/get';
import React from 'react';
import storage from 'utils/storage';
import { renderMaintTime, setActiveTheme } from 'utils/util';
const { Text } = Typography;

export const CHANGE_THEME = 'CHANGE_THEME';
export const TOGGLE_HEADER = 'TOGGLE_HEADER';
export const SET_AUTHENTICATE = 'SET_AUTHENTICATE';
export const UPDATE_BREAKPOINT = 'UPDATE_BREAKPOINT';
export const FETCH_PORTALS = 'FETCH_PORTALS';
export const UPDATE_PORTALS = 'UPDATE_PORTALS';
export const UPDATE_TENANT_USER = 'UPDATE_TENANT_USER';
export const TOGGLE_SIDER_COLLAPSE = 'TOGGLE_SIDER_COLLAPSE';
export const UPDATE_VERSION = 'UPDATE_VERSION';
export const UPDATE_SITE_LIST_OPTIONS = 'UPDATE_SITE_LIST_OPTIONS';
export const UPDATE_FORTIONE_ACCOUNTS = 'UPDATE_FORTIONE_ACCOUNTS';
export const UPDATE_AUDIT_LOG_OPTIONS = 'UPDATE_AUDIT_LOG_OPTIONS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const ADD_NOTITICATION = 'ADD_NOTITICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const ADD_SYSTEM_INFO = 'ADD_SYSTEM_INFO';
export const REMOVE_SYSTEM_INFO = 'REMOTE_SYSTEM_INFO';
export const UPDATE_SYSTEM_INFO = 'UPDATE_SYSTEM_INFO';
export const UPDATE_REGIONS = 'UPDATE_REGIONS';
export const SET_TEMP_UI_MODE = 'SET_TEMP_UI_MODE';

export const logoutAction = () => ({ type: USER_LOGOUT });

export const changeTheme = theme => disptch => {
  setActiveTheme(theme);
  disptch({
    type: CHANGE_THEME,
    payload: { theme },
  });
};

export const toggleHeader = isHeaderHide => ({
  type: TOGGLE_HEADER,
  payload: { isHeaderHide },
});

export const setAuthenticate = isAuthenticated => ({
  type: SET_AUTHENTICATE,
  payload: { isAuthenticated },
});

export const setBreakpoint = breakpoint => ({
  type: UPDATE_BREAKPOINT,
  payload: { breakpoint },
});

export const updateTenantUser = user => ({
  type: UPDATE_TENANT_USER,
  payload: { user },
});

export const updatePortals = portals => ({
  type: UPDATE_PORTALS,
  payload: { portals },
});

export const fetchPortals = authToken => dispatch => {
  return api.getFortiOnePortals(
    authToken,
    res => {
      const { data: resData } = res;
      dispatch(updatePortals(resData.data));
    },
    error => {
      console.log('error', error);
    }
  );
};

export const updateVersion = version => ({
  type: UPDATE_VERSION,
  payload: { version },
});

export const getVersion = () => dispatch => {
  api.getSystemVersion(({ data: resData }) => {
    const version = _get(resData, 'data.version', '');
    dispatch(updateVersion(version));
  });
};

export const getMaintInfo = () => (dispatch, getState) => {
  const activeInfo = getState().globalSetting.sysInfo;

  api.getMaintInfo().then(({ data: resData }) => {
    // clear all messages
    notify.destroy();

    const data = _get(resData, 'data', []);

    activeInfo.forEach(item => {
      const stillActive = data.find(({ id }) => id === item.id);
      if (!stillActive) {
        dispatch(removeSystemInfo(item));
      }
    });
    data.forEach(item => {
      const isNew = !activeInfo.find(({ id }) => id === item.id);
      if (isNew) {
        dispatch(addSystemInfo(item));
      }
    });
  });
};

export const addSystemInfo = info => (dispatch, getState) => {
  const { breakpoint } = getState().globalSetting;

  const cached = _get(storage.get('globalSetting'), 'sysInfo', []);

  const isClosed = _get(
    cached.find(({ id }) => id === info.id),
    'isClosed',
    false
  );
  !isClosed &&
    notify.warning({
      key: info.id,
      message: info.title,
      description: (
        <div>
          {renderMaintTime(info)}
          <p>{info.description}</p>
        </div>
      ),
      duration: 5,
      icon: <ToolTwoTone twoToneColor="#faad14"></ToolTwoTone>,
      style: {
        width: 420,
        marginLeft: breakpoint === 'xs' ? 0 : 384 - 420,
        maxWidth: '96vw',
      },
      onClose: () => dispatch(updateSysInfo({ isClosed: true, ...info })),
    });
  !isClosed &&
    dispatch({
      type: ADD_SYSTEM_INFO,
      payload: info,
    });
};

export const removeSystemInfo = info => (dispatch, getState) => {
  const { breakpoint } = getState().globalSetting;

  // close the maintenance notification if it existed before
  notify.success({
    key: info.id,
    message: info.title,
    description: <Text delete>{info.description}</Text>,
    duration: 5,
    style: {
      width: 420,
      marginLeft: breakpoint === 'xs' ? 0 : 384 - 420,
      maxWidth: '96vw',
    },
  });

  dispatch({
    type: REMOVE_SYSTEM_INFO,
    payload: info,
  });
};

export const updateSysInfo = info => ({ type: UPDATE_SYSTEM_INFO, payload: info });

export const toggleSiderCollapse = siderCollapsed => ({
  type: TOGGLE_SIDER_COLLAPSE,
  payload: { siderCollapsed },
});

export const updateSiteListOptions = siteListOptions => ({
  type: UPDATE_SITE_LIST_OPTIONS,
  payload: { siteListOptions },
});

export const updateFortiOneAccounts = fortiOneAccounts => ({
  type: UPDATE_FORTIONE_ACCOUNTS,
  payload: { fortiOneAccounts },
});

export const updateAuditLogOptions = auditLogOptions => ({
  type: UPDATE_AUDIT_LOG_OPTIONS,
  payload: { auditLogOptions },
});

export const addNotification = notification => ({
  type: ADD_NOTITICATION,
  payload: { notification },
});

export const clearNotifications = () => ({ type: CLEAR_NOTIFICATIONS });

export const readAllNotification = () => ({ type: READ_ALL_NOTIFICATIONS });

export const readNotification = notifiIndex => ({
  type: READ_NOTIFICATION,
  payload: { notifiIndex },
});

export const updateRegions = regions => ({
  type: UPDATE_REGIONS,
  payload: { regions },
});

export const getRegions = () => dispatch => {
  return api.getRegions().then(res => {
    const regions = _get(res, 'data.data', []).sort(({ alias: alias1 }, { alias: alias2 }) => {
      if (alias1 < alias2) {
        return -1;
      } else if (alias1 > alias2) {
        return 1;
      } else {
        return 0;
      }
    });
    dispatch(updateRegions(regions));
    return regions;
  });
};

export const setTempUIMode = tempMode => ({ type: SET_TEMP_UI_MODE, payload: { tempMode } });
