import './ConfigTable.module.less';

import { Button, Checkbox, Modal, Table } from 'antd';
import arrayMove from 'array-move';
import SvgIcon from 'components/SvgIcon';
import Lockr from 'lockr';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const modalProps = {
  centered: true,
  autoFocusButton: null,
  maskClosable: false,
  title: 'Show/Hide Columns',
  okText: 'Confirm',
};

class ColumnsControl extends Component {
  state = {
    columns: [...this.props.columns],
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ columns }) => ({
      columns: arrayMove(columns, oldIndex, newIndex),
    }));
    this.props.onChange(this.state.columns);
  };

  updateChecked(checked, index) {
    const newColumns = this.state.columns.map((column, idx) =>
      idx !== index ? column : { ...column, hide: !checked }
    );
    this.setState({ columns: newColumns });
    this.props.onChange(newColumns);
  }

  reset(columns) {
    this.setState({ columns });
    this.props.onChange(columns);
  }

  render() {
    const { columns } = this.state;
    const DragHandle = SortableHandle(() => (
      <SvgIcon type="grip-lines" style={{ color: '#cacbcc', paddingRight: '14px' }} />
    ));

    const SortableItem = SortableElement(({ value, sortIndex }) => (
      <li className="flex-row space-between cross-center draggable-item">
        <Checkbox
          onChange={e => this.updateChecked(e.target.checked, sortIndex)}
          defaultChecked={!value.hide}
          disabled={value.mustBeShown}
        >
          {value.title}
        </Checkbox>
        <DragHandle />
      </li>
    ));

    const SortableList = SortableContainer(({ columns }) => (
      <ul styleName="draggable-list">
        {columns.map((column, index) => (
          <SortableItem
            key={`item-${column.key || column.dataIndex}`}
            index={index}
            sortIndex={index}
            value={column}
          />
        ))}
      </ul>
    ));

    return <SortableList columns={columns} onSortEnd={this.onSortEnd} />;
  }
}

export default class ConfigTable extends Component {
  state = {
    columns: this.getInitColumns(),
  };

  getInitColumns() {
    const { localStorageId, columns } = this.props;
    const configTableOptions = Lockr.get('configTableOptions') || {};
    const savedColumns = configTableOptions[localStorageId];

    if (!localStorageId || !savedColumns) return columns;

    // columns is updated, must reset saved columns
    if (!this.isColumnsEqual(savedColumns, columns)) {
      this.saveOptions(columns);
      return columns;
    } else {
      return savedColumns.map(item => ({
        ...columns.find(colunm => (colunm.dataIndex || colunm.key) === item.key),
        ...item,
      }));
    }
  }

  isColumnsEqual(colsA, colsB) {
    if (colsA.length !== colsB.length) return false;
    const getKey = item => (item.dataIndex ? item.dataIndex : item.key);
    return colsA.map(getKey).sort().toString() === colsB.map(getKey).sort().toString();
  }

  saveOptions(columns) {
    const configTableOptions = Lockr.get('configTableOptions') || {};
    const { localStorageId } = this.props;
    Lockr.set('configTableOptions', {
      ...configTableOptions,
      [localStorageId]: columns.map(item => ({
        key: item.dataIndex || item.key,
        hide: item.hide,
        mustBeShown: item.mustBeShown,
      })),
    });
  }

  onModalConfirm = () => {
    const columns = this.updatedColumns || this.props.columns;
    this.setState({ columns: [...columns] });
    this.props.localStorageId && this.saveOptions(columns);
    this.props.closeConfigTbModal && this.props.closeConfigTbModal();
  };

  onModalCancel = () => {
    this.props.closeConfigTbModal && this.props.closeConfigTbModal();
  };

  resetColumn = () => {
    this.columnCtrl.reset(this.props.columns);
  };

  renderFooter() {
    const { allowReset } = this.props;
    return (
      <div className={`flex-row ${allowReset ? 'space-between' : 'main-end'}`}>
        {allowReset && <Button onClick={this.resetColumn}>Restore defaults</Button>}
        <div>
          <Button key="cancel" onClick={this.onModalCancel}>
            Cancel
          </Button>
          <Button key="confirm" type="primary" onClick={this.onModalConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { configTbModalVisible, minColumnWidth, allowReset, style = {}, ...options } = this.props;
    const { columns } = this.state;
    const renderColumns = columns.filter(column => column.mustBeShown || !column.hide);
    const footer = this.renderFooter();
    return (
      <Fragment>
        <Table
          {...options}
          style={{ minWidth: minColumnWidth && minColumnWidth * renderColumns.length, ...style }}
          columns={renderColumns}
          size="middle"
        />
        <Modal
          {...modalProps}
          visible={configTbModalVisible}
          onOk={this.onModalConfirm}
          onCancel={this.onModalCancel}
          footer={footer}
          destroyOnClose
        >
          <ColumnsControl
            ref={component => (this.columnCtrl = component)}
            columns={columns}
            onChange={value => (this.updatedColumns = value)}
          />
        </Modal>
      </Fragment>
    );
  }
}

ColumnsControl.propTypes = {
  columns: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

ConfigTable.propTypes = {
  allowReset: PropTypes.bool,
  style: PropTypes.object,
  minColumnWidth: PropTypes.number,
  columns: PropTypes.array.isRequired,
  localStorageId: PropTypes.string,
  closeConfigTbModal: PropTypes.func,
  configTbModalVisible: PropTypes.bool,
};
