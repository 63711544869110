import { fetchWrap } from 'utils/fetch';

const resource = '/v1/system';

/**
 * @description
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {{principal: string, password: sha256}} credential
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function standaloneLogin(credential, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/login/standalone`,
      method: 'post',
      data: credential,
    },
    ...cb
  );
}

/**
 * @description
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {Object.<User>} userData
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function ssoLogin(xAuthToken, userData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/login/sso`,
      method: 'post',
      headers: {
        'auth-token': xAuthToken,
      },
      data: {
        ...userData,
      },
    },
    ...cb
  );
}

/**
 * @description
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {Object.<User>} userData
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function etacLogin(xAuthToken, userData, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/login/etac`,
      method: 'post',
      headers: {
        'auth-token': xAuthToken,
      },
      data: {
        ...userData,
      },
    },
    ...cb
  );
}

export default { standaloneLogin, ssoLogin, etacLogin };
