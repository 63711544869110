import './SiteApplications.module.less';

import {
  ApartmentOutlined,
  AppstoreOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Divider, Input, Popconfirm, Table, Tooltip } from 'antd';
import api from 'api';
import Page from 'components/Page';
import _get from 'lodash/get';
import { VIEWER } from 'pages/Settings/Users/user.service';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSiteInfo, getSiteList } from 'redux/Security/Security.actions';

import AddApplicationModal from './AddApplication.modal';
import AssignApplicationModal from './AssignApplicationsModal';
import { getDefaultIcon, typeToLabelMap } from './siteAppUtils';
import UserAppsManagementModal from './UserAppsManagement.modal';

const mapStateToProps = state => ({
  tenantUser: state.globalSetting.user,
});

const mapDispatchToProps = dispatch => ({
  getSiteInfo: siteId => dispatch(getSiteInfo(siteId)),
  getSiteList: () => dispatch(getSiteList()),
});

class SiteApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      assignAppModalVisible: false,
      addAppModalVisible: false,
      userAppsMgmtModalVisible: false,
      selectedRows: [],
      site: {},
      nameFilter: '',
      appsData: [],
    };
    this.columns = [
      {
        title: 'App Name',
        dataIndex: 'name',
        key: 'name',
        width: 300,
        render: (name, record) => (
          <div>
            <img
              src={record.icon || getDefaultIcon(record.type)}
              alt="icon"
              style={{ width: 32, height: 24, marginRight: 12 }}
            />
            <span>{name}</span>
          </div>
        ),
      },
      {
        title: 'App Type',
        dataIndex: 'type',
        key: 'type',
        width: 180,
        render: type => typeToLabelMap[type],
        filters: ['web', 'ssh', 'rdp'].map(type => ({ text: typeToLabelMap[type], value: type })),
        onFilter: (value, record) => record.type === value,
      },
      {
        title: 'Action',
        key: 'action',
        render: record => (
          <Fragment>
            <span className="link" onClick={() => this.handleAppAction('edit', record)}>
              Edit
            </span>
            <Divider type="vertical" />
            <Popconfirm
              title={`Are you sure to delete app ${record.name}`}
              onConfirm={() => this.handleAppAction('delete', record)}
            >
              <span className="link">Delete</span>
            </Popconfirm>
          </Fragment>
        ),
      },
    ];
  }

  componentDidMount() {
    const isAdmin = !_get(this.props.tenantUser, 'role', []).includes(VIEWER);
    if (isAdmin) {
      this.fetchSite();
      this.getSiteApplications();
    } else {
      this.props.history.push('/security');
    }
  }

  getSiteId() {
    const { match } = this.props;
    const { id } = match.params;
    return id;
  }

  getSiteApplications = () => {
    const id = this.getSiteId();
    this.setState({ loading: true });
    api
      .getSiteApplications(id)
      .then(res => {
        this.setState({ appsData: res.data.data });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  fetchSite() {
    const id = this.getSiteId();
    this.props.getSiteList();
    this.props.getSiteInfo(id).then(res => {
      const site = _get(res, 'data.data', {});
      this.setState({ site });
    });
  }

  handleAppAction(action, record) {
    this.setState({ selectedRecord: record });
    switch (action) {
      case 'edit':
        this.openAddAppModal('edit', 'Edit');
        break;
      case 'delete':
        api.deleteSiteApplication(record.id).then(() => this.getSiteApplications());
        break;
      default:
        break;
    }
  }

  handleSearch(name) {
    this.setState({ nameFilter: name.trim().toLowerCase() });
  }

  openAddAppModal(addAppModalType, addModelTitle) {
    this.setState({ addAppModalType, addModelTitle });
    this.setModalVisible('addAppModalVisible', true);
  }

  setModalVisible(modalStateKey, visible) {
    this.setState({ [modalStateKey]: visible });
  }

  renderTitle() {
    return (
      <div className="flex flex-row">
        <Button
          icon={<PlusOutlined />}
          size="small"
          onClick={() => {
            this.openAddAppModal('add', 'Add');
          }}
        >
          Add
        </Button>
        <Button
          icon={<AppstoreOutlined />}
          size="small"
          onClick={() => this.setModalVisible('assignAppModalVisible', true)}
          style={{ margin: '0 8px' }}
          disabled={!this.state.selectedRows.length}
        >
          Assign
        </Button>
        <Button
          icon={<ApartmentOutlined />}
          size="small"
          onClick={() => this.setModalVisible('userAppsMgmtModalVisible', true)}
          style={{ marginRight: 8 }}
        >
          User Apps Mgmt
        </Button>
        <Input
          prefix={<SearchOutlined />}
          size="small"
          placeholder="Search by name"
          onChange={e => this.handleSearch(e.target.value)}
        />
      </div>
    );
  }

  renderExtra() {
    return (
      <Tooltip title="Refresh" placement="top">
        <ReloadOutlined className="util-btn" onClick={() => this.getSiteApplications()} />
      </Tooltip>
    );
  }

  render() {
    const {
      addAppModalVisible,
      assignAppModalVisible,
      userAppsMgmtModalVisible,
      selectedRows,
      addModelTitle,
      addAppModalType,
      selectedRecord,
      site,
      appsData,
      loading,
      nameFilter,
    } = this.state;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({ selectedRows });
      },
    };
    const siteId = this.getSiteId();
    const dataSource = appsData.filter(item => item.name.toLowerCase().includes(nameFilter));
    return (
      <Page>
        <Page.Header title={this.renderTitle()} extra={this.renderExtra()} />
        <Table
          loading={loading}
          rowKey="id"
          columns={this.columns}
          rowSelection={rowSelection}
          dataSource={dataSource}
        />

        {addAppModalVisible && (
          <AddApplicationModal
            modelTitle={addModelTitle}
            modalType={addAppModalType}
            scope="site"
            ownerParams={{
              owner_site: site.id,
              owner_site_name: site.name,
            }}
            appData={selectedRecord}
            afterAddCb={this.getSiteApplications}
            afterClose={() => this.setModalVisible('addAppModalVisible', false)}
          />
        )}

        {assignAppModalVisible && (
          <AssignApplicationModal
            afterClose={() => this.setModalVisible('assignAppModalVisible', false)}
            siteId={siteId}
            apps={selectedRows}
          />
        )}

        {userAppsMgmtModalVisible && (
          <UserAppsManagementModal
            appsData={appsData}
            afterClose={() => this.setModalVisible('userAppsMgmtModalVisible', false)}
            siteId={siteId}
          />
        )}
      </Page>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteApplications));
