export const LAN_IP_REGEX = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\/((((254|252|248|240|224|192|128|0)\.0\.0\.0)|(255\.(254|252|248|240|224|192|128|0)\.0\.0)|(255\.255\.(254|252|248|240|224|192|128|0)\.0)|(255\.255\.255\.(255|254|252|248|240|224|192|128|0))|(3[0-2]|2[0-9]|1[0-9]|[0-9])))$/;

export const SUBNET_REGEX = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-4]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[1-9])\/((((254|252|248|240|224|192|128|0)\.0\.0\.0)|(255\.(254|252|248|240|224|192|128|0)\.0\.0)|(255\.255\.(254|252|248|240|224|192|128|0)\.0)|(255\.255\.255\.(255|254|252|248|240|224|192|128|0))|(3[0-2]|2[0-9]|1[0-9]|[0-9])))$/;

// THIN_EDGE_TYPE
export const THIN_EDGE_EXPRESS = 'express';
export const THIN_EDGE_PRO = 'pro';
export const THIN_EDGE_ADVANCE = 'advance';

export const DNS_SERVICE_OPTIONS = {
  DEFAULT: {
    label: 'Same as System DNS',
    value: 'default',
  },
  LOCAL: { label: 'Same as Interface IP', value: 'local' },
  SPECIFY: { label: 'Specify', value: 'specify' },
};
