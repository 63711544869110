import './AssignApplicationsModal.module.less';

import { SearchOutlined } from '@ant-design/icons';
import { Input, List, Radio } from 'antd';
import React, { Component } from 'react';

import { getDefaultIcon } from '../siteAppUtils';

class AppsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appNameFilter: '',
      radioKey: props.initialSelectedAppId,
    };
  }

  handleSearch(name) {
    this.setState({ appNameFilter: name.trim().toLowerCase() });
  }

  handleRadioChange = radioKey => {
    this.setState({ radioKey });
    this.props.onAppChange(radioKey);
  };

  render() {
    const { appNameFilter, radioKey } = this.state;
    const apps = this.props.apps.filter(appItem =>
      appItem.name.toLowerCase().includes(appNameFilter)
    );

    return (
      <div styleName="list-container">
        <div styleName="search">
          <h3>Applications</h3>
          <Input
            styleName="search-input"
            prefix={<SearchOutlined />}
            size="small"
            style={{ width: 200 }}
            allowClear
            placeholder="Search by name"
            onChange={e => this.handleSearch(e.target.value)}
          />
        </div>
        <div styleName="apps-list">
          <List
            itemLayout="horizontal"
            dataSource={apps}
            renderItem={item => (
              <List.Item
                actions={[
                  <Radio
                    checked={item.id === radioKey}
                    key="checked"
                    onChange={e => this.handleRadioChange(item.id)}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <img
                      src={item.icon || getDefaultIcon(item.type)}
                      alt={item.name}
                      style={{ width: 50, height: 40 }}
                    />
                  }
                  title={item.name}
                  description={`Type: ${item.type}`}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  }
}

export default AppsList;
