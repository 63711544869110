import { Form, Input, Modal, Select, Space, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { THIN_EDGE_PRO } from '../constant';
// import GeoLocationField from './GeoLocationField';
import ProModeFields from './ProModeFields';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
};

const formItemLayoutWithutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 17, offset: 6 },
  },
};

// TODO:
// 1. check location name duplication
// 2. support advance mode

const EditLocationModal = forwardRef(({ type, onFinish }, ref) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [initialValue, setInitialValue] = useState({});

  useImperativeHandle(ref, () => ({
    showModal: (value = { cpe_networks: [{ cpe_network: '' }] }) => {
      form.setFieldsValue(value);
      setInitialValue(value);
      setVisible(true);
    },
  }));

  const handleAddLocation = useCallback(
    values => {
      console.log('Location =>', { values });
      onFinish(values);
      setVisible(false);
    },
    [onFinish]
  );

  return (
    <Modal
      visible={visible}
      centered
      maskClosable={false}
      destroyOnClose
      title={_isEmpty(initialValue) ? 'Add New Location' : 'Edit Location'}
      okText={_isEmpty(initialValue) ? 'Add' : 'Save'}
      onOk={form.submit}
      onCancel={() => setVisible(false)}
      afterClose={() => form.resetFields()}
    >
      <Form form={form} {...formItemLayout} onFinish={handleAddLocation} autoComplete="off">
        <Form.Item
          name="name"
          key="name"
          label="Name"
          rules={[{ required: true, message: 'Name is required.' }]}
          autoComplete="name"
        >
          <Input />
        </Form.Item>

        <Form.Item name="description" key="description" label="Description">
          <TextArea />
        </Form.Item>

        {/* <GeoLocationField form={form} /> */}

        <Form.Item name="owner" key="owner" label="Owner">
          <Input />
        </Form.Item>

        {
          // TODO:
          // 1. Pro fields
          type === THIN_EDGE_PRO && <ProModeFields />
          // 2. Advance fields
        }
      </Form>
    </Modal>
  );
});

EditLocationModal.propTypes = {
  type: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default EditLocationModal;
