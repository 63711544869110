import './IPsecConfig.module.less';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Radio, Space, Switch } from 'antd';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _values from 'lodash/values';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IP_HOST_REGEX } from 'utils/commonRegex';

import { DNS_SERVICE_OPTIONS, SUBNET_REGEX, THIN_EDGE_ADVANCE } from './constant';
import {
  checkRangesOverlapping,
  fetchDefaultIpRanges,
  validateIpRange,
} from './IPsecConfig.service';

const AdvanceFields = ({ form, data, defaultConfig, updateDefaultConfig }) => {
  const [dhcpEnabled, setDhcpEnabled] = useState(false);
  const [dnsService, setDnsService] = useState();
  const { getFieldValue, getFieldsValue, setFields, validateFields } = form;

  useEffect(() => {
    const type = form.getFieldValue('type');
    if (type === THIN_EDGE_ADVANCE) {
      const defaultDhcpToggle = _isEmpty(data)
        ? _get(defaultConfig, 'dhcp_enable')
        : _get(data, 'dhcp_enable');
      setDhcpEnabled(defaultDhcpToggle);
      form.setFieldsValue({
        dhcp_enable: defaultDhcpToggle,
      });

      const defaultDnsService = _isEmpty(data)
        ? _get(defaultConfig, 'dhcp_server.dns_service', 'default')
        : _get(data, 'dhcp_server.dns_service') || 'default';

      setDnsService(defaultDnsService);
      form.setFieldsValue({
        dhcp_server: {
          dns_service: defaultDnsService,
        },
      });
    }
  }, [data, defaultConfig, form]);

  useEffect(() => {
    let isMounted = true;
    // if there is filled data & the type is Advance,
    // query the default IP Ranges
    const isAdvance = _get(data, 'type') === THIN_EDGE_ADVANCE;
    const ip = _get(data, 'ip');
    isAdvance &&
      ip &&
      fetchDefaultIpRanges(ip).then(ipRanges => {
        if (!_isEmpty(defaultConfig)) {
          const newConfig = _cloneDeep(defaultConfig);
          if (_get((newConfig, 'dhcp_server.ip_range') !== ipRanges)) {
            _set(newConfig, 'dhcp_server.ip_range', ipRanges);
            isMounted && updateDefaultConfig(newConfig);
          }
        }
      });
    return () => (isMounted = false);
  }, [data, defaultConfig, updateDefaultConfig]);

  const updateIpRanges = useCallback(
    ip => {
      ip &&
        fetchDefaultIpRanges(ip).then(ipRanges => {
          if (!_isEmpty(defaultConfig)) {
            const newConfig = _cloneDeep(defaultConfig);
            _set(newConfig, 'dhcp_server.ip_range', ipRanges);
            updateDefaultConfig(newConfig);
          }
        });
    },
    [defaultConfig, updateDefaultConfig]
  );

  // antd custom validator
  // Example: https://ant.design/components/form/#components-form-demo-register
  // Note: https://ant.design/components/form/#Custom-validator-not-working
  const ipRangeValidator = useCallback(
    (rule, value) => {
      try {
        const fieldPath = rule.fullField.split('.').slice(0, 2);
        const index = Number(rule.fullField.split('.').slice(2, 3));
        const _ip = getFieldValue(`ip`) || _get(defaultConfig, `ip`, null);

        let startIp = null;
        let endIp = null;
        if (rule.fullField.includes('start_ip')) {
          startIp = value;
          endIp = getFieldValue([...fieldPath, index, 'end_ip']);
        } else if (rule.fullField.includes('end_ip')) {
          startIp = getFieldValue([...fieldPath, index, 'start_ip']);
          endIp = value;
        }

        if (startIp && endIp) {
          validateIpRange(_ip, startIp, endIp);
          setFields([
            {
              name: [...fieldPath, index, 'start_ip'],
              errors: [],
            },
            {
              name: [...fieldPath, index, 'end_ip'],
              errors: [],
            },
          ]);
        }
      } catch (err) {
        const fieldPath = rule.fullField.split('.').slice(0, 2);
        const index = Number(rule.fullField.split('.').slice(2, 3));
        setFields([
          {
            name: [...fieldPath, index, 'start_ip'],
            errors: [err.message],
          },
          {
            name: [...fieldPath, index, 'end_ip'],
            errors: [err.message],
          },
        ]);
        return Promise.reject(err);
      }

      return Promise.resolve();
    },
    [defaultConfig, getFieldValue, setFields]
  );

  //TODO: can move to service
  const checkIpRangeOverlapping = useCallback(() => {
    const ranges = _get(getFieldsValue(), `dhcp_server.ip_range`, []).filter(
      el => el.start_ip && el.end_ip
    );
    if (ranges.length === 0) return;

    const isOverlapped = checkRangesOverlapping(ranges);
    // if overlapped, set error to each range filed
    const fields = ranges.reduce((fields, _, i) => {
      _set(fields, `dhcp_server.ip_range[${i}].start_ip`, {
        value: getFieldValue(`dhcp_server.ip_range[${i}].start_ip`),
        errors: isOverlapped ? [new Error('IP conflict')] : null,
      });
      _set(fields, `dhcp_server.ip_range[${i}].end_ip`, {
        value: getFieldValue(`dhcp_server.ip_range[${i}].end_ip`),
        errors: isOverlapped ? [new Error('IP conflict')] : null,
      });
      return fields;
    }, {});

    setTimeout(() => {
      setFields(fields);
    }, 100);
  }, [getFieldValue, getFieldsValue, setFields]);

  const dhcpFields = useMemo(() => {
    return (
      <>
        <Form.Item key="addressRange" label="Address Range">
          <Form.List name={['dhcp_server', 'ip_range']} initialValue={[{}, {}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <Space key={field.key} align="baseline">
                    <Form.Item noStyle>
                      <Space align="baseline" split={'-'}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'start_ip']}
                          rules={[
                            {
                              required: !!getFieldValue(
                                `dhcp_server.ip_range[${field.name}].end_ip`
                              ),
                              message: 'This field is required.',
                            },
                            { pattern: IP_HOST_REGEX, message: 'Invalid IP Address' },
                            { validator: ipRangeValidator },
                          ]}
                          initialValue={_get(
                            data,
                            `dhcp_server.ip_range[${field.name}].start_ip`,
                            ''
                          )}
                          validateTrigger="onBlur"
                        >
                          <Input
                            placeholder={_get(
                              defaultConfig,
                              `dhcp_server.ip_range[${field.name}].start_ip`,
                              'Start IP'
                            )}
                            onBlur={() => {
                              if (getFieldValue(`dhcp_server.ip_range[${field.name}].end_ip`)) {
                                validateFields([
                                  `dhcp_server.ip_range[${field.name}].start_ip`,
                                  `dhcp_server.ip_range[${field.name}].end_ip`,
                                ]).then(values => checkIpRangeOverlapping());
                              }
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, 'end_ip']}
                          rules={[
                            {
                              required: !!getFieldValue(
                                `dhcp_server.ip_range[${field.name}].start_ip`
                              ),
                              message: 'This field is required.',
                            },
                            { pattern: IP_HOST_REGEX, message: 'Invalid IP Address' },
                            { validator: ipRangeValidator },
                          ]}
                          initialValue={_get(
                            data,
                            `dhcp_server.ip_range[${field.name}].end_ip`,
                            ''
                          )}
                          validateTrigger="onBlur"
                        >
                          <Input
                            placeholder={_get(
                              defaultConfig,
                              `dhcp_server.ip_range[${field.name}].end_ip`,
                              'End IP'
                            )}
                            onBlur={() => {
                              if (getFieldValue(`dhcp_server.ip_range[${field.name}].start_ip`)) {
                                validateFields([
                                  `dhcp_server.ip_range[${field.name}].start_ip`,
                                  `dhcp_server.ip_range[${field.name}].end_ip`,
                                ]).then(values => checkIpRangeOverlapping());
                              }
                            }}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    {fields.length > 1 && (
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    )}
                  </Space>
                ))}
                <Form.Item wrapperCol={{ span: 18 }}>
                  <Button
                    type="dashed"
                    disabled={fields.length >= 3}
                    onClick={() => fields.length < 3 && add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          key="dns_service"
          name={['dhcp_server', 'dns_service']}
          initialValue={
            _isEmpty(data)
              ? _get(defaultConfig, `dhcp_server.dns_service`, 'default')
              : _get(data, `dhcp_server.dns_service`, 'default')
          }
          label="DNS Server"
        >
          <Radio.Group
            buttonStyle="solid"
            onChange={({ target: { value } }) => setDnsService(value)}
          >
            {_values(DNS_SERVICE_OPTIONS).map(item => (
              <Radio.Button key={item.value} value={item.value}>
                {item.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        {dnsService === DNS_SERVICE_OPTIONS.SPECIFY.value && (
          <Form.List
            name={['dhcp_server', 'dns_server']}
            initialValue={_get(data, `dhcp_server.dns_server`, ['0.0.0.0'])}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <Form.Item key={field.name} wrapperCol={{ span: 24 }}>
                    <div className="flex-row" style={{ width: '100%', position: 'relative' }}>
                      <Form.Item
                        {...field}
                        label={`${field.name === 0 ? 'Primary' : 'Secondary'} DNS Server`}
                        rules={[
                          { required: true, message: 'This field is required' },
                          { pattern: IP_HOST_REGEX, message: 'Invalid IP Address' },
                        ]}
                        validateTrigger="onBlur"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        style={{ width: '100%' }}
                        initialValue={_get(
                          data,
                          `dhcp_server.dns_server[${field.name}]`,
                          '0.0.0.0'
                        )}
                      >
                        <Input />
                      </Form.Item>

                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                          style={{
                            position: 'absolute',
                            top: 14,
                            right: 0,
                            transform: 'translateY(-50%)',
                          }}
                        />
                      )}
                    </div>
                  </Form.Item>
                ))}

                <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
                  <Button
                    type="dashed"
                    disabled={fields.length >= 2}
                    onClick={() => fields.length < 2 && add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}
      </>
    );
  }, [
    checkIpRangeOverlapping,
    data,
    defaultConfig,
    dnsService,
    getFieldValue,
    ipRangeValidator,
    validateFields,
  ]);

  return (
    <>
      <Divider orientation="left">VxLAN Interface</Divider>
      <br />
      <Form.Item
        key="ip"
        name="ip"
        initialValue={_get(data, 'ip')}
        label="SASE Gateway IP/CIDR"
        rules={[{ pattern: SUBNET_REGEX, message: 'Invalid SASE Gateway IP/CIDR.' }]}
        validateTrigger="onBlur"
      >
        <Input
          placeholder={_get(defaultConfig, 'ip')}
          onBlur={e => {
            const value = e.target.value;
            const isValid = SUBNET_REGEX.test(value);
            if (!value || !isValid) return;
            updateIpRanges(value);
          }}
        />
      </Form.Item>
      <Form.Item
        key="dhcp"
        name="dhcp_enable"
        valuePropName="checked"
        label="DHCP Server"
        // initialValue={_isEmpty(data) ? _get(defaultConfig, 'dhcp_enable') : _get(data, 'dhcp_enable')}
        initialValue={_get(data, 'dhcp_enable')}
      >
        <Switch onChange={value => setDhcpEnabled(value)} />
      </Form.Item>

      {dhcpEnabled && dhcpFields}
    </>
  );
};

AdvanceFields.propTypes = {};

export default AdvanceFields;
