import Icon from '@ant-design/icons';
import HtmlToReact from 'html-to-react';
import PropTypes from 'prop-types';
import React from 'react';

const htmlToReactParser = new HtmlToReact.Parser(React);

// render Neutrino icons to a Ant Design Icon Component
const NuIcon = ({ icon = {}, style = {}, className, ...options }) => {
  const defaultStyle = {
    width: icon.size || 18,
    height: icon.size || 18,
    flexShrink: 0,
    overflow: 'hidden',
    verticalAlign: '-3px',
    fill: 'currentColor',
  };
  const svg = htmlToReactParser.parse(icon.svg); // convert svg string to a React DOM
  return (
    <Icon
      component={() => (
        <svg {...svg.props} data-nu-icon={`${icon.setName}__${icon.name}`}>
          {svg.props.children}
        </svg>
      )}
      className={`nu-icon ${className ? className : ''}`}
      style={{ ...defaultStyle, ...style }}
      {...options}
    />
  );
};

NuIcon.propTypes = {
  // neutrino icon object
  // https://neutrino.corp.fortinet.com/0.9.0-interim-217-00a003d0/sg/overview/icons?content=examples#available-icons
  icon: PropTypes.object.isRequired,
};

export default NuIcon;
