export const API_BASE_URL = '/api';
export const REFRESH_TOKEN_URL = `${API_BASE_URL}/v1/system/refreshtoken`;
export const ACCESS_TOKEN_KEY = 'remote_user_access_token';
export const REFRESH_TOKEN_KEY = 'remote_user_refresh_token';

// portal pathes
export const ROOT_PATH = '/userportal';
export const LOGIN_PATH = `${ROOT_PATH}/login`;
export const FORGOT_PWD_PATH = `${ROOT_PATH}/forgot-password`;
export const RESET_PWD_PATH = `${ROOT_PATH}/reset-password`;
export const ACTIVATION_PATH = `${ROOT_PATH}/activation`;
export const ERROR_404_PATH = `${ROOT_PATH}/404`;
