import { message } from 'antd';
import jwtDecode from 'jwt-decode';

import { LOGIN_PATH } from './constants';

// Usage: https://ant.design/components/message/#API
export function broadcastMessage(level = 'info', ...options) {
  message.config({
    duration: 3,
  });
  message[level].apply(null, options);
}

export function decodeToken(token) {
  let decoded = {};
  if (!token) {
    return decoded;
  }
  try {
    decoded = jwtDecode(token);
  } catch (error) {
    console.error(error);
  }
  return decoded;
}

export function getLoginURL() {
  const loginQuery = sessionStorage.getItem('loginQuery') || '';
  return LOGIN_PATH + loginQuery;
}

export const passwordRules = [
  {
    required: true,
    message: 'Please input your new password!',
  },
  {
    min: 8,
    message: 'Password must be at least 8 characters long.',
  },
  {
    max: 64,
    message: 'Password must be within 64 characters long.',
  },
  {
    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{1,}$/,
    message: `Password must have at least one lowercase letter (a-z), one uppercase letter (A-Z), one digit (0-9), one special character (~\`!@#$%^&*()_-+={[}]|\\:;"'<,>.?/)`,
  },
];
