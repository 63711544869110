import './SiteList.module.less';

import { Button, Divider, Modal, Spin, Table, Typography } from 'antd';
import InfoItem from 'components/InfoItem/InfoItem';
import Page from 'components/Page';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getSiteInfo, getSiteList } from 'redux/Security/Security.actions';
import { getPath } from 'routeConfig';
import useEntitlements from 'utils/hooks/useEntitlements';
import { getVpnConfig } from 'utils/services/site.service';

import IPsecInfo from '../shared/IPsecVPN/IPsecInfo';

const { Text, Paragraph } = Typography;

const SiteInfo = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const breakpoint = useSelector(state => state.globalSetting.breakpoint);
  const { id: siteId } = useParams();
  const site = useSelector(state => state.security.sites.find(el => el.id === siteId) || {});
  const { isSslEntitled, isIpsecEntitled } = useEntitlements();
  const [loading, setLoaindg] = useState(false);
  const [data, setData] = useState({});
  const [detail, setDetail] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setLoaindg(true);
    dispatch(getSiteList());
    dispatch(getSiteInfo(siteId));
    getVpnConfig(siteId)
      .then(data => setData(data))
      .finally(() => setLoaindg(false));
  }, [dispatch, siteId]);

  const viewDetail = useCallback(
    targetId => {
      const { details } = data;
      const target = details.find(({ id }) => id === targetId);
      setDetail({ ...target.ipsec, id: target.id, name: target.name });
      setModalVisible(true);
    },
    [data]
  );

  const cloaseModal = useCallback(() => {
    setModalVisible(false);
    setDetail({});
  }, []);

  const sslSection = useMemo(() => {
    const generalInfo = _get(data, 'ssl', {});
    const details = _get(data, 'details', []);

    return (
      <section>
        <Divider orientation="left">SSL VPN</Divider>
        {generalInfo.enable ? (
          <>
            <div style={breakpoint === 'xs' ? { padding: 0 } : { padding: '0 32px' }}>
              <div style={{ padding: '0 24px' }}>
                <InfoItem
                  readOnly
                  label="SSL Gateway"
                  value={
                    <Typography.Link
                      href={generalInfo.domain_name}
                      target="_blank"
                      copyable
                      data-test="ssl-gateway-url"
                    >
                      {`${generalInfo.domain_name}`}
                    </Typography.Link>
                  }
                />
              </div>
              {!!details.length && (
                <Table
                  rowKey={'name'}
                  dataSource={details}
                  size="small"
                  pagination={false}
                  style={{ margin: '0 16px' }}
                >
                  <Table.Column key="name" dataIndex="name" title="Backend Site" />
                  <Table.Column key="region" dataIndex="region" title="Region" />
                  <Table.Column
                    key="ssl"
                    title="SSL Gateway"
                    render={record => (
                      <Typography.Link
                        copyable
                        href={_get(record, 'ssl.domain_name')}
                        target="_blank"
                      >{`${_get(record, 'ssl.domain_name')}`}</Typography.Link>
                    )}
                  />
                </Table>
              )}
            </div>
          </>
        ) : (
          <p style={{ display: 'inline-block', marginLeft: 54 }}>N/A</p>
        )}
      </section>
    );
  }, [breakpoint, data]);

  const ipsecSection = useMemo(() => {
    const ipsecInfo = _get(data, 'ipsec', {});
    const details = _get(data, 'details', []);

    let content;
    switch (true) {
      case details.filter(site => !!_get(site, 'ipsec.enable')).length > 0:
        content = (
          <Table
            rowKey={'name'}
            dataSource={details}
            size="small"
            pagination={false}
            style={{ margin: '0 24px' }}
          >
            <Table.Column key="name" dataIndex="name" title="Backend Site" />
            <Table.Column key="region" dataIndex="region" title="Region" />
            <Table.Column
              key="ipsec"
              title="Thin Edge Type"
              render={record => {
                return <span>{_startCase(_get(record, 'ipsec.type'))}</span>;
              }}
            />
            <Table.Column
              key="ipsec"
              title="IPSec VPN"
              render={record => {
                return (
                  <Button type="link" onClick={() => viewDetail(record.id)}>
                    Detail
                  </Button>
                );
              }}
            />
          </Table>
        );
        break;
      case ipsecInfo.enable:
        content = (
          <IPsecInfo
            style={breakpoint === 'xs' ? { padding: 0 } : { padding: '0 32px' }}
            data={{ ...ipsecInfo, id: siteId, name: site.name }}
          />
        );
        break;
      default:
        content = <p style={{ display: 'inline-block', marginLeft: 32 }}>N/A</p>;
    }
    return (
      <section>
        <Divider orientation="left">IPSec VPN</Divider>

        <div style={{ padding: '0 24px' }}>{content}</div>
      </section>
    );
  }, [breakpoint, data, site.name, siteId, viewDetail]);

  return (
    <Page>
      <Page.Header
        title={`Network Access Info`}
        onBack={() => history.push(getPath('site-list'))}
      />
      <div className="flex-col flex-item" style={{ margin: '24px 0', position: 'relative' }}>
        {loading ? (
          <Spin styleName="spinner" />
        ) : (
          <div styleName="site-info">
            {isSslEntitled && sslSection}
            <br />
            {isIpsecEntitled && ipsecSection}
          </div>
        )}
        <Modal
          title={`IPSec VPN`}
          width={'95%'}
          style={{
            maxWidth: 720,
            maxHeight: '95vh',
          }}
          bodyStyle={{
            height: 760,
            maxHeight: 'calc(95vh - 55px)',
            overflow: 'auto',
          }}
          footer={null}
          visible={modalVisible}
          centered
          autoFocusButton={null}
          maskClosable
          onCancel={cloaseModal}
        >
          {_isEmpty(detail) ? (
            <div style={{ height: '100%' }} className="flex-col main-center cross-center">
              <Spin />
            </div>
          ) : (
            <IPsecInfo data={detail} style={{ padding: '0 24px' }} />
          )}
        </Modal>
      </div>
    </Page>
  );
};

SiteInfo.propTypes = {
  // siteId: PropTypes.string.isRequired,
  // siteName: PropTypes.string.isRequired,
};

export default SiteInfo;
