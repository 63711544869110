import _get from 'lodash/get';

import { parseDashString, toLocalTime } from './util';

const formatNotification = notification => {
  const dateStr = toLocalTime(notification.data.timestamp, { timeZoneName: 'short' });
  const { name } = notification.data;
  let title = parseDashString(notification.type, true);
  let state = parseDashString(_get(notification, 'data.state_label', ''));
  let linkUrl;

  switch (notification.type) {
    case 'analytics':
      linkUrl = '/settings/analytics';
      break;
    case 'remote_user':
      linkUrl = '/security/remote-user';
      state = parseDashString(notification.data.provision_status);
      break;
    case 'security':
      linkUrl = '/security/site';
      title = 'Site'; // The label need to be consistent with the sidebar menu
      break;
    default:
      break;
  }

  return {
    title,
    name,
    state,
    linkUrl,
    dateStr,
  };
};

export default formatNotification;
