import './IPsecConfig.module.less';

import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Form, Radio, Switch, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import AdvanceFields from './AdvanceFields';
import { THIN_EDGE_ADVANCE, THIN_EDGE_EXPRESS, THIN_EDGE_PRO } from './constant';
import {
  checkRangesOverlapping,
  fetchDefaultIpRanges,
  validateIpRange,
} from './IPsecConfig.service';
import LocationList from './Location/LocationList';

const layoutOptions = {
  labelCol: { span: 6, xs: 24, sm: 6 },
  wrapperCol: { span: 18, xs: 24, sm: 18 },
};

const IPsecConfigForm = forwardRef(({ data, onChange, formOptions, defaultValues }, ref) => {
  const [form] = useForm();
  const [type, setType] = useState('');
  const locationListRef = useRef();

  useEffect(() => {
    setType(data.type || _get(defaultValues, 'ipsecgateway_type'));
  }, [data, defaultValues]);

  useEffect(() => {
    console.log({ defaultValues });
    // setDefaultConfig(_get(defaultValues, `ipsecgateway_customer_config[${type}].cloud.interface[0]`, {}));
  }, [defaultValues]);

  useEffect(() => {
    // set form initial values

    const { isIpsecEnabled, ...initialValue } = data;
    const defaultValue = {
      type: defaultValues.ipsecgateway_type,
      bound_firewall_policy: defaultValues.bound_firewall_policy,
    };

    form.setFieldsValue(_isEmpty(initialValue) ? defaultValue : initialValue);
  }, [
    data,
    defaultValues.bound_firewall_policy,
    defaultValues.ipsec_gateway_type,
    defaultValues.ipsecgateway_type,
    form,
  ]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      return form.validateFields().then(values =>
        Promise.resolve({
          ...values,
          cloud: { interface: locationListRef.current.getLocations() },
        })
      );
    },
  }));

  return (
    <Form
      ref={ref}
      form={form}
      {...layoutOptions}
      onValuesChange={(changedValue, allValues) => onChange && onChange()}
      {...formOptions}
    >
      <Form.Item
        key="type"
        name="type"
        labelAlign="left"
        label={
          <span>
            Type&nbsp;
            <Tooltip
              title={
                <div style={{ color: 'white' }}>
                  <p>Pro: Customized LAN IP address</p>
                  <p>Advance: VxLAN over IPSec</p>
                </div>
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        labelCol={{ span: 4, xs: 24, sm: 4, md: 3 }}
        wrapperCol={{ span: 18, xs: 24, sm: 18 }}
        style={{ marginLeft: 32 }}
      >
        <Radio.Group buttonStyle="solid" onChange={({ target: { value } }) => setType(value)}>
          <Radio.Button key="pro" value={THIN_EDGE_PRO}>
            Pro
          </Radio.Button>
          <Radio.Button key="advance" value={THIN_EDGE_ADVANCE}>
            Advance
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        key="bound_firewall_policy"
        name="bound_firewall_policy"
        valuePropName="checked"
        label="Apply Security Policy"
        labelAlign="left"
        labelCol={{ span: 6, xs: 24, sm: 7, md: 5 }}
        wrapperCol={{ span: 18, xs: 24, sm: 17, md: 19 }}
        style={{ marginLeft: 32 }}
      >
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
      </Form.Item>

      {
        // FIXME: relocate advance fields
        // type === THIN_EDGE_ADVANCE && (
        //   <AdvanceFields
        //     form={form}
        //     data={data}
        //     defaultConfig={defaultConfig}
        //     updateDefaultConfig={(values) => setDefaultConfig(values)}
        //   />
        // )
      }

      <Divider orientation="left" styleName="divider">
        Locations
      </Divider>
      <LocationList
        ref={locationListRef}
        data={_get(data, 'cloud.interface', [])}
        type={type}
        editable={true}
      />
    </Form>
  );
});

const IPsecConfig = forwardRef(({ data, onChange, formOptions, defaultValues }, ref) => {
  return (
    <div className="flex-col flex-item" style={{ overflow: 'auto' }}>
      <IPsecConfigForm
        ref={ref}
        data={data}
        defaultValues={defaultValues}
        formOptions={formOptions}
        onChange={onChange}
      />
    </div>
  );
});

IPsecConfig.propTypes = {
  data: PropTypes.object,
  formOptions: PropTypes.object,
  onChange: PropTypes.func,
  defaultValues: PropTypes.object,
};

export default IPsecConfig;
