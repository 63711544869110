import './Page.module.less';

import React, { Component } from 'react';

import Footer from './Footer';
import Header from './Header';

/**
 * @description
 * {@link https://dops-git.fortinet-us.com/FortiGuardCloud/fgc/blob/master/fgc-web-ng/src/components/Page/README.md}
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-16
 * @class Page
 * @extends {Component}
 */
class Page extends Component {
  static Header = Header;
  static Footer = Footer;
  state = {};

  render() {
    const {
      children,
      className = '',
      styleName,
      header,
      title,
      toolbar,
      footer,
      ...restProps
    } = this.props;
    let _header = [];
    let _content = [];
    let _footer = null;

    React.Children.forEach(this.props.children, el => {
      if (el && el.type === Header) {
        _header.push(el);
      } else if (el && el.type === Footer) {
        _footer = el;
      } else {
        _content.push(el);
      }
    });

    return (
      <div className={`flex-col flex-item ${className}`} styleName={styleName} {...restProps}>
        <div styleName="page-wrap" className="flex-col flex-item">
          {_header}
          <section className="page-content flex-col flex-item">{_content}</section>
          {_footer}
        </div>
      </div>
    );
  }
}

export default Page;
