import { CheckOutlined, CloseOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal, Select, Space, Switch } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { LAN_IP_REGEX, THIN_EDGE_ADVANCE, THIN_EDGE_PRO } from '../constant';
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
};

const formItemLayoutWithutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 17, offset: 6 },
  },
};

const ProModeFields = props => {
  return (
    <>
      <Form.Item name="nat_enable" key="nat" label="NAT" valuePropName="checked">
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => prevValues.nat_enable !== curValues.nat_enable}
      >
        {({ getFieldValue }) =>
          !getFieldValue('nat_enable') && (
            <Form.List name="cpe_networks" key="cpe_networks">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayoutWithutLabel)}
                      label={index === 0 ? 'LAN IP/CIDR' : ''}
                      required={true}
                      key={field.key}
                    >
                      <div className="flex-row cross-center" style={{ width: '100%' }}>
                        <Form.Item
                          {...field}
                          rules={[
                            { required: true, message: 'LAN IP/CIDR is required.' },
                            { pattern: LAN_IP_REGEX, message: 'Invalid LAN IP/CIDR.' },
                          ]}
                          name={[`${index}`, 'cpe_network']}
                          validateTrigger="onBlur"
                          noStyle
                          style={{ flex: 1 }}
                        >
                          <Input />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ marginLeft: 8 }}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </div>
                    </Form.Item>
                  ))}
                  <Form.Item {...formItemLayoutWithutLabel}>
                    <Button onClick={() => add()} icon={<PlusOutlined />}>
                      Add
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          )
        }
      </Form.Item>
    </>
  );
};

ProModeFields.propTypes = {};

export default ProModeFields;
