import loginBgImg from 'assets/images/login_page_bg.jpeg';
import React, { useEffect, useState } from 'react';

export default function bgWrapper(WrappedComponent) {
  return props => {
    const { className, styleName } = props;

    return (
      <div styleName={styleName} className={className} style={{ height: '100%', display: 'flex' }}>
        <div
          className="full-img-bg"
          style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            backgroundImage: `url(${loginBgImg})`,
            backgroundColor: '#09141C',
            filter: 'brightness(80%)',
            top: 0,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            background: 'rgba(29, 45, 61, 0.4)',
            top: 0,
          }}
        />
        <WrappedComponent {...props} />
      </div>
    );
  };
}
