import request from './request';

const appResource = '/v1/security/applications';
const vaultsResource = '/v1/security/vaults';

/**
 * @params {siteId, remoteUserId}
 * url parameters: site_id=xxxx&remote_user_id=xxxxx
 * @param {callback} successCallback
 * @param {callback} errorCallback
 */
function getRemoteUserApps({ siteId, remoteUserId }, ...cb) {
  return request(
    {
      url: `${appResource}?site_id=${siteId}&remote_user_id=${remoteUserId}`,
    },
    ...cb
  );
}

function addRemoteUserApp(appData, ...cb) {
  return request(
    {
      url: `${appResource}`,
      method: 'post',
      data: appData,
    },
    ...cb
  );
}

function deleteRemoteUserApp(appId, ...cb) {
  return request(
    {
      url: `${appResource}/${appId}`,
      method: 'delete',
    },
    ...cb
  );
}

function updateRemoteUserApp(appId, appData, ...cb) {
  return request(
    {
      url: `${appResource}/${appId}`,
      method: 'patch',
      data: appData,
    },
    ...cb
  );
}

/**
 * @description get sslwebportal login cookie
 * @author Huanle Hu <huh@fortinet.com>
 * @date 2020-04-08
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */

function getVpnTest(id, ...cb) {
  return request(
    {
      url: '/v1/vpn/logintest/' + id,
    },
    ...cb
  );
}

function getVpnLoginCookie(siteId, endUserId, ...cb) {
  return request(
    {
      url: `/v1/vpn/sites/${siteId}/end-users/${endUserId}/login`,
    },
    ...cb
  );
}

/**
 * @description  password vaults
 * @author Huanle Hu <huh@fortinet.com>
 * @date 2020-05-06
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */

function getPwdValuts(query = {}, ...cb) {
  return request(
    {
      url: vaultsResource,
      params: {
        ...query,
      },
    },
    ...cb
  );
}

function addPwdVault(pwdData, ...cb) {
  return request(
    {
      url: vaultsResource,
      method: 'post',
      data: pwdData,
    },
    ...cb
  );
}

function updatePwdVault(vaultId, vaultData, ...cb) {
  return request(
    {
      url: `${vaultsResource}/${vaultId}`,
      method: 'patch',
      data: vaultData,
    },
    ...cb
  );
}

function deletePwdVault(vaultId, ...cb) {
  return request(
    {
      url: `${vaultsResource}/${vaultId}`,
      method: 'delete',
    },
    ...cb
  );
}

export default {
  getVpnLoginCookie,
  getVpnTest,
  getRemoteUserApps,
  addRemoteUserApp,
  deleteRemoteUserApp,
  updateRemoteUserApp,
  getPwdValuts,
  addPwdVault,
  updatePwdVault,
  deletePwdVault,
};
