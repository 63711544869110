import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const useEntitlements = props => {
  const tenant = useSelector(state => state.globalSetting.user.tenant);
  const license = _get(tenant, 'company.license', {});
  const isIpsecEntitled =
    _get(license, 'security_entitlement.site.ipsec_entitlement.availability') &&
    _get(license, 'security_entitlement.site.ipsec_entitlement.visibility');

  const isSslEntitled =
    _get(license, 'security_entitlement.site.ssl_vpn_entitlement.availability') &&
    _get(license, 'security_entitlement.site.ssl_vpn_entitlement.visibility');

  const entitledLogModels = _get(license, 'analytics_entitlement.models', ['small']);
  return { isSslEntitled, isIpsecEntitled, entitledLogModels };
};

useEntitlements.propTypes = {};

export default useEntitlements;
