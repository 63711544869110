import './SiteDetail.module.less';

import { Divider, Input, Tag } from 'antd';
import EditableTagGroup from 'components/EditableTagGroup';
import InfoItem from 'components/InfoItem';
import _get from 'lodash/get';
import IPsecConfigReview from 'pages/Security/shared/IPsecVPN/IPsecConfig/Review';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from 'redux/GlobalSetting/globalSetting.actions';
import { getEdge, getSiteStatusBadge, getSiteTypeLabel } from 'utils/services/site.service';

import { getIPsecOptions, getSslOptions } from '../shared/site.service';

const StandaloneSiteDetail = props => {
  const { data, readOnly, patchSite } = props;
  const dispatch = useDispatch();
  const descInputRef = useRef();
  const tagsInputRef = useRef();

  const cloudRegions = useSelector(state => state.globalSetting.regions || []);

  const tenantUser = useSelector(state => state.globalSetting.user);
  const license = _get(tenantUser, 'tenant.company.license', {});

  const typeLabel = getSiteTypeLabel(data);
  const tagBadges = data.tags && data.tags.map((el, index) => <Tag key={index}>{el}</Tag>);

  const handleSave = useCallback(value => patchSite(value), [patchSite]);

  const showSSL = _get(getSslOptions(license), 'visibility');
  const showIPsec = _get(getIPsecOptions(license), 'visibility');
  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  const edge = getEdge(_get(data, 'region_name', ''), cloudRegions);

  return (
    <div styleName={readOnly && 'read-only'}>
      <Divider orientation="left">Site Info</Divider>
      <div styleName="info-section">
        <InfoItem label="Site Name" value={data.name} />
        <InfoItem
          label="Description"
          value={data.description}
          inputElement={<Input.TextArea ref={descInputRef} defaultValue={data.description} />}
          editable={!readOnly}
          onSave={() =>
            handleSave({ description: descInputRef.current.resizableTextArea.props.value })
          }
        />
        <InfoItem label="Edge" value={edge} />
        <InfoItem label="Region" value={data.region_alias} />
        <InfoItem label="Type" value={typeLabel} />
        <InfoItem
          label="Tags"
          value={tagBadges}
          inputElement={<EditableTagGroup ref={tagsInputRef} value={data.tags || []} />}
          editable={!readOnly}
          onSave={() => handleSave({ tags: tagsInputRef.current.state.tags })}
        />
        {!!data.state_label && (
          <InfoItem
            label="Status"
            value={
              <span>
                {getSiteStatusBadge(data)} {data.state_label}
              </span>
            }
          />
        )}
      </div>
      <br />
      <Divider orientation="left">VPN</Divider>
      <div styleName="info-section">
        {showSSL && (
          <InfoItem label="SSL VPN" value={data.sslgateway_enable ? 'Enabled' : 'Disabled'} />
        )}
        {showIPsec && (
          <Fragment>
            <InfoItem label="Thin Edge" value={data.ipsecgateway_enable ? 'Enabled' : 'Disabled'} />
            {data.ipsecgateway_enable && (
              <IPsecConfigReview data={_get(data, 'ipsecgateway_customer_config')} />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

StandaloneSiteDetail.propTypes = {
  data: PropTypes.object.isRequired,
  patchSite: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default StandaloneSiteDetail;
