import axios from 'axios';

export function create(config) {
  const { baseURL, tokenKey } = config;

  const client = axios.create({
    baseURL: baseURL,
  });

  const request = function (options) {
    const onSuccess = function (response) {
      console.info('Request succeeded!', response);
      return response.data;
    };
    const onError = function (error) {
      console.error('Request Failed:', error.config);

      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.error('Error Message:', error.message);
      }

      return Promise.reject(error);
    };

    return client({
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem(tokenKey),
      },
      ...options,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { client, request };
}
