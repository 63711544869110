import api from 'api';
import _get from 'lodash/get';
import storage from 'utils/storage';

export const SUPER_ADMIN = 'Super Admin';
export const EDGE_ADMIN = 'Edge Admin';
export const SITE_ADMIN = 'Site Admin';
export const VIEWER = 'Viewer';

export const fetchUsers = ({ page, pageSize, sort } = {}) =>
  api.getUserList({ page, max_results: pageSize, sort }).then(({ data }) => Promise.resolve(data));

export const fetchRoles = () => api.getRoles().then(({ data: { data } }) => Promise.resolve(data));

export const isStandaloneEntitled = () =>
  _get(storage.get('license'), 'standalone_entitlement.visibility', false);

export const fetchUserResourceStatus = () =>
  api.getUserResourceStatus().then(({ data: { data } }) => Promise.resolve(data || []));

export const createUser = payload => api.createUser(payload).then(({ data }) => data);
export const updateUser = (id, payload) =>
  api.updateUser(id, payload).then(({ data: { data } }) => data);

export const fetchPrivilegedSites = id => api.getSitesByUser(id).then(({ data: { data } }) => data);

export const getTeanantAndUsersListRelations = (tenantUser, user) => {
  const isRoot = user.root;
  const isTenantViewer = _get(tenantUser, 'role', []).includes(VIEWER);
  const isTenantSSOUser = tenantUser.auth_type === 'sso';

  const isTenantSSOAdmin = isTenantSSOUser && !isTenantViewer;
  const isTenantSSOViewer = isTenantSSOUser && isTenantViewer;
  const isTenantStandaloneAdmin = !isTenantSSOUser && !isTenantViewer;
  const isTenantStandaloneViewer = !isTenantSSOUser && isTenantViewer;

  const isSelf = tenantUser.id === user.id;
  const isRecordSSOUser = user.auth_type === 'sso' && !isSelf;
  const isRecordStandAloneUser = user.auth_type === 'standalone' && !isSelf;

  return {
    isRoot,
    isTenantViewer,
    isTenantSSOUser,
    isTenantSSOAdmin,
    isTenantSSOViewer,
    isTenantStandaloneAdmin,
    isTenantStandaloneViewer,
    isSelf,
    isRecordSSOUser,
    isRecordStandAloneUser,
  };
};

export const isProvisionProcessing = user => {
  const assignedSites = _get(user, 'sites', []);
  const isProcessing = assignedSites.some(
    ({ user_provision_status }) => user_provision_status === 'pending'
  );
  return isProcessing;
};
