import { fetchWrap } from 'utils/fetch';

const resource = portal => `/v1/setting/permission/sso/${portal}`;

function getFazCookies(...cb) {
  return fetchWrap(
    {
      url: `${resource('faz')}/login`,
      method: 'post',
    },
    ...cb
  );
}

function getFazUrls(...cb) {
  return fetchWrap(
    {
      url: `${resource('faz')}/urls`,
    },
    ...cb
  );
}

export default {
  getFazCookies,
  getFazUrls,
};
