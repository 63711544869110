import './AccessTokens.module.less';

import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Pagination, Popconfirm, Table, Typography } from 'antd';
import api from 'api';
import clsx from 'clsx';
import Page from 'components/Page';
import PagenationInfo from 'components/PagenationInfo';
import _get from 'lodash/get';
import AddTokenModal from 'Modals/AddTokenModal';
import React, { Component } from 'react';
import { defaultSorter, toLocalTime } from 'utils/util';

const { Paragraph } = Typography;

class AccessTokens extends Component {
  state = {
    scope: {},
    getAccessTokensLoading: true,
    apiAccessToken: '',
    isModalOpen: false,
    accessTokensData: [],
    page: 1,
    size: 25,
    sort: '-_created',
    total: 0,
  };

  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      sorter: defaultSorter('name'),
    },
    {
      title: 'Created',
      dataIndex: '_created',
      width: 150,
      render: text => toLocalTime(text),
      sorter: (a, b) => new Date(a._created) - new Date(b._created),
    },
    {
      title: 'Expiration',
      dataIndex: 'expiration',
      width: 150,
      render: text => {
        if (text === '-1') {
          return <span>Unlimited</span>;
        } else {
          const isExpired = new Date(text) < new Date();
          return <span styleName={clsx({ expired: isExpired })}>{toLocalTime(text)}</span>;
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => {
        return (
          <Popconfirm title="Are you sure？" onConfirm={() => this.deleteToken(record.name)}>
            <span className="link">Revoke</span>
          </Popconfirm>
        );
      },
    },
  ];

  paginationProps = {
    size: 'small',
    pageSize: this.state.size,
    onChange: (page, pageSize) => {
      this.setState({ page });
      const { size, sort } = this.state;

      this.getAccessTokens({ page, size, sort });
    },
  };

  componentDidMount() {
    const { page, size, sort } = this.state;
    this.getScopes();
    this.getAccessTokens({ page, size, sort });
  }

  getScopes() {
    api.getScopes(
      ({ data: resData }) => {
        this.setState({
          scope: resData.data.scope,
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  deleteToken(tokenName) {
    api.deleteAccessToken(
      tokenName,
      res => {
        this.getAccessTokens();
      },
      error => {
        console.log('delete accesstoken error');
      }
    );
  }

  getAccessTokens = ({ page, size, sort } = {}) => {
    this.setState({ getAccessTokensLoading: true });
    api.getAccessTokens(
      { page, max_results: size, sort },
      ({ data: resData }) => {
        this.setState({
          accessTokensData: resData.data || [],
          getAccessTokensLoading: false,
          total: resData.meta.total,
          page: resData.meta.page,
        });
      },
      error => {
        this.setState({ getAccessTokensLoading: false });
      }
    );
  };

  onSorterChange = (pagination, filters, sorter) => {
    let sort = '-_created';
    const sortKey = _get(sorter, 'column.dataIndex', null);
    if (sorter.order) {
      sort = !!sortKey && sorter.order === 'descend' ? `-${sortKey}` : sortKey;
      this.setState({ sort });
    }
    const { page, size } = this.state;
    this.getAccessTokens({ page, size, sort });
  };

  tokenNameChange = e => {
    this.setState({ tokenName: e.target.value });
  };

  expirationChange = date => {
    this.setState({ expiration: date });
  };

  setModalOpenState = isModalOpen => {
    this.setState({ isModalOpen });
  };

  setApiAccessToken = (apiAccessToken = '') => {
    this.setState({ apiAccessToken });
  };

  renderExpendRow = record => (
    <span>
      <strong>Scope: </strong>
      {record.scope.scope.map(scopeItem => scopeItem.split(':')[0]).join(', ')}
    </span>
  );

  render() {
    const {
      scope,
      isModalOpen,
      apiAccessToken,
      getAccessTokensLoading,
      accessTokensData,
      page,
      size,
      total,
    } = this.state;
    const tableHeight = _get(document.querySelector('.page-content'), 'offsetHeight', 600) - 140;

    return (
      <Page>
        <Page.Header title="Personal Access Tokens" />
        <div styleName="create-btn-container">
          <Button onClick={() => this.setModalOpenState(true)} icon={<PlusOutlined />}>
            Create
          </Button>
        </div>
        {apiAccessToken && (
          <div styleName="token-info-container">
            <h4>Your New Personal Access Token</h4>
            <Alert
              message={`Make sure to copy your new personal access token now. You won't be able to see it again!`}
              banner
              type="info"
              showIcon={false}
            />
            <Alert
              message={
                <Paragraph copyable styleName="copy-p" ellipsis>
                  {apiAccessToken}
                </Paragraph>
              }
              type="success"
              showIcon
              icon={<CheckOutlined />}
              banner
            />
          </div>
        )}
        <Table
          rowKey="name"
          expandedRowRender={this.renderExpendRow}
          loading={getAccessTokensLoading}
          columns={this.columns}
          dataSource={accessTokensData}
          size="middle"
          pagination={false}
          onChange={this.onSorterChange}
          scroll={{ y: tableHeight, x: 900 }}
        />
        <AddTokenModal
          visible={isModalOpen}
          setModalOpenState={this.setModalOpenState}
          scope={scope}
          isModalOpen={isModalOpen}
          setApiAccessToken={this.setApiAccessToken}
          getAccessTokens={this.getAccessTokens}
          accessTokensData={accessTokensData}
        />
        <Page.Footer>
          <PagenationInfo
            current={page}
            size={size}
            total={total}
            dataLength={accessTokensData.length}
          />
          <Pagination {...this.paginationProps} current={page} pageSize={size} total={total} />
        </Page.Footer>
      </Page>
    );
  }
}

export default AccessTokens;
