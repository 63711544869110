import { Form, Modal, Select, Tooltip } from 'antd';
import api from 'api';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from 'redux/GlobalSetting/globalSetting.actions';
import { getSiteInfo } from 'redux/Security/Security.actions';
import { TYPES } from 'utils/constant';
import useEntitlements from 'utils/hooks/useEntitlements';
import { defaultSorter } from 'utils/util';

const formOptions = {
  labelCol: { span: 6, xs: 24, sm: 6 },
  wrapperCol: { span: 18, xs: 24, sm: 18 },
};
const DEFAULT_MODAL_CONFIG = {
  centered: true,
  destroyOnClose: true,
  maskClosable: false,
};

const SiteLogConfigModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const regions = useSelector(state => state.globalSetting.regions);
  const { entitledLogModels } = useEntitlements();
  const formRef = useRef();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);

  useImperativeHandle(ref, () => ({
    showModal: id => {
      setId(id);
      setVisible(true);
    },
  }));

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  const regionOptions = useMemo(
    () =>
      regions.sort(defaultSorter('alias')).map((region, index) => {
        const disabled = !region.log_available || region.disabled;
        let message;
        if (region.disabled) {
          message = 'This region is not supported by your license.';
        }

        if (!region.log_available) {
          message = region.log_tips;
        }

        return (
          <Select.Option key={index} value={region.name} disabled={disabled}>
            <Tooltip title={disabled && message}>
              <span style={{ display: 'inline-block', width: '100%' }}>{region.alias}</span>
            </Tooltip>
          </Select.Option>
        );
      }),
    [regions]
  );

  const modelOptions = useMemo(
    () =>
      TYPES.map(item => ({ ...item, disabled: !entitledLogModels.includes(item.value) })).map(
        (type, index) => (
          <Select.Option key={index} value={type.value} disabled={type.disabled}>
            <Tooltip title={type.disabled && 'This type is not supported by your license.'}>
              <span style={{ display: 'inline-block', width: '100%' }}>{type.label}</span>
            </Tooltip>
          </Select.Option>
        )
      ),
    [entitledLogModels]
  );

  const enableLog = useCallback(
    values => {
      api
        .enableSiteLog(id, values)
        .then(res => {
          dispatch(getSiteInfo(id));
          setVisible(false);
        })
        .catch(err => {
          console.log({ err });
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [dispatch, id]
  );

  return (
    <Modal
      {...DEFAULT_MODAL_CONFIG}
      title="Analytics Configuration"
      visible={visible}
      okText={'Enable Analytics'}
      onOk={() => formRef.current.submit()}
      onCancel={() => setVisible(false)}
      loading={loading}
    >
      <Form ref={formRef} onFinish={enableLog} {...formOptions}>
        <Form.Item label="Region" name="region_name" key="region_name">
          <Select>{regionOptions}</Select>
        </Form.Item>

        <Form.Item label="Type" name="resource_model">
          <Select>{modelOptions}</Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});

SiteLogConfigModal.propTypes = {};

export default SiteLogConfigModal;
