import { notification as notify } from 'antd';
import _get from 'lodash/get';
import React from 'react';
import { addNotification } from 'redux/GlobalSetting/globalSetting.actions';
import { mappingData, updateLogStatus } from 'redux/Logs/logs.actions';
import { getSiteList, updateSiteStatus } from 'redux/Security/Security.actions';
import { getPath } from 'routeConfig';
import formatNotification from 'utils/notificationFormat';

import history from '@/history';

export const showNotification = (notification, options) => {
  const {
    data: { state_label },
  } = notification;

  const notifyTypeMap = {
    running: 'success',
    failed: 'error',
  };
  const notifyType = notifyTypeMap[state_label] || 'info';
  const { name, title, state, linkUrl, dateStr } = formatNotification(notification);

  notify[notifyType]({
    message: (
      <span className="link" onClick={() => linkUrl && history.push(linkUrl)}>
        {title}
      </span>
    ),
    description: `${name} ${state} at ${dateStr}`,
    duration: 10,
    ...options,
  });
};

const onNotification = (response, dispatch, getState) => {
  const parsed = JSON.parse(response);
  console.log('socket #######:', parsed);

  try {
    let allowPopup;

    const { sites } = getState().security;
    const { breakpoint } = getState().globalSetting;

    //TODO: distingish ui mode with a better way
    const isExpressMode = window.location.pathname === '/';

    // If currently on the same page as where the relevant resource is displayed, then don't show notification popup
    const pathname = window.location.pathname;
    switch (parsed.type) {
      case 'security':
        const dataId = _get(parsed, 'data.id');
        if (pathname === getPath('site-list')) {
          // If on the site list page, popup notification if the updated site is not on the current list page.
          allowPopup = !sites.find(item => item.id === dataId);
        } else {
          // If on the site detail page, popup notification if the updated site is not the current site.
          allowPopup = pathname !== getPath('site-detail', { id: dataId });
        }
        break;
      case 'analytics':
        allowPopup = pathname !== getPath('faz-config');
        break;
      default:
        allowPopup = true;
    }

    // only popup notification if CURRENT_TIME is within 2 minutes from _updated.
    const timeDifference = (Date.now() - Date.parse(_get(parsed, 'data._updated'))) / 1000 / 60;
    if (!isExpressMode && allowPopup && timeDifference && timeDifference < 2) {
      showNotification(parsed, {
        style: {
          width: 420,
          marginLeft: breakpoint === 'xs' ? 0 : 384 - 420,
          maxWidth: '96vw',
        },
      });
    }
  } catch (err) {
    console.log(err);
  }

  dispatch(addNotification(parsed));
  switch (parsed.type) {
    case 'security':
      if (parsed.need_refresh) {
        dispatch(getSiteList());
      } else {
        const { data } = parsed;
        dispatch(updateSiteStatus(data));
      }
      break;
    case 'analytics':
      const { timestamp } = getState().logs;
      if (timestamp > parsed.data.timestamp) {
        console.warn('analytics status is delayed from websocket !');
        return;
      }
      const logStatusData = mappingData(parsed.data);
      dispatch(updateLogStatus(logStatusData));
      break;
    default:
      break;
  }
};

export default onNotification;
