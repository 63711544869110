import { fetchWrap } from 'utils/fetch';

const resource = '/v1/setting/infra';

/**
 * @description get regions
 * @author Di Song <dsong@fortinet.com>
 * @date 2019-05-07
 */
function getRegions(...cb) {
  const showAllRegions = window.sessionStorage.getItem('show_all_regions');

  return fetchWrap(
    {
      url: `${resource}/regions${
        showAllRegions === 'true' ? `?show_all_regions=${showAllRegions}` : ''
      }`,
    },
    ...cb
  );
}

export default {
  getRegions,
};
