import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin, Table } from 'antd';
import api from 'api';
import jwtDecode from 'jwt-decode';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  fetchPortals,
  setAuthenticate,
  updateFortiOneAccounts,
  updateTenantUser,
} from 'redux/GlobalSetting/globalSetting.actions';
import { defaultRoute } from 'routeConfig';
import bgWrapper from 'utils/bgWrapper';
import storage from 'utils/storage';
import { getCookie, getTenantUser, logout, redirectForticare, saveToken } from 'utils/util';

import SubUserModal from './SubUserModal/SubUserModal';

const SSOAccountList = props => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const subUserModalRef = useRef();

  const impersonationToken = _get(queryString.parse(window.location.search), 'x-auth-token');
  if (impersonationToken) {
    document.cookie = `x-auth-token=${impersonationToken}`;
  }
  // web server will set x-auth-token to cookie when routing back to our portal
  const xAuthToken = useMemo(() => {
    // Check ou impersonation data.
    const queryParams = new URLSearchParams(location.search);
    const ouToken = queryParams.get('ouToken');
    if (ouToken) {
      sessionStorage.setItem(
        'ou_data',
        JSON.stringify({
          ouToken,
          ouAccountId: queryParams.get('ouAccountId'),
          ouUiMode: queryParams.get('ouUiMode'),
        })
      );

      return ouToken;
    }
    return getCookie('x-auth-token');
  }, []);

  useEffect(() => {
    dispatch(setAuthenticate(false));
    ['access_token', 'refresh_token'].forEach(key => storage.rm(key));
    xAuthToken && dispatch(fetchPortals(xAuthToken));
  }, [dispatch, xAuthToken]);

  useEffect(() => {
    if (!xAuthToken) {
      logout();
    } else {
      try {
        setUser(jwtDecode(xAuthToken));
      } catch (error) {
        setLoading(false);
      }
    }
  }, [xAuthToken]);

  const enterPortal = useCallback(() => {
    const { state } = location;
    let redircetPath = defaultRoute;
    if (state && state.from.pathname === 'sso-login') {
      redircetPath = state.from.pathname;
    }
    dispatch(setAuthenticate(true));
    history.push(redircetPath);
  }, [dispatch, history, location]);

  const showWarning = useCallback(({ title, content }) => {
    const configs = {
      title,
      content,
      closable: false,
      maskClosable: false,
      okText: 'Logout',
      centered: true,
      onOk: () => logout(),
    };
    Modal.confirm({ ...configs });
  }, []);

  const afterLogin = useCallback(
    tokenObj => {
      saveToken(tokenObj);
      const tenantUser = getTenantUser();
      const account_entitlement = _get(
        tenantUser,
        'tenant.company.license.account_entitlement.availability',
        false
      );

      // check if the tenant is entitled
      if (!account_entitlement) {
        const fortiCare = storage
          .get('globalSetting')
          ?.portals?.apps?.support_menu_items?.find(({ item_id }) => item_id === 10105); // find the "Create a Ticket" item from the FortiCare portal list

        const [fortiCareURL] = fortiCare?.url?.match(/https:\/\/support.*.fortinet.com/); // extract the origin
        const createTicketURL = `${
          fortiCareURL ?? 'https://support.fortinet.com' // set the fallback URL to FortiCare prod env
        }/Ticket/CreateTicket.aspx`; // compose the URL for the FortiCare "create a ticket" page to skip the login precess

        showWarning({
          title: 'Missing entitlements',
          content: (
            <span>
              Thank you for your interest in FortiSASE. Your FortiCloud account does not have
              entitlement for this service. If you feel this is in error,&nbsp;
              <a href={createTicketURL} target="blank">
                contact FortiCare Support
              </a>
              .
            </span>
          ),
        });
      }
      // check if the user has a role to access SASE services
      else if (!tenantUser.role || tenantUser.role.length === 0) {
        showWarning({
          title: 'No role assigned',
          content: 'You are assigned no role, please contact admin to assign you role.',
        });
      } else {
        enterPortal();
      }
      dispatch(updateTenantUser(tenantUser));
    },
    [dispatch, showWarning, enterPortal]
  );

  const login = useCallback(
    record => {
      const userData = record;
      const api_login = userData.iam_support === 'True' ? api.etacLogin : api.ssoLogin;
      setLoading(true);
      xAuthToken &&
        api_login(xAuthToken, userData)
          .then(({ data: resData }) => {
            if (resData.data.user_auth_url) {
              window.location.href = resData.data.user_auth_url;
              return;
            }
            afterLogin(resData.data);
          })
          .catch(error => {
            const errorCode = error?.response?.data?.code;
            if (
              errorCode === 403 &&
              error?.response?.data?.error?.type === 'IAM_USER_UNAUTHORIZED'
            ) {
              Modal.confirm({
                title: 'Unauthorized',
                content: (
                  <div>
                    <span>This IAM user is unauthorized.</span>
                    <br />
                    <span>Please follow the steps below to resolve the issue: </span>
                    <br />
                    <ol>
                      <li>
                        Verify your email address on FortiCare.
                        <br />
                        <b>Note</b>: If verifying your email address resolves the issue, you do not
                        need to take any further actions.
                      </li>
                      <li>Check your IAM user 2FA settings on FortiCare.</li>
                      <li>
                        Contact your account admin to verify your IAM user & user group
                        configuration.
                      </li>
                      <li>
                        If still having issues, contacting FortiCare support for further help.
                      </li>
                    </ol>
                  </div>
                ),
                centered: true,
                okText: 'FortiCare',
                onOk: () => redirectForticare(),
                cancelText: 'Logout',
                onCancel: () => logout(),
              });
            } else if (errorCode === 403 && error.response.data.error?.type) {
              Modal.confirm({
                title: 'Unauthorized',
                content: error.response.data.error.message,
                centered: true,
                okText: 'FortiCare',
                onOk: () => redirectForticare(),
                cancelText: 'Logout',
                onCancel: () => logout(),
              });
            } else if (errorCode === 403) {
              Modal.confirm({
                title: 'No Permission',
                content: 'This IAM user has no permission to visit FortiSASE portal.',
                centered: true,
                okText: 'Logout',
                onOk: () => logout(),
              });
            }
            if (errorCode === 404) {
              Modal.confirm({
                title: 'No tenant existed',
                content: `This Account ID/Email you've entered has no tenant.`,
                centered: true,
                okText: 'Logout',
                onOk: () => logout(),
              });
            }
            if (errorCode === 409) {
              Modal.confirm({
                title: 'This account already exists',
                content: `There's already an account set up to use ${_get(
                  user,
                  'principal'
                )}, please contact the administrator.`,
                centered: true,
                okText: 'Logout',
                onOk: () => logout(),
              });
            }
          })
          .finally(() => setLoading(false));
    },
    [afterLogin, user, xAuthToken]
  );

  const getAccountList = useCallback(() => {
    if (_isEmpty(user)) {
      return;
    }

    if (_get(user, 'auth_type') === 'iam') {
      const {
        iam_account_name,
        iam_user_name,
        authentication_status,
        nameID,
        iam_account_alias,
        iam_support_root,
        iam_support,
      } = user;
      if (iam_account_name && iam_user_name) {
        const iamUser = {
          auth_type: 'iam',
          nameID,
          authentication_status,
          iam_account_alias,
          iam_account_name,
          iam_user_name,
          tenant: {
            account_id: iam_account_name,
            root_principal: iam_account_name,
          },
          name: iam_user_name,
          root: false,
          principal: iam_user_name,
          iam_support_root,
          iam_support,
          company: {
            name: '',
          },
        };
        const accounts = [iamUser];
        setAccounts(accounts);
        dispatch(updateFortiOneAccounts(accounts));
        xAuthToken && login(iamUser);
      }
    } else if (_get(user, 'auth_type') === 'external') {
      const { idp_user_id, idp_name, idp_user_roles, authentication_status, nameID } = user;
      let is_multi_role = false;

      if (idp_user_roles && idp_user_id) {
        const externalUser = {
          auth_type: 'external',
          nameID,
          authentication_status,
          idp_user_id,
          idp_name,
          idp_user_roles,
          tenant: {
            account_id: idp_user_id, // no real account_id for external_id in saml response
            root_principal: idp_user_id,
          },
          name: idp_user_id,
          root: false,
          principal: idp_user_id,
          company: {
            name: '',
          },
        };
        if (Array.isArray(idp_user_roles)) {
          if (idp_user_roles.length > 1) {
            is_multi_role = true;
          }
        } else {
          if (idp_user_roles.split(',').length > 1) {
            is_multi_role = true;
          }
        }

        const accounts = [externalUser];
        setAccounts(accounts);
        dispatch(updateFortiOneAccounts(accounts));

        if (!is_multi_role) {
          xAuthToken && login(externalUser);
        } else {
          // Handle selection for multiple idp_roles
          // create multiple external accounts with different roles
          const duplicatedAccounts = [];

          accounts.forEach(externalUser => {
            if (Array.isArray(externalUser.idp_user_roles)) {
              externalUser.idp_user_roles.forEach(role => {
                const duplicatedUser = { ...externalUser };
                duplicatedUser.idp_user_roles = role; // Assign single role
                duplicatedAccounts.push(duplicatedUser);
              });
            } else if (typeof externalUser.idp_user_roles === 'string') {
              externalUser.idp_user_roles.split(',').forEach(role => {
                const duplicatedUser = { ...externalUser };
                duplicatedUser.idp_user_roles = role; // Assign single role
                duplicatedAccounts.push(duplicatedUser);
              });
            }
          });
          setAccounts(duplicatedAccounts);
          dispatch(updateFortiOneAccounts(duplicatedAccounts));
          setLoading(false);
        }
      }
    } else {
      const principal = _get(user, 'principal');
      // h_key will be used to interact with FortiOne APIs
      let hKey = {};
      try {
        hKey = JSON.parse(getCookie('h_key'));
      } catch (e) {
        console.log('empty h_key');
      }
      xAuthToken &&
        api
          .getFortinetOneAccounts(principal, {
            ...hKey,
            auth_token: xAuthToken,
          })
          .then(({ data: resData }) => {
            setAccounts(resData.data);
            dispatch(updateFortiOneAccounts(resData.data));

            // if account list has only one user, login directly and do not show switch user button in Users page
            if (resData.data.length === 1) {
              login(resData.data[0]);
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response.data.code === 404) {
              Modal.warning({
                title: 'Unregistered Account',
                content: `This Account ID/Email you've entered doesn't match any account, please sign up for an account.`,
                okText: 'Logout',
                centered: true,
                onOk: () => logout(),
              });
            } else {
              logout();
            }
          })
          .finally(() => setLoading(false));
    }
  }, [dispatch, login, user, xAuthToken]);

  useEffect(() => {
    try {
      getAccountList();
    } catch (error) {
      Modal.warning({
        title: 'FortiCloud connect error',
        content: 'Connect FortiCloud timeout, try to refresh page',
        okText: 'Logout',
        onOk: () => (window.location.href = '/login'),
      });
    }
  }, [getAccountList]);

  const handleRowClick = record => {
    if (!record.root && record.auth_type !== 'external') {
      subUserModalRef.current.showModal(record);
    } else {
      login(record);
    }
  };

  return (
    <div
      style={{
        margin: 'auto',
        width: 960,
        maxWidth: '90vw',
        backgroundColor: '#fff',
        padding: 14,
        borderRadius: 8,
        zIndex: 5,
      }}
    >
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
        style={{ width: '100%' }}
      >
        <Table
          rowKey={(record, index) => record.tenant.account_id + index}
          onRow={record => ({
            onClick: () => handleRowClick(record),
          })}
          dataSource={accounts}
          scroll={{ y: 640, x: 960 }}
          pagination={false}
          data-testid="account-list"
        >
          <Table.Column
            title="System ID"
            dataIndex={['tenant', 'account_id']}
            width={200}
            ellipsis={true}
          />
          <Table.Column
            title="Primary Account"
            dataIndex={['tenant', 'root_principal']}
            width={230}
            ellipsis={true}
          />
          {accounts.length > 0 && accounts[0].auth_type === 'external' ? (
            <Table.Column
              title="IDP User Role"
              dataIndex={'idp_user_roles'}
              width={200}
              ellipsis={true}
            />
          ) : (
            <Table.Column title="Name" dataIndex={'name'} width={150} ellipsis={true} />
          )}
          <Table.Column
            title="Company"
            dataIndex={['company', 'name']}
            width={150}
            ellipsis={true}
          />
        </Table>
      </Spin>
      <SubUserModal ref={subUserModalRef} loginWithSSO={login} />
    </div>
  );
};

SSOAccountList.propTypes = {};

export default bgWrapper(SSOAccountList);
