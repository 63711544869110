import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCookie } from 'utils/util';

const PRODUCT_NAME = 'FortiSASE';

const useHKey = props => {
  const tenantUser = useSelector(state => state.globalSetting.user);

  const visitedSites = useMemo(() => {
    const currSite = {
      app_name: PRODUCT_NAME,
      last_url: window.location.protocol + '//' + window.location.host + '/saml/login',
    };
    try {
      const h_key = JSON.parse(getCookie('h_key'));
      const visited_sites = _get(h_key, 'visited_sites');
      if (!visited_sites || visited_sites.length === 0) {
        return [currSite];
      } else {
        const currSiteIndex = visited_sites.findIndex(site => site.app_name === PRODUCT_NAME);
        currSiteIndex > -1
          ? (visited_sites[currSiteIndex].last_url = currSite.last_url)
          : visited_sites.push(currSite);
        return visited_sites;
      }
    } catch (e) {
      // console.log('empty h_key');
      return [currSite];
    }
  }, []);

  let hKey = {
    source_app: PRODUCT_NAME,
    account_id: _get(tenantUser, 'tenant.account_id'),
    user_id: _get(tenantUser, 'third_party_data.user_id'),
    user_fullaccess: _get(tenantUser, 'third_party_data.user_fullaccess'),
    visited_sites: visitedSites,
  };

  try {
    const h_key = JSON.parse(getCookie('h_key'));
    hKey = {
      ...hKey,
      account_id: _get(h_key, 'account_id'),
      user_id: _get(h_key, 'user_id'),
      user_fullaccess: _get(h_key, 'user_fullaccess'),
    };
  } catch (e) {
    // console.log('empty h_key');
  }

  const storeHKey = useCallback(() => {
    sessionStorage.setItem('h_key', JSON.stringify(hKey));
  }, [hKey]);
  return { hKey, storeHKey };
};

useHKey.propTypes = {};

export default useHKey;
