import './Users.module.less';

import { Alert, Button, Divider, Form, Input, Select, Space } from 'antd';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { validateMessages } from 'utils/util';

import { EDGE_ADMIN, isProvisionProcessing, SITE_ADMIN, SUPER_ADMIN, VIEWER } from './user.service';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

const EditUserProfileForm = ({ onSubmit, onCancel, data, roles, permissions, sites }) => {
  const formRef = useRef();
  const [pending, setPending] = useState(false);
  const { allowEditProfile, allowAssignSite, allowAssignRole } = permissions;
  const isProvisionPending = isProvisionProcessing(data);
  const allowSubmit =
    !isProvisionPending && (allowEditProfile || allowAssignRole || allowAssignSite);
  const initialValues = useMemo(() => {
    let sitesValue;
    const role = _get(data, 'role')[0];
    switch (role) {
      case SUPER_ADMIN:
        sitesValue = '*';
        break;
      case EDGE_ADMIN:
        sitesValue = _get(data, 'sites', []).map(({ id }) => id);
        break;
      case SITE_ADMIN:
        sitesValue = _get(data, 'sites[0].id', null);
        break;
      case VIEWER:
      default:
        sitesValue = '';
    }
    return {
      root: _get(data, 'tenant.root_principal'),
      principal: _get(data, 'principal'),
      name: _get(data, 'name'),
      email: _get(data, 'email'),
      role,
      sites: sitesValue,
    };
  }, [data]);
  const [role, setRole] = useState(initialValues.role);

  const siteOptions = useMemo(
    () =>
      sites.map(item => (
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      )),
    [sites]
  );

  const onRoleChange = useCallback(value => {
    const { setFieldsValue } = formRef.current;
    setRole(value);
    switch (value) {
      case SUPER_ADMIN:
        setFieldsValue({ sites: '*' });
        break;
      case EDGE_ADMIN:
        setFieldsValue({ sites: [] });
        break;
      case SITE_ADMIN:
        setFieldsValue({ sites: null });
        break;
      case VIEWER:
      default:
        setFieldsValue({ sites: '' });
    }
  }, []);

  const selectAllSites = useCallback(() => {
    const { setFieldsValue } = formRef.current;
    setFieldsValue({ sites: sites.map(({ id }) => id) });
  }, [sites]);

  const siteField = useMemo(() => {
    let fieldName = 'sites';
    switch (role) {
      case SUPER_ADMIN:
        return (
          <Form.Item
            name={fieldName}
            label="Site"
            key={fieldName}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select disabled>
              <Select.Option value={'*'}>All</Select.Option>
            </Select>
          </Form.Item>
        );
      case EDGE_ADMIN:
        return (
          <Form.Item
            name={fieldName}
            label="Site(s)"
            key={fieldName}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              mode="tags"
              allowClear
              disabled={!allowAssignSite || isProvisionPending || pending}
              placeholder="Please select sites"
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div className="ant-select-item" styleName="select-all-item">
                    <div
                      style={{
                        flex: 'auto',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      onClick={selectAllSites}
                    >
                      Select All
                    </div>
                  </div>
                </div>
              )}
            >
              {siteOptions}
            </Select>
          </Form.Item>
        );
      case SITE_ADMIN:
        return (
          <Form.Item
            name={fieldName}
            label="Site"
            key={fieldName}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              disabled={!allowAssignSite || isProvisionPending || pending}
              placeholder="Please select a site"
            >
              {siteOptions}
            </Select>
          </Form.Item>
        );
      case VIEWER:
      default:
        return (
          <Form.Item name={fieldName} label="Site" key={fieldName}>
            <Select disabled>
              <Select.Option value={''}>None</Select.Option>
            </Select>
          </Form.Item>
        );
    }
  }, [allowAssignSite, isProvisionPending, pending, role, selectAllSites, siteOptions]);

  const handleSubmit = useCallback(
    values => {
      setPending(true);
      onSubmit(values).finally(() => setPending(false));
    },
    [onSubmit]
  );

  return (
    <Form
      ref={formRef}
      {...formItemLayout}
      onFinish={handleSubmit}
      initialValues={initialValues}
      validateMessages={validateMessages}
    >
      <Form.Item name="root" label="Primary Account" key="rootPrincipal">
        <Input disabled />
      </Form.Item>

      <Form.Item name="principal" label="Principal" key="principal">
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="name"
        label="Name"
        key="userName"
        validateTrigger="onBlur"
        rules={
          allowEditProfile && [
            {
              required: true,
            },
          ]
        }
      >
        <Input disabled={!allowEditProfile || isProvisionPending || pending} />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        key="email"
        validateTrigger="onBlur"
        rules={
          allowEditProfile && [
            {
              required: true,
            },
            {
              type: 'email',
            },
          ]
        }
      >
        <Input disabled={!allowEditProfile || isProvisionPending || pending} />
      </Form.Item>

      <Form.Item
        name="role"
        label="Role"
        key="role"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          disabled={!allowAssignRole || isProvisionPending || pending}
          placeholder="Select a role"
          onChange={onRoleChange}
        >
          {' '}
          {roles.map(role => (
            <Select.Option key={role.name}>{role.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      {siteField}

      <Form.Item {...tailLayout}>
        <Space direction="vertical">
          {isProvisionPending && (
            <Alert
              message="The user provisioning progress is ongoing. Please wait until the process is completed."
              type="warning"
            />
          )}

          <Space>
            <Button type="primary" loading={pending} disabled={!allowSubmit} htmlType="submit">
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </Space>
        </Space>
      </Form.Item>
    </Form>
  );
};

EditUserProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.object,
  roles: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default EditUserProfileForm;
