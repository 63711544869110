import './Analytics.module.less';

import { Spin, Tooltip } from 'antd';
import api from 'api';
import Exception from 'components/Exception';
import Page from 'components/Page';
import SvgIcon from 'components/SvgIcon';
import _get from 'lodash/get';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { from, timer } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { DOMAIN } from 'utils/constant';
import { RESOURCE_STATUS } from 'utils/constant';

const mapStateToProps = state => ({
  tenantUser: state.globalSetting.user,
  logsStatus: state.logs.logsStatus,
  resourceStatus: state.logs.resourceStatus,
});
const LOGOUT_TIMEOUT = 1200;

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      logoutUrl: null,
      tabLogoutUrl: null,
      tabSetCookieUrl: null,
      loading: true,
      iFrameLoadError: false,
      domainSetError: false,
      resourceStatusLoading: false,
    };
  }

  componentDidMount() {
    const { resourceStatus } = this.props;

    this.setDomain();

    if (resourceStatus === null) {
      this.setState({ resourceStatusLoading: true });
    } else if (!RESOURCE_STATUS.isRunning(resourceStatus)) {
      this.props.history.push('/settings/analytics');
    } else {
      this.accessFaz();
    }
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('componentWillReceiveProps', this.props.resourceStatus, nextProps.resourceStatus);
    if (this.props.resourceStatus === null && nextProps.resourceStatus !== null) {
      if (RESOURCE_STATUS.isRunning(nextProps.resourceStatus)) {
        this.setState({ resourceStatusLoading: false });
        !this.state.url && this.accessFaz();
      } else {
        this.props.history.push('/settings/analytics');
      }
    }
  }

  accessFaz() {
    // +-------- getFazUrls ------------->
    // +-------- delay ------------------>
    // +-------- getFazCookies ---------->
    this.getFazUrls()
      .pipe(
        // logout FGT
        tap(({ logout_url }) => this.setState({ logoutUrl: logout_url })),
        switchMap(() => this.getFazCookies()),
        delay(LOGOUT_TIMEOUT),
        // set cookies to browser
        tap(cookies => {
          const { urls } = this.state;
          const setCookieUrl = this.getCookieUrl(urls.set_cookie_url, cookies);
          this.setState({ url: setCookieUrl });
        }),
        delay(LOGOUT_TIMEOUT)
      )
      .subscribe(
        () => {
          const { urls } = this.state;

          this.setState({ loading: false, url: urls.url });
        },
        err => this.setState({ iFrameLoadError: true, loading: false })
      );
  }

  getFazUrls() {
    return from(api.getFazUrls()).pipe(
      map(res => _get(res, 'data.data', {})),
      tap(urls => this.setState({ urls }))
    );
  }

  getFazCookies() {
    return from(api.getFazCookies()).pipe(map(res => _get(res, 'data.data.cookies', {})));
  }

  setDomain() {
    try {
      document.domain = DOMAIN;
    } catch (err) {
      console.log(err);
      this.setState({ loading: false, domainSetError: true });
    }
  }

  getCookieUrl(url, cookies) {
    const query = {};
    for (let key in cookies) {
      query[key] = btoa(cookies[key]);
    }
    return `${url}?${queryString.stringify(query)}`;
  }

  openTab = () => {
    const { urls } = this.state;
    window.open(_get(window, 'frames[0].location', urls.tab_url));

    // NOTE: re-login will cause FAZ lost connection in 6.4
    // this.setState({ loading: true, tabLogoutUrl: urls.tab_logout_url });
    // try {
    //   timer(LOGOUT_TIMEOUT)
    //     .pipe(
    //       switchMap(() => this.getFazCookies()),
    //       tap((cookies) => {
    //         const tabSetCookieUrl = this.getCookieUrl(urls.tab_set_cookie_url, cookies);
    //         this.setState({ tabSetCookieUrl });
    //       }),
    //       delay(LOGOUT_TIMEOUT)
    //     )
    //     .subscribe(() => {
    //       this.setState({ loading: false, tabLogoutUrl: null });

    //       window.open(_get(window, 'frames[0].location', urls.tab_url));
    //     });
    // } catch (err) {
    //   console.log(err);
    // }
  };

  render() {
    const {
      url,
      logoutUrl,
      tabLogoutUrl,
      tabSetCookieUrl,
      iFrameLoadError,
      loading,
      domainSetError,
      resourceStatusLoading,
    } = this.state;

    return (
      <Page>
        <Page.Header
          extra={
            <Tooltip placement="left" title="Open in a new Tab">
              {!loading && <SvgIcon type="expand" onClick={this.openTab} />}
            </Tooltip>
          }
        />

        {resourceStatusLoading && (
          <section className="center" style={{ width: 170, height: 50 }}>
            <Spin tip="loading resource status...." />
          </section>
        )}

        {!resourceStatusLoading && (
          <section className="flex-col flex-item pos-relative">
            <Spin spinning={loading} styleName="spinner" />
            {logoutUrl && <img alt="logout" style={{ display: 'none' }} src={logoutUrl} />}
            {tabLogoutUrl && <img alt="tabLogout" style={{ display: 'none' }} src={tabLogoutUrl} />}

            {url && (
              <iframe
                id="analyticsFrame"
                title="analytics"
                src={url}
                height="100%"
                width="100%"
                frameBorder="0"
              />
            )}

            {
              // setting cookie for accessing FAZ GUI in tab
              tabSetCookieUrl && (
                <iframe title="tab" style={{ display: 'none' }} src={tabSetCookieUrl} />
              )
            }

            {(iFrameLoadError || domainSetError) && <Exception />}
          </section>
        )}
      </Page>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Analytics));
