import './FederatedSitesConfig.module.less';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import React, {
  createRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import shortid from 'shortid';
import { DEFAULT_SLAVES_QUOTA } from 'utils/constant';

import SiteConfigField from './SiteConfigField';

const FederatedSitesConfig = forwardRef((props, ref) => {
  const { cloudRegions, quota, onChange, seats } = props;

  // used to control if a slave item is removable
  // for creating turbo site, the minimum is 1
  const minLength = _isNumber(props.minLength) ? props.minLength : 0;

  const defaultItems = _get(props, 'defaultItems', []).map(el => ({
    ...el,
    key: shortid.generate(),
  })); //
  const [items, setItems] = useState(defaultItems);

  const itemRefs = useMemo(() => items.map(() => createRef()), [items]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      return Promise.all(itemRefs.map(item => item.current.validateFields()));
    },
    getValues: () => {
      return itemRefs.map(el => el.current.getFieldsValue());
    },
  }));

  // remove each item's key, which is used internally
  const lintItems = useCallback(arr => arr.map(({ region, count }) => ({ region, count })), []);

  // add new slave item row
  const addItem = useCallback(() => {
    if (!seats > 0) {
      Modal.warning({
        centered: true,
        title: 'Site Limit Reached',
        content:
          'You have reached the Maximum Site Limit in your license. To create more sites, please upgrade your license.',
      });
      return;
    }

    const newItem = {
      key: shortid.generate(),
      region: undefined,
      count: 1,
      ipsecgateway_enable: false,
    };
    const result = [...items, newItem];
    setItems(result);
    onChange(lintItems(result));
  }, [items, lintItems, onChange, seats]);

  // remove a slave item by index
  const removeItem = useCallback(
    index => {
      const result = [...items];
      result.splice(index, 1);
      setItems(result);
      onChange(lintItems(result));
    },
    [items, lintItems, onChange]
  );

  // update a slave item by index
  const handleItemChange = useCallback(
    (index, data) => {
      const result = [...items];
      result.splice(index, 1, data);
      setItems(result);
      onChange(lintItems(result));
    },
    [items, lintItems, onChange]
  );

  return (
    <div styleName="container">
      {items.map((el, index) => (
        <SiteConfigField
          ref={itemRefs[index]}
          key={el.key}
          data={el}
          cloudRegions={cloudRegions}
          onChange={values => handleItemChange(index, values)}
          removable={items.length > minLength}
          onRemove={() => removeItem(index)}
          quota={_isNumber(quota) ? quota : DEFAULT_SLAVES_QUOTA}
        />
      ))}
      <div>
        <Button size="small" icon={<PlusOutlined />} onClick={addItem} disabled={quota < 1}>
          Add
        </Button>
      </div>
    </div>
  );
});

FederatedSitesConfig.propTypes = {
  defaultItems: PropTypes.array,
  cloudRegions: PropTypes.array.isRequired,
  quota: PropTypes.number,
  seats: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  minLength: PropTypes.number,
};

export default FederatedSitesConfig;
