import './Logs.module.less';
import '@ant-design/compatible/assets/index.css';

import { Form } from '@ant-design/compatible';
import { LoadingOutlined } from '@ant-design/icons';
import { Badge, Button, Select, Switch, Tooltip } from 'antd';
import FailureDetail from 'components/FailureDetail';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { RESOURCE_STATUS } from 'utils/constant';
import { defaultSorter } from 'utils/util';

const formOptions = {
  labelCol: { span: 5, xs: 24, sm: 5 },
  wrapperCol: { span: 19, xs: 24, sm: 19 },
};

// This is a dumb component that only renders the form
const LogConfigForm = Form.create({})(
  ({ logStatusData, regionOptions, modelOptions, onSubmit, onRecreate, form }) => {
    const [enableLog, setEnableLog] = useState(false);

    const { resourceStatus, logsStatus, regionName, type, resourceState } = logStatusData;
    const { getFieldDecorator } = form;

    const isPending = RESOURCE_STATUS.isPending(resourceStatus);
    const isFailed = RESOURCE_STATUS.isFailed(resourceStatus);
    const isInitStatus = !resourceStatus;

    const badgeStatus = useMemo(() => {
      switch (resourceStatus) {
        case RESOURCE_STATUS.RUNNING:
          return 'success';
        case RESOURCE_STATUS.PENDING:
          return 'processing';
        case RESOURCE_STATUS.FAILED:
          return 'error';
        default:
          return 'default';
      }
    }, [resourceStatus]);

    const isEditable = useMemo(() => {
      return !isInitStatus && !isPending && logStatusData.isEditable;
    }, [isInitStatus, isPending, logStatusData.isEditable]);

    useMemo(() => {
      // update enableLog value if the status updated
      setEnableLog(logStatusData.logsStatus);
    }, [logStatusData.logsStatus]);

    const renderStatus = useCallback(
      (resourceStatus, resourceState) => {
        return isPending && resourceState !== 'running' ? (
          <Fragment>
            <LoadingOutlined spin />{' '}
            {resourceState ? resourceState.replace(/-/g, ' ') : resourceStatus}
          </Fragment>
        ) : (
          <Fragment>
            <Badge status={badgeStatus} text={resourceStatus} />
            {isFailed && (
              <FailureDetail data={logStatusData} onRecreate={onRecreate}></FailureDetail>
            )}
          </Fragment>
        );
      },
      [isPending, badgeStatus, isFailed, logStatusData, onRecreate]
    );

    const handleConfirm = useCallback(() => {
      form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);

          onSubmit(values);
        }
      });
    }, [form, onSubmit]);

    return (
      <Form {...formOptions} styleName="log-form">
        <Form.Item label="Status" className="capitalize">
          {isInitStatus ? (
            <span>
              <LoadingOutlined spin /> loading
            </span>
          ) : (
            renderStatus(resourceStatus, resourceState)
          )}
        </Form.Item>

        <Form.Item label="Enable">
          {getFieldDecorator('toggleLogs', {
            initialValue: logsStatus,
            valuePropName: 'checked',
          })(
            <Switch
              disabled={isPending || isInitStatus}
              onChange={checked => setEnableLog(checked)}
            />
          )}
        </Form.Item>

        {enableLog && (
          <Fragment>
            <Form.Item label="Regions">
              {getFieldDecorator('regionName', {
                initialValue: regionName,
                rules: [
                  {
                    required: true,
                    message: 'Please Select a Region',
                  },
                ],
              })(
                <Select style={{ width: 220 }} disabled={!isEditable}>
                  {[...regionOptions].sort(defaultSorter('alias')).map((region, index) => {
                    const disabled = !region.log_available || region.disabled;
                    let message;
                    if (region.disabled) {
                      message = 'This region is not supported by your license.';
                    }

                    if (!region.log_available) {
                      message = region.log_tips;
                    }

                    return (
                      <Select.Option key={index} value={region.name} disabled={disabled}>
                        <Tooltip title={disabled && message}>
                          <span style={{ display: 'inline-block', width: '100%' }}>
                            {region.alias}
                          </span>
                        </Tooltip>
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Type">
              {getFieldDecorator('type', {
                initialValue: type,
                rules: [
                  {
                    required: true,
                    message: 'Please Select a Type',
                  },
                ],
              })(
                <Select style={{ width: 220 }} disabled={!isEditable}>
                  {modelOptions.map((type, index) => (
                    <Select.Option key={index} value={type.value} disabled={type.disabled}>
                      <Tooltip
                        title={type.disabled && 'This type is not supported by your license.'}
                      >
                        <span style={{ display: 'inline-block', width: '100%' }}>{type.label}</span>
                      </Tooltip>
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Fragment>
        )}

        <Form.Item
          styleName="confirm-btn"
          wrapperCol={{
            offset: 5,
            span: 19,
            xs: { offset: 0, span: 24 },
            sm: { offset: 5, span: 19 },
          }}
        >
          <Button
            type="primary"
            disabled={isPending}
            loading={isInitStatus}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Form.Item>
      </Form>
    );
  }
);

LogConfigForm.propTypes = {
  logStatusData: PropTypes.object,
  regionOptions: PropTypes.array,
  modelOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onRecreate: PropTypes.func.isRequired,
};

export default LogConfigForm;
