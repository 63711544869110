import storage from 'utils/storage';

import {
  ADD_NOTITICATION,
  ADD_SYSTEM_INFO,
  CHANGE_THEME,
  CLEAR_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS,
  READ_NOTIFICATION,
  REMOVE_SYSTEM_INFO,
  SET_AUTHENTICATE,
  SET_TEMP_UI_MODE,
  TOGGLE_HEADER,
  TOGGLE_SIDER_COLLAPSE,
  UPDATE_AUDIT_LOG_OPTIONS,
  UPDATE_BREAKPOINT,
  UPDATE_FORTIONE_ACCOUNTS,
  UPDATE_PORTALS,
  UPDATE_REGIONS,
  UPDATE_SITE_LIST_OPTIONS,
  UPDATE_SYSTEM_INFO,
  UPDATE_TENANT_USER,
  UPDATE_VERSION,
} from './globalSetting.actions';

const getDefaultState = () => ({
  theme: 'dark',
  isHeaderHide: true,
  isAuthenticated: false,
  breakpoint: '',
  siderCollapsed: true,
  user: {},
  version: '',
  fortiOneAccounts: [],
  portals: {},
  notifications: [],
  sysInfo: [],
  siteListOptions: {
    siteListViewMode: 'card',
    autoReload: false,
    interval: 10,
  },
  regions: [],
  ...storage.get('globalSetting'),
});

const storeSettings = ({
  theme,
  isHeaderHide,
  isAuthenticated,
  portals,
  user,
  siteListOptions,
  auditLogOptions,
  fortiOneAccounts,
  version,
  notifications,
  sysInfo,
}) => {
  storage.set('globalSetting', {
    theme,
    isHeaderHide,
    isAuthenticated,
    portals,
    user,
    siteListOptions,
    auditLogOptions,
    fortiOneAccounts,
    version,
    notifications,
    sysInfo,
  });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CHANGE_THEME:
    case TOGGLE_HEADER:
    case UPDATE_BREAKPOINT:
    case SET_AUTHENTICATE:
    case UPDATE_PORTALS:
    case UPDATE_VERSION:
    case UPDATE_TENANT_USER:
    case TOGGLE_SIDER_COLLAPSE:
    case UPDATE_FORTIONE_ACCOUNTS:
    case UPDATE_SITE_LIST_OPTIONS:
    case UPDATE_AUDIT_LOG_OPTIONS:
    case SET_TEMP_UI_MODE:
    case UPDATE_REGIONS: {
      const newState = {
        ...state,
        ...action.payload,
      };
      storeSettings(newState);
      return newState;
    }
    case ADD_SYSTEM_INFO: {
      const { payload } = action;
      const newState = {
        ...state,
        sysInfo: state.sysInfo.find(({ id }) => id === payload.id)
          ? state.sysInfo.map(info => (info.id === payload.id ? payload : info))
          : [...state.sysInfo, payload],
      };
      storeSettings(newState);
      return newState;
    }
    case REMOVE_SYSTEM_INFO: {
      const { payload } = action;
      const newState = {
        ...state,
        sysInfo: state.sysInfo.filter(info => info.id !== payload.id),
      };
      storeSettings(newState);
      return newState;
    }
    case UPDATE_SYSTEM_INFO: {
      const { payload } = action;
      const newState = {
        ...state,
        sysInfo: state.sysInfo.map(info => (info.id === payload.id ? payload : info)),
      };
      storeSettings(newState);
      return newState;
    }
    case ADD_NOTITICATION: {
      const newState = {
        ...state,
        notifications: [action.payload.notification, ...state.notifications],
      };
      storeSettings(newState);
      return newState;
    }
    case CLEAR_NOTIFICATIONS: {
      const newState = {
        ...state,
        notifications: [],
      };
      storeSettings(newState);
      return newState;
    }
    case READ_ALL_NOTIFICATIONS: {
      const newState = {
        ...state,
        notifications: state.notifications.map(item => ({ ...item, read: true })),
      };
      storeSettings(newState);
      return newState;
    }
    case READ_NOTIFICATION: {
      const newState = {
        ...state,
        notifications: state.notifications.map((item, index) =>
          action.payload.notifiIndex === index ? { ...item, read: true } : item
        ),
      };
      storeSettings(newState);
      return newState;
    }
    default:
      return state;
  }
};
