import './Logs.module.less';

import { Modal } from 'antd';
import Page from 'components/Page';
import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getRegions } from 'redux/GlobalSetting/globalSetting.actions';
import {
  createLog,
  disableLog,
  getLogInfo,
  recreateLog,
  updateLogStatus,
} from 'redux/Logs/logs.actions';
import { defaultRoute } from 'routeConfig';
import { RESOURCE_STATUS, TYPES } from 'utils/constant';
import usePermissions from 'utils/hooks/usePermissions';
import useUserRole from 'utils/hooks/useUserRole';

import LogConfigForm from './LogConfigForm';

const mapStateToProps = state => ({
  logStatusData: state.logs,
  tenantUser: state.globalSetting.user,
  regions: state.globalSetting.regions,
});

const mapDispatchToProps = dispatch => ({
  updateLogStatus: logStatusData => dispatch(updateLogStatus(logStatusData)),
  getLogInfo: () => dispatch(getLogInfo()),
  createLog: data => dispatch(createLog(data)),
  recreateLog: () => dispatch(recreateLog()),
  disableLog: () => dispatch(disableLog()),
  getRegions: () => dispatch(getRegions()),
});

/**
 * This is a container component which will do the following:
 * 1. check the tenant role. If the role is unmatched, it will do redirection
 * 2. get available FAZ models from the tenant's license
 * 3. fetch and filter available regions with the tenant's license
 * 4. handle submission
 */
const Logs = props => {
  const { tenantUser, regions, history, logStatusData, getRegions } = props;
  const role = useUserRole();
  const permissions = usePermissions(role);

  useEffect(() => {
    if (!permissions.analytics.accessible) {
      history.push(defaultRoute);
    }
  }, [history, permissions.analytics.accessible]);

  const modelOptions = useMemo(() => {
    const license = _get(tenantUser, 'tenant.company.license', {});
    const availableModels = _get(license, 'analytics_entitlement.models', ['small']);
    const options = TYPES.map(item => ({
      ...item,
      disabled: !availableModels.includes(item.value),
    }));
    return options;
  }, [tenantUser]);

  useEffect(() => {
    getRegions();
  }, [getRegions]);

  const handleSubmit = useCallback(
    values => {
      if (!values.toggleLogs) {
        Modal.confirm({
          title: 'Disable Analytics will delete all logs. Are you sure to disable it?',
          okText: 'Yes',
          centered: true,
          onOk: () => {
            const oldResourceStatus = logStatusData.resourceStatus;
            props.updateLogStatus({ resourceStatus: RESOURCE_STATUS.PENDING });
            props.disableLog().catch(err => {
              props.updateLogStatus({ resourceStatus: oldResourceStatus });
            });
          },
        });
      } else {
        const { regionName, type, toggleLogs } = values;
        const region = regions.find(item => item.name === regionName);
        const logSettingData = {
          log_enable: toggleLogs,
          region_name: regionName,
          region_alias: _get(region, 'alias'),
          resource_model: type,
        };
        const oldResourceStatus = logStatusData.resourceStatus;
        props.updateLogStatus({ resourceStatus: RESOURCE_STATUS.PENDING });
        props.createLog(logSettingData).catch(err => {
          props.updateLogStatus({ resourceStatus: oldResourceStatus });
        });
      }
    },
    [logStatusData.resourceStatus, props, regions]
  );

  return (
    <Page>
      {permissions.analytics.accessible && (
        <LogConfigForm
          logStatusData={logStatusData}
          regionOptions={regions}
          modelOptions={modelOptions}
          onSubmit={handleSubmit}
          onRecreate={props.recreateLog}
        />
      )}
    </Page>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logs));
