import { Form, Input, Select, Tooltip } from 'antd';
import EditableTagGroup from 'components/EditableTagGroup';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPopModelOptions, getSiteNameRules } from 'utils/services/site.service';

const layoutOptions = {
  labelCol: { span: 6, xs: 24, sm: 6, md: 4 },
  wrapperCol: { span: 18, xs: 24, sm: 18, md: 20 },
};

const SiteInfo = forwardRef(({ data, cloudRegions, ...formOptions }, ref) => {
  const tenantUser = useSelector(state => state.globalSetting.user);
  const license = _get(tenantUser, 'tenant.company.license', {});

  const cloudProviders = useMemo(() => {
    const list = cloudRegions.reduce((result, item) => {
      const cp = item.edge;
      !result.includes(cp) && result.push(cp);
      return result;
    }, []);
    return list;
  }, [cloudRegions]);

  const siteNameRules = getSiteNameRules();
  const modelOptions = useMemo(() => getPopModelOptions(license), [license]);

  return (
    <Form {...layoutOptions} initialValues={data || {}} {...formOptions} ref={ref}>
      <Form.Item
        key="name"
        name="name"
        label="Site Name"
        rules={siteNameRules}
        validateTrigger="onBlur"
      >
        <Input />
      </Form.Item>

      <Form.Item key="description" name="description" label="Description">
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item
        key="type"
        name="resource_model"
        label="Type"
        rules={[{ required: true, message: 'This field is required.' }]}
      >
        <Select>
          {modelOptions.map((type, index) => (
            <Select.Option key={index} value={type.value} disabled={type.disabled}>
              <Tooltip title={type.disabled && 'This type is not supported by your license.'}>
                <span style={{ display: 'inline-block', width: '100%' }}>{type.label}</span>
              </Tooltip>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        key="cloud_provider"
        name="cloud_provider"
        label="Edge"
        rules={[{ required: true, message: 'This field is required.' }]}
      >
        <Select>
          {cloudProviders.map((cp, index) => (
            <Select.Option key={cp} value={cp}>
              <span style={{ display: 'inline-block', width: '100%' }}>{cp}</span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item key="tags" name="tags" label="Tags">
        <EditableTagGroup />
      </Form.Item>
    </Form>
  );
});

SiteInfo.propTypes = {
  data: PropTypes.object,
  cloudRegions: PropTypes.array.isRequired,
};

export default SiteInfo;
