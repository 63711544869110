import './Page.module.less';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Divider, PageHeader } from 'antd';
import TransButton from 'antd/lib/_util/transButton';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  breakpoint: state.globalSetting.breakpoint,
});
const Header = props => {
  const {
    // toolbar,
    children,
    className,
    styleName,
    breakpoint,
    dispatch,
    title,
    onBack,
    ...restProps
  } = props;
  // let mobileLayout = false;
  // switch (breakpoint) {
  //   case 'xs':
  //     mobileLayout = true;
  //     break;
  //   case 'xxl':
  //   case 'xl':
  //   case 'lg':
  //   case 'md':
  //   case 'sm':
  //   default:
  //     mobileLayout = false;
  // }
  const backButton = (
    <Fragment>
      <TransButton
        onClick={e => {
          if (onBack) {
            onBack(e);
          }
        }}
        className="ant-page-header-back-button"
        aria-label={'back'}
      >
        <ArrowLeftOutlined />
      </TransButton>
      <Divider type="vertical" />
    </Fragment>
  );

  const titleContent = (
    <Fragment>
      {onBack && backButton}
      {title}
    </Fragment>
  );

  return (
    <PageHeader
      title={titleContent}
      {...restProps}
      styleName={`page-header ${styleName || ''}`}
      className={`${className || ''}`}
    >
      {children}
    </PageHeader>
  );
};

export default connect(mapStateToProps)(Header);
