import './Wizard.module.less';

import { Steps } from 'antd';
import { getChildren } from 'components/shared/helpers';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { cloneElement, useCallback, useEffect, useMemo, useState } from 'react';

import Actions from './Actions';

const { Step } = Steps;

const Wizard = props => {
  const {
    steps: derivedSteps,
    currentStep,
    trackerOptions,
    position,
    onStepChange,
    children,
    style,
    ...options
  } = props;

  const [steps, setSteps] = useState([]);
  const [current, setCurrentStep] = useState(0);

  // initialize steps
  useEffect(() => {
    setSteps(derivedSteps);
  }, [derivedSteps]);

  const moveToStep = useCallback(
    targetStep => {
      onStepChange && typeof onStepChange === 'function' && onStepChange(targetStep);
      setCurrentStep(targetStep);
    },
    [onStepChange]
  );

  const handleStepChange = useCallback(
    async targetStep => {
      // validate the form before moving forward if a validation is required by the step,
      if (targetStep > current && derivedSteps[current].validator) {
        await derivedSteps[current].validator();
        moveToStep(targetStep);
      } else {
        moveToStep(targetStep);
      }
    },
    [current, derivedSteps, moveToStep]
  );

  // update step status if current step changed
  useEffect(() => {
    const updatedSteps = derivedSteps.map((step, index) => {
      let status;
      if (index > current) {
        status = 'wait';
      } else if (index === current) {
        status = 'process';
      } else {
        status = 'finish';
      }
      return {
        ...step,
        status,
      };
    });
    setSteps(updatedSteps);
  }, [current, derivedSteps]);

  useEffect(() => {
    if (Number.isInteger(currentStep) && currentStep > -1) {
      setCurrentStep(currentStep);
    }
  }, [currentStep]);

  const goPrev = useCallback(() => {
    handleStepChange(current - 1);
  }, [current, handleStepChange]);

  const goNext = useCallback(() => {
    handleStepChange(current + 1);
  }, [current, handleStepChange]);

  const actions = useMemo(
    () =>
      getChildren(children, child => {
        return child && child.type === Actions
          ? cloneElement(child, {
              goNext,
              goPrev,
              showPrev: current > 0,
              showNext: current < steps.length - 1,
            })
          : null;
      }),
    [current, children, goNext, goPrev, steps.length]
  );

  const otherChildren = getChildren(props.children, child => {
    return child && child.type !== Actions ? child : null;
  });

  // elements wrapped by <Wizard> tag have higher priority than the step.content
  const content =
    (!_isEmpty(otherChildren) && otherChildren) || (steps[current] && steps[current].content);

  const antdTrackerOptions = useMemo(
    () => ({
      ...trackerOptions,
      direction: position === 'left' || position === 'right' ? 'vertical' : 'horizontal',
      current: current,
      onChange: handleStepChange,
    }),
    [current, handleStepChange, position, trackerOptions]
  );
  return (
    <div styleName={`wizard wizard-${position || 'top'}`} style={style} {...options}>
      {
        // TODO: mobile-compatible
      }
      <Steps {...antdTrackerOptions}>
        {steps.map(({ content, validator, ...options }, index) => {
          return <Step key={index} {...options} disabled={options.status === 'wait'}></Step>;
        })}
      </Steps>
      <div styleName="wizard-context">
        {content}
        {actions}
      </div>
    </div>
  );
};

Wizard.Actions = Actions;

Wizard.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number,
  onStepChange: PropTypes.func,
  trackerOptions: PropTypes.object,
  position: PropTypes.oneOf([
    'top', // default
    'left',
    'right',
    'bottom',
  ]),
};

export default Wizard;
