import _get from 'lodash/get';
import { EDGE_ADMIN, SITE_ADMIN, SUPER_ADMIN, VIEWER } from 'pages/Settings/Users/user.service';
import PropTypes from 'prop-types';

const usePermissions = role => {
  switch (role[0]) {
    case SUPER_ADMIN:
      return {
        analytics: {
          accessible: true,
        },
        security: {
          accessible: true,
          createSite: true,
          enterSite: true,
          editSite: true,
          terminateSite: true,
        },
        automation: true,
        user: {
          createUser: true,
          deleteUser: true,
          editUser: {
            profile: true,
            role: true,
            site: true,
            password: false,
          },
        },
      };
    case EDGE_ADMIN:
      return {
        analytics: {
          accessible: true,
        },
        security: {
          accessible: true,
          createSite: false,
          enterSite: true,
          editSite: true,
          terminateSite: false,
        },
        automation: false,
        user: {
          createUser: false,
          deleteUser: false,
          editUser: {
            profile: true,
            role: false,
            site: true,
            password: true,
          },
        },
      };
    case SITE_ADMIN:
      return {
        analytics: {
          accessible: false,
        },
        security: {
          accessible: true,
          createSite: false,
          enterSite: true,
          editSite: true,
          terminateSite: false,
        },
        automation: false,
        user: {
          createUser: false,
          deleteUser: false,
          editUser: {
            profile: false,
            role: false,
            site: false,
            password: false,
          },
        },
      };
    case VIEWER:
    default:
      return {
        analytics: {
          accessible: false,
        },
        security: {
          accessible: false,
          createSite: false,
          enterSite: false,
          editSite: false,
          terminateSite: false,
        },
        automation: false,
        user: {
          createUser: false,
          deleteUser: false,
          editUser: {
            profile: false,
            role: false,
            site: false,
            password: false,
          },
        },
      };
  }
};

usePermissions.propTypes = {};

export default usePermissions;
