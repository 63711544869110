import './Site.module.less';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Spin, Tag, Tooltip } from 'antd';
import api from 'api';
import Exception from 'components/Exception';
import Page from 'components/Page';
import SvgIcon from 'components/SvgIcon';
import _get from 'lodash/get';
import queryString from 'query-string';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSiteInfo } from 'redux/Security/Security.actions';
import { getPath } from 'routeConfig';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import SiteInfo from '../SiteList/SiteInfo';

const defaultModalConfig = {
  centered: true,
  autoFocusButton: null,
  maskClosable: false,
};

const mapStateToProps = state => ({
  user: state.globalSetting.user,
});

const mapDispatchToProps = dispatch => ({
  getSiteInfo: siteId => dispatch(getSiteInfo(siteId)),
});

class Site extends Component {
  state = {
    site: {},
    url: null,
    logoutUrl: null,
    tabLogoutUrl: null,
    tabSetCookieUrl: null,

    // loading: true,
    iFrameLoadError: false,
    contentReady: false,
    modalConfig: {
      ...defaultModalConfig,
      visible: false,
      title: null,
      content: null,
      okText: null,
      onOk: null,
      onCancel: null,
    },
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    this.getSiteInfo(id).subscribe(
      ({ ui_mode }) => {
        // the backend side will provide the proper uri for accessing the right FOS light ui
        // e.g. /ui-ng, /ui-pro, /ui-ga etc.
        let baseURL = ui_mode;

        this.setState({ url: `${baseURL}/?site_id=${id}` });
      },
      err => this.setState({ iFrameLoadError: true }),
      () => this.setState({ loading: false })
    );
  }

  getSiteInfo(id) {
    return from(this.props.getSiteInfo(id)).pipe(
      map(res => _get(res, 'data.data', {})),
      tap(site => this.setState({ site }))
    );
  }

  updateSite(snapshot) {
    const { match } = this.props;
    const { id } = match.params;

    api.updateSite(id, { snapshot }).finally(() => {
      this.props.history.push(getPath('site-list'));
    });
  }

  onBack = () => {
    // const isMobile = isMobileDevice();
    // if (this.state.url && !this.state.iFrameLoadError && !isMobile) {
    //   this.screenshot();
    // } else {
    this.props.history.push(getPath('site-list'));
    // }
  };

  openTab = () => {
    const { match } = this.props;
    const { id } = match.params;
    const { ui_mode } = this.state.site;
    // the backend side will provide the proper uri for accessing the right FOS light ui
    // e.g. /ui-ng, /ui-pro, /ui-ga etc.
    let baseURL = ui_mode;

    window.open(`${baseURL}/?site_id=${id}`);
  };

  routeToSiteEdit() {
    const { site } = this.state;
    const path = getPath('site-detail', { id: site.id });
    const query = queryString.stringify({ name: site.name });
    const url = `${path}?${query}`;
    this.props.history.push(url);
  }

  routeToNetworkAccessInfo() {
    const { site } = this.state;
    const path = getPath('site-network-access-info', { id: site.id });
    this.props.history.push(path);
  }

  renderToolBar() {
    const { site, url } = this.state;
    return (
      <Fragment>
        <Tooltip placement="left" title="Open in a new Tab">
          {url && <SvgIcon type="expand" onClick={this.openTab} style={{ marginLeft: 10 }} />}
        </Tooltip>

        <Tooltip placement="left" title="Details">
          {site.id && (
            <SvgIcon
              type="detail"
              onClick={() => this.routeToSiteEdit()}
              style={{ marginLeft: 10 }}
            />
          )}
        </Tooltip>
        <Tooltip placement="left" title="Network Access Info">
          {site.id && (
            <InfoCircleOutlined
              onClick={() => this.routeToNetworkAccessInfo()}
              style={{ marginLeft: 10 }}
            />
          )}
        </Tooltip>
      </Fragment>
    );
  }
  showModal = config => {
    const modalConfig = {
      ...defaultModalConfig,
      ...config,
    };
    this.setState({ modalConfig });
  };

  showNetworkInfoModal = () => {
    const { site } = this.state;
    const siteName = site.name;
    const siteId = site.id;
    const title = `Network Access Information`;
    const modalWidth = 900;
    const modalHeight = 650;
    const windowHeight = window.innerHeight;

    this.showModal({
      visible: true,
      title: `${title} for ${siteName}`,
      width: '95%',
      style: {
        maxWidth: modalWidth,
        maxHeight: '95vh',
      },
      bodyStyle: {
        maxHeight: 'calc(95vh - 55px)',
        minHeight: `calc(${
          0.95 * windowHeight > modalHeight ? modalHeight + 'px' : '95vh'
        } - 55px)`,
        overflow: 'auto',
      },
      content: <SiteInfo siteId={siteId} siteName={siteName} />,
      footer: null,
      destroyOnClose: true,
      onCancel: () => {
        this.setState(state => (state.modalConfig.visible = false));
      },
    });
  };

  render() {
    const { site, url, iFrameLoadError, loading, modalConfig } = this.state;

    const tags = _get(site, 'tags', []) || [];

    const toolbar = this.renderToolBar();

    return (
      <Page>
        <Page.Header
          title={_get(site, 'name', '')}
          subTitle={_get(site, 'description', '')}
          tags={tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
          extra={toolbar}
          onBack={this.onBack}
        />

        <section className="flex-col flex-item pos-relative">
          <Spin spinning={loading} styleName="spinner" />
          {url && (
            <iframe
              title="Site"
              width="100%"
              height="100%"
              styleName={`frame ${this.state.iFrameLoadError ? 'error' : ''}`}
              src={url}
            />
          )}

          {iFrameLoadError && <Exception />}
        </section>

        <Modal {...modalConfig} afterClose={this.resetModal}>
          {modalConfig.content}
        </Modal>
      </Page>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Site));
