export const REMOTE_USER_LOGOUT = 'REMOTE_USER_LOGOUT';
export const UPDATE_TOKENS = 'UPDATE_TOKENS';
export const UPDATE_TOKEN_EXPIRED = 'UPDATE_TOKEN_EXPIRED';
export const UPDATE_AUTHENTICATED = 'UPDATE_AUTHENTICATED';

export const setAuthenticated = isAuthenticated => ({
  type: UPDATE_AUTHENTICATED,
  payload: { isAuthenticated },
});

export const updateTokenExpired = exipred => ({
  type: UPDATE_TOKEN_EXPIRED,
  payload: { tokenExpired: exipred },
});

export const cacheToken = ({ access_token, refresh_token }) => ({
  type: UPDATE_TOKENS,
  payload: { accessToken: access_token, refreshToken: refresh_token },
});

export const logout = () => ({
  type: REMOTE_USER_LOGOUT,
});
