import request from './request';

const resource = '/v1/portal/remote-users';

function getRemoteUserSites(end_user_id, ...cb) {
  return request(
    {
      url: `${resource}/${end_user_id}/sites`,
    },
    ...cb
  );
}

// query if the tenant is an advanced user
// and get the available site names
function getTenantSites(account) {
  return request({
    url: `${resource}/tenant/${account}`,
  }).then(({ data }) => Promise.resolve(data));
}

function login(payload, ...cb) {
  return request(
    {
      url: `${resource}/login`,
      method: 'post',
      data: payload,
    },
    ...cb
  );
}

function activateAccount(remote_user_id, payload, token, ...cb) {
  return request(
    {
      url: `${resource}/${remote_user_id}/registration`,
      headers: getHeaders(token),
      method: 'patch',
      data: payload,
    },
    ...cb
  );
}

function resetPassword(remote_user_id, payload, token, ...cb) {
  return request(
    {
      url: `${resource}/${remote_user_id}/reset`,
      headers: getHeaders(token),
      method: 'patch',
      data: payload,
    },
    ...cb
  );
}

function changePassword(remote_user_id, payload, ...cb) {
  return request(
    {
      url: `${resource}/${remote_user_id}/password`,
      method: 'patch',
      data: payload,
    },
    ...cb
  );
}

function getHeaders(token) {
  return {
    Authorization: 'Bearer ' + token,
  };
}

/**
 * verify if a access_token is available for registration/resetting password
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-10-31
 * @param {string} type "register"|"reset_password"
 * @param {string} token access_token
 * @param {callback} successCallback
 * @param {callback} errorCallback
 */
function verifyToken(type, token, ...cb) {
  return request(
    {
      url: '/v1/auth/verify',
      method: 'post',
      data: { type, token },
    },
    ...cb
  );
}

export default {
  getRemoteUserSites,
  login,
  activateAccount,
  resetPassword,
  changePassword,
  verifyToken,
  getTenantSites,
};
