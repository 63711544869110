import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import { SUPER_ADMIN } from 'pages/Settings/Users/user.service';
import io from 'socket.io-client';
import { refreshToken } from 'utils/fetch';
import storage from 'utils/storage';

import onNotification from './socket.notification';
import onSysBroadcast from './socket.sysBroadcast';

export const UPDATE_SOCKET_STATUS = 'UPDATE_SOCKET_STATUS';

let socket;

export const connectSocket = () => (dispatch, getState) => {
  console.log('dispatch connectSocket actions');
  if (socket && socket.connected) return;

  const { user } = getState().globalSetting;

  socket = io({ query: `token=${sessionStorage.getItem('access_token')}` });
  window.socket = socket;
  console.log('socket', socket);

  socket.on('connect', () => {
    console.log('socket connected ====================');
    dispatch(updateSocketStatus(true));
  });

  socket.on('disconnect', reason => {
    console.log('socket disconnect *******************', reason);
    dispatch(updateSocketStatus(false));

    if (reason !== 'io client disconnect') {
      socket.connect();
    }
  });

  /* extra error logs start*/
  socket.on(
    'connect_error',
    _throttle(error => {
      console.log('socket connect_error ///////////', error);
      socket.connect();
    }, 3000)
  );

  socket.on('error', error => {
    console.log('socket error ~~~~~~~~~', error);
    if (error.type === 'UnauthorizedError') {
      refreshToken(() => dispatch(connectSocket()));
    }
  });
  /* extra error log end */

  socket.on(`notification_${user.tenant_id}`, response => {
    const isSuperAdmin = _get(storage.get('globalSetting'), 'user.role[0]') === SUPER_ADMIN;
    isSuperAdmin && onNotification(response, dispatch, getState);
  });

  socket.on('system_notification', response => onSysBroadcast(response, dispatch, getState));
};

export const closeSocket = () => {
  socket && socket.close();
};

export const updateSocketStatus = connected => ({
  type: UPDATE_SOCKET_STATUS,
  payload: { connected },
});
