import api from 'api';
import _get from 'lodash/get';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_SITE_LIST_PAGE = 'UPDATE_SITE_LIST_PAGE';
export const UPDATE_SITE_LIST_PAGE_SIZE = 'UPDATE_SITE_LIST_PAGE_SIZE';
export const UPDATE_SITE_LIST_SORT = 'UPDATE_SITE_LIST_SORT';
export const UPDATE_SITE_LIST_TOTAL = 'UPDATE_SITE_LIST_TOTAL';
export const UPDATE_SITE_LIST = 'UPDATE_SITE_LIST';
export const UPDATE_SITE_STATUS = 'UPDATE_SITE_STATUS';
export const UPDATE_SITE_REMAINING_SEATS = 'UPDATE_SITE_REMAINING_SEATS';
export const UPDATE_TURBO_SITE_REMAINING_SEATS = 'UPDATE_SITE_REMAINING_SEATS';

export const getSiteList = (params = {}) => (dispatch, getState) => {
  switch (true) {
    case params.hasOwnProperty('page') && params.page > 0:
      dispatch(updateSiteListPage(params.page));
      break;
    case params.hasOwnProperty('size') && params.page > 0:
      dispatch(updateSiteListPageSize(params.size));
      break;
    case params.hasOwnProperty('sort'):
      dispatch(updateSiteListSort(params.sort));
      break;
    default:
  }

  const { currentPage, pageSize, sort } = getState().security;
  dispatch(updateLoading(true));
  api
    .getSites({ page: currentPage, max_results: pageSize, sort, ...params })
    .then(({ data: resData }) => {
      const sites = _get(resData, 'data', []);
      const total = _get(resData, 'meta.total', 0);
      const remainingSeats = _get(resData, 'meta.standalone_seat', 0);
      const remainingTurobSeats = _get(resData, 'meta.turbo_seat', 0);
      dispatch(updateSiteList(sites));
      dispatch(updateSiteListTotal(total));
      dispatch(updateLoading(false));
      dispatch(updateSiteRemainingSeats(remainingSeats));
      dispatch(updateTurboSiteRemainingSeats(remainingTurobSeats));
    })
    .catch(err => dispatch(updateLoading(false)));
};

export const getSiteInfo = siteId => dispatch => {
  return api.getSite(siteId).then(res => {
    const site = _get(res, 'data.data', {});
    dispatch(updateSiteStatus(site));
    return Promise.resolve(res);
  });
};

export const updateLoading = loading => ({ type: UPDATE_LOADING, payload: { loading } });

export const updateSiteListPage = currentPage => ({
  type: UPDATE_SITE_LIST_PAGE,
  payload: { currentPage },
});

export const updateSiteListPageSize = pageSize => ({
  type: UPDATE_SITE_LIST_PAGE_SIZE,
  payload: { pageSize },
});

export const updateSiteListSort = sort => ({
  type: UPDATE_SITE_LIST_SORT,
  payload: { sort },
});

export const updateSiteListTotal = total => ({
  type: UPDATE_SITE_LIST_TOTAL,
  payload: { total },
});

export const updateSiteRemainingSeats = remainingSeats => ({
  type: UPDATE_SITE_REMAINING_SEATS,
  payload: { remainingSeats },
});

export const updateTurboSiteRemainingSeats = remainingTurboSeats => ({
  type: UPDATE_TURBO_SITE_REMAINING_SEATS,
  payload: { remainingTurboSeats },
});

export const updateSiteList = sites => ({
  type: UPDATE_SITE_LIST,
  payload: { sites },
});

export const updateSiteStatus = site => ({
  type: UPDATE_SITE_STATUS,
  payload: site,
});

export const changeSiteStatus = (id, status) => dispatch =>
  api.changeSiteStatus(id, status, res => {
    const pendingItem = _get(res, 'data.data', null);
    if (!pendingItem) {
      dispatch(getSiteList());
      return;
    }
    dispatch(updateSiteStatus(pendingItem));
  });

export const createSite = payload => dispatch => {
  return api.createSite(payload).then(res => {
    const siteData = _get(res, 'data.data', {});
    dispatch(getSiteList());
    return siteData;
  });
};

export const patchSite = (siteId, payload) => dispatch => {
  return api.updateSite(siteId, payload, res => {
    const siteData = _get(res, 'data.data', null);
    dispatch(updateSiteStatus(siteData));
  });
};

export const deleteSite = site => dispatch =>
  api.deleteSite(site.id, res => {
    const pendingItem = _get(res, 'data.data', null);
    if (!pendingItem || pendingItem.resource_status === 'terminated') {
      dispatch(getSiteList());
      return;
    }
    dispatch(updateSiteStatus(pendingItem));
  });

export const deleteSites = sites => dispatch =>
  api.deleteSites(
    sites.map(item => item.id),
    res => {
      dispatch(getSiteList());
    },
    err => {
      throw err;
    }
  );

export const recreateSite = site => dispatch =>
  api.recreateSite(site.id).then(res => {
    const site = _get(res, 'data.data', null);
    dispatch(updateSiteStatus(site));
  });

export const checkTurboSiteSyncStatus = id => dispatch =>
  api.checkTurboSiteSyncStatus(id).then(res => {
    const site = _get(res, 'data.data', null);
    site && dispatch(updateSiteStatus(site));
  });
