import apitoken from './apitoken.api';
import auth from './auth.api';
import fortinetOne from './fortinetOne.api';
import infra from './infra.api';
import role from './role.api';
import scope from './scope.api';
import setting from './setting.api';
import site from './site.api';
import siteApplication from './siteApplication.api';
import sso from './sso.api';
import system from './system.api';
import user from './user.api';

export default {
  ...auth,
  ...fortinetOne,
  ...user,
  ...site,
  ...role,
  ...system,
  ...scope,
  ...apitoken,
  ...infra,
  ...setting,
  ...sso,
  ...siteApplication,
};
