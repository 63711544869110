import './CreateTurboSite.module.less';

import { Alert, Statistic } from 'antd';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';

import Review from './Review';

const Result = props => {
  const { data, cloudRegions, onRedirect } = props;

  const formatted = useMemo(() => {
    const config = _get(data, 'config');
    const slaves = _get(data, 'config.slaves', []);

    return {
      ...data,
      config: {
        ...config,
        slaves: slaves
          .reduce((collection, { sites }) => [...collection, ...sites], [])
          .map(item => ({
            region_name: item.region_name,
            number: 1,
            ipsecgateway_enable: item.ipsecgateway_enable,
            sites: [item],
            ipsecgateway_customer_config: item.ipsecgateway_customer_config,
          })),
      },
    };
  }, [data]);

  return (
    <div>
      <Alert
        message={`Your site: ${data.name} is now launching`}
        description={
          <Fragment>
            <p>
              It will take a few minutes until it is in the <strong>running</strong> state. Once
              your site is in the <strong>running</strong> state, it will be ready for you to use.
            </p>
            <br />
            <div>
              Redirecting to <strong>Security</strong> in{' '}
              {
                <span styleName="countdown">
                  <Statistic.Countdown
                    title={null}
                    format="s"
                    value={Date.now() + 4 * 1000}
                    onFinish={onRedirect}
                    valueStyle={{ fontSize: 16 }}
                  />
                </span>
              }{' '}
              seconds.
            </div>
          </Fragment>
        }
        type="success"
        showIcon
        style={{ width: '100%' }}
      />

      <Review data={formatted} cloudRegions={cloudRegions} style={{ marginTop: 40 }} />
    </div>
  );
};

Result.propTypes = {
  data: PropTypes.object.isRequired,
  onRedirect: PropTypes.func.isRequired,
};

export default Result;
