import { HomeOutlined } from '@ant-design/icons';
import _get from 'lodash/get';
import Logout from 'pages/Logout/Logout';
import SiteInfo from 'pages/Security/SiteList/SiteInfo';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import en from './Languages/en.json';
import Analytics from './pages/Analytics';
import Login from './pages/Login';
import PrePortal from './pages/Login/PrePortal';
import SSOAccountList from './pages/Login/SSOAccountList';
import {
  CreateStandaloneSite,
  CreateTurboSite,
  Site,
  SiteApplications,
  SiteDetail,
  SiteList,
} from './pages/Security';
import AccessTokens from './pages/Settings/AccessTokens';
import Logs from './pages/Settings/Logs';
import User from './pages/Settings/Users';

/**
 * Centerilized route configurations
 * All route definitions should be retrievable by id, which means an id is required when difining a route.
 * As path is exposed, it might be changed over time. Therefore, we need id as an internal identity to retrive routes.
 */

export const routeConfig = [
  // HOME
  {
    id: 'home',
    path: '/',
    exact: true,
    breadcrumb: () => <HomeOutlined />,
  },
  // LOGOUT
  {
    id: 'logout',
    path: '/logout',
    exact: true,
    component: Logout,
    breadcrumb: null,
    public: true,
  },
  // LOGIN
  {
    id: 'login',
    path: '/login',
    exact: true,
    component: Login,
    breadcrumb: null,
    public: true,
  },
  {
    id: 'sso-login',
    path: '/sso-login',
    exact: true,
    component: SSOAccountList,
    breadcrumb: null,
    public: true,
  },
  {
    id: 'pre-portal',
    path: '/pre-portal',
    exact: true,
    component: PrePortal,
    breadcrumb: null,
    public: true,
  },
  // ANALYTICS
  {
    id: 'analytics',
    path: '/analytics',
    component: Analytics,
    breadcrumb: en.TERMS.ANALYTICS,
  },
  // SETTINGS
  {
    id: 'settings',
    path: '/settings',
    exact: true,
    breadcrumb: en.TERMS.SETTING_PLURAL,
    routes: [
      {
        id: 'users',
        path: '/users',
        component: User,
        breadcrumb: en.TERMS.USER_PLURAL,
      },
      {
        id: 'faz-config',
        path: '/analytics',
        component: Logs,
        breadcrumb: en.TERMS.ANALYTICS,
      },
      {
        id: 'access-tokens',
        path: '/access_tokens',
        component: AccessTokens,
        breadcrumb: en.TERMS.ACCESS_TOKENS,
      },
    ],
  },
  {
    id: 'security',
    path: '/security',
    exact: true,
    breadcrumb: null, //TERMS.SECURITY,
    routes: [
      {
        id: 'site-list',
        path: '/site',
        component: SiteList,
        exact: true,
        breadcrumb: en.TERMS.SITE,
        routes: [
          {
            id: 'create-turbo-site',
            path: '/new-turbo',
            component: CreateTurboSite,
            exact: true,
            breadcrumb: 'New Turbo Site',
          },
          {
            id: 'site-applications',
            path: '/:id/site-applications',
            component: SiteApplications,
            exact: true,
            breadcrumb: 'Add Application',
          },
          {
            id: 'site-detail',
            path: '/:id/edit',
            component: SiteDetail,
            exact: true,
            breadcrumb: 'Detail',
          },
          {
            id: 'site-network-access-info',
            path: '/:id/network',
            component: SiteInfo,
            exact: true,
            breadcrumb: 'Network Access Info',
          },
          {
            id: 'site-fos',
            path: '/:id',
            component: Site,
            exact: true,
            breadcrumb: ({ match }) => {
              const BreadcrumbItem = props => {
                const sites = useSelector(state => state.security.sites);
                const targetSite = sites.find(({ id }) => id === match.params.id);
                const siteName = _get(targetSite, 'name', '');
                return <span>{siteName}</span>;
              };
              return <BreadcrumbItem />;
            },
          },
        ],
      },
    ],
  },
];

const routes = generateRoutes(routeConfig);

/**
 * flatten the route config and generate a full list of routes
 *
 *
 * @param {object} config
 * @param {array} routes
 * @param {string} parentPath
 * @returns {array}
 */
function generateRoutes(config, routes, parentPath) {
  routes = routes || [];
  config.forEach(route => {
    if (!route.path) {
      console.error('Missing route path:', route);
      return;
    }
    if (!route.path.startsWith('/')) {
      console.error(`Invalid route path: ${route.path}. A valid path should start with /`, route);
      return;
    }
    if (!route.id) {
      console.warn('id is required for the route to be retrievable.', route);
    }
    const path = parentPath ? parentPath + route.path : route.path;
    routes.push({ ...route, path });
    if (route.routes) {
      generateRoutes(route.routes, routes, path);
    }
  });
  return routes;
}

export function getRouteById(routeId) {
  return routes && routes.find(({ id }) => routeId === id);
}

export function getPath(routeId, params) {
  const route = getRouteById(routeId);
  if (route) {
    const path = generatePath(route.path, params);
    return path;
  }

  return '#';
}

export const defaultRoute = getPath('site-list');
export const loginPath = getPath('login');
export const logoutPath = getPath('logout');

export default routes;
