import api from 'api';
import { ipv4 } from 'ip-address-utils';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { map } from 'rxjs/operators';
import { toStream } from 'utils/util';

/**
 * get default IPsec VPN config
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-07-09
 * @export
 * @returns stream
 */
export function fetchDefaultIpsecConfig() {
  return api.getDefaultIpsecConfig().then(res => Promise.resolve(_get(res, 'data', {})));
}

/**
 * fetch default IP ranges
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-07-09
 * @export
 * @param {*} address
 * @returns
 */
export function fetchDefaultIpRanges(address) {
  return api
    .getDefaultIpRanges(address)
    .then(res => Promise.resolve(_get(res, 'data.data.ip_range', [])));
}

/**
 * validate IP range:
 * 1. check if start IP & end IP are valid IP address
 * 2. check if end IP is greater than start IP
 * 3. check if IP range conflicts with the interface IP address
 * 4. check if the iprange is within the subnet
 *
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-07-08
 * @export
 * @param {*} subnet
 * @param {*} startIp
 * @param {*} endIp
 */
export function validateIpRange(subnet, startIp, endIp) {
  const ipRange = `${startIp}-${endIp}`;
  const startIpDecimal = ipv4.toDecimal(startIp);
  const endIpDecimal = ipv4.toDecimal(endIp);
  const ipDecimal = ipv4.toDecimal(subnet.split('/')[0]);

  if (ipDecimal >= startIpDecimal && ipDecimal <= endIpDecimal) {
    throw new Error('IP conflicts with the interface IP address.');
  }

  try {
    ipv4.validateIpRange(ipRange, { subnet });
  } catch (err) {
    throw err;
  }
}

/**
 * check if a given IP range list is overlapped
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-07-09
 * @export
 * @param {*} ranges
 * @returns
 */
export function checkRangesOverlapping(ranges) {
  ranges.sort((a, b) => ipv4.toDecimal(a.start_ip) - ipv4.toDecimal(b.start_ip));
  let prevEnd = ipv4.toDecimal(ranges[0].end_ip);

  // ranges is overlapped if any range's end IP decimal
  // is smaller than any other range's start IP decimal
  const isOverlapped = ranges.slice(1).some(item => {
    const thisStart = ipv4.toDecimal(item.start_ip);
    const thisEnd = ipv4.toDecimal(item.end_ip);
    if (thisStart <= prevEnd) {
      return true;
    } else {
      prevEnd = thisEnd;
      return false;
    }
  });

  return isOverlapped;
}

/**
 * check & fix site VPN data before submitting
 *
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-07-08
 * @export
 * @param {*} data
 * @returns
 */
export function checkVpnData(data) {
  if (!data.ipsecgateway_enable) {
    delete data.ipsecgateway_customer_config;
  }

  const subnet = _get(data, 'ipsecgateway_customer_config.cloud.interface[0].ip', '');
  const ipranges = _get(
    data,
    'ipsecgateway_customer_config.cloud.interface[0].dhcp_server.ip_range',
    []
  );
  if (!subnet && ipranges.some(item => item.start_ip && item.end_ip)) {
    //TODO: get the default value from the backend
    _set(data, 'ipsecgateway_customer_config.cloud.interface[0].ip', '192.168.1.99/24');
  }

  if (data.ipsecgateway_customer_config) {
    data.ipsecgateway_customer_config.cloud.interface = data.ipsecgateway_customer_config.cloud.interface.map(
      item => {
        if (item.dhcp_server) {
          item.dhcp_server.ip_range = item.dhcp_server.ip_range.filter(
            range => range.start_ip && range.end_ip
          );
        }
        return item;
      }
    );
  }

  return data;
}
