import './modal.module.less';
import '@ant-design/compatible/assets/index.css';

import { Form } from '@ant-design/compatible';
import { Button, Checkbox, DatePicker, Input, Modal } from 'antd';
import api from 'api';
import React, { PureComponent } from 'react';

const formOptions = {
  labelCol: { span: 5, xs: 24, sm: 5 },
  wrapperCol: { span: 19, xs: 24, sm: 19 },
};

const initState = {
  createTokenLoading: false,
  postScope: [],
  tokenName: '',
  expiration: null,
};

class AddTokenModal extends PureComponent {
  state = { ...initState };

  afterClose = () => {
    this.setState(initState);
  };

  handleCancel = e => {
    this.props.setModalOpenState(false);
  };

  handleCreate = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.createToken(values.tokenName);
      }
    });
  };

  createToken = tokenName => {
    this.setState({ createTokenLoading: true });
    const { postScope, expiration } = this.state;
    const scopeData = {
      name: tokenName.trim(),
      scope: postScope,
      expiration: expiration ? expiration.toDate() : null,
    };

    api.createAccessToken(
      scopeData,
      ({ data: resData }) => {
        this.setState({ createTokenLoading: false });
        this.props.setApiAccessToken(resData.data.api_access_token);
        this.props.setModalOpenState(false);
        this.props.getAccessTokens();
      },
      error => {
        this.setState({ createTokenLoading: false });
        this.props.setApiAccessToken();
      }
    );
  };

  tokenNameChange = e => {
    this.setState({ tokenName: e.target.value });
  };

  expirationChange = date => {
    this.setState({ expiration: date ? date.endOf('day') : date });
  };

  scopeItemChange(checked, key, pattern) {
    const postScopeItem = `${key}:${pattern}`;
    let postScope = [...this.state.postScope];

    if (checked) {
      postScope.push(postScopeItem);
      postScope = [...new Set(postScope)];
    } else {
      postScope = postScope.filter(item => item !== postScopeItem);
    }

    this.setState({ postScope });
    this.postScope = postScope;
  }

  validateSameTokenName = (rule, value, callback) => {
    const found = this.props.accessTokensData.find(item => item.name === value.trim());
    if (found) {
      callback('You enter the same token name ');
    } else {
      callback();
    }
  };

  validatePostScope = (rule, value, callback) => {
    if (!this.postScope || this.postScope.length === 0) {
      callback('Choose at least one scope');
    } else {
      callback();
    }
  };

  renderScopes(key, scopeItem) {
    return (
      <div key={key} style={{ marginBottom: 12 }}>
        <div style={{ lineHeight: 'initial' }}>
          <Checkbox
            onChange={e => this.scopeItemChange(e.target.checked, key, scopeItem.pattern)}
          />{' '}
          {scopeItem.name}
        </div>
        <div style={{ marginLeft: 20, lineHeight: 'initial' }}>
          {scopeItem.description || `${key} ---- description`}
        </div>
      </div>
    );
  }

  render() {
    const { createTokenLoading, expiration } = this.state;
    console.log('expireation', expiration);
    const { scope, visible } = this.props;
    const { getFieldDecorator } = this.props.form;
    const scopeKeys = Object.keys(scope);

    return (
      <Modal
        title="Create Personal Access Tokens"
        visible={visible}
        maskClosable={false}
        centered
        destroyOnClose
        afterClose={this.afterClose}
        onCancel={this.handleCancel}
        footer={[
          <Button key="cancel" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button
            type="primary"
            key="submit"
            loading={createTokenLoading}
            onClick={this.handleCreate}
          >
            Create
          </Button>,
        ]}
      >
        <Form {...formOptions}>
          <Form.Item label="Name">
            {getFieldDecorator('tokenName', {
              rules: [
                {
                  required: true,
                  message: 'Please input your personal token name',
                },
                {
                  validator: this.validateSameTokenName,
                },
              ],
            })(<Input style={{ width: 300 }} />)}
          </Form.Item>
          <Form.Item label="Expires at">
            <DatePicker
              disabledDate={currentDate => {
                const today = new Date();
                today.setDate(today.getDate() - 1);
                return currentDate < today;
              }}
              value={expiration}
              style={{ width: 300 }}
              onChange={this.expirationChange}
            />
          </Form.Item>
          <Form.Item label="Scopes" styleName="scopes-form">
            {getFieldDecorator('postScope', {
              rules: [
                {
                  validator: this.validatePostScope,
                },
              ],
            })(
              <div style={{ paddingTop: 12 }}>
                {scopeKeys.map(scopeKey => this.renderScopes(scopeKey, scope[scopeKey]))}
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({})(AddTokenModal);
