import { UPDATE_LOG_STATUS } from './logs.actions';

const defaultState = {
  logsStatus: false,
  resourceStatus: null,
  type: '',
  regionName: '',
  regionAlias: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_LOG_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
