import { InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Form, Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_SLAVES_QUOTA } from 'utils/constant';

import FederatedSitesConfig from '../components/FederatedSitesConfig';

export const MasterConfigForm = forwardRef(({ data, cloudRegions, disabled, onChange }, ref) => {
  return (
    <Form
      ref={ref}
      layout="inline"
      className="flex-row"
      style={{ margin: 16, marginTop: 24 }}
      initialValues={data || {}}
      onValuesChange={changedValues => onChange(changedValues)}
    >
      <Form.Item
        name="region"
        style={{ width: 225 - 16 }}
        rules={[{ required: true, message: 'Select a Cloud Region' }]}
      >
        <Select disabled={disabled} placeholder="Select a Cloud Region" style={{ width: 225 - 16 }}>
          {cloudRegions.map(el => (
            <Select.Option key={el.name} value={el.name}>
              {el.alias}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
});

const SiteRegionConfig = forwardRef((props, ref) => {
  const { data, cloudRegions } = props;
  const [master, setMaster] = useState(data.master);
  const [newSlaves, setNewSlaves] = useState(data.slaves || [{ region: null, count: 1 }]);

  const masterRef = useRef();
  const slavesRef = useRef();

  const remainingSiteSeats = useSelector(state => state.security.remainingSeats);

  const totalSlaves = useMemo(() => {
    const newSlavesCount = newSlaves.reduce((result, item) => {
      result += item.count || 0;
      return result;
    }, 0);

    return newSlavesCount;
  }, [newSlaves]);

  const slaveQuota = DEFAULT_SLAVES_QUOTA - totalSlaves;

  const leaderRegions = useMemo(() => {
    // leader can be selected from the federated sites regions
    return cloudRegions.filter(item => newSlaves.some(({ region }) => region === item.name));
  }, [cloudRegions, newSlaves]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      return Promise.all([
        masterRef && masterRef.current.validateFields(),
        slavesRef.current && slavesRef.current.validate(),
      ]).then(([master, slaves]) => {
        return Promise.resolve({ master, slaves });
      });
    },
    getValues: () => {
      const values = {
        master: masterRef.current.getFieldsValue(),
        slaves: slavesRef.current.getValues(),
      };

      return values;
    },
  }));

  // update leader region value when federated sites regions changed
  const handleChange = useCallback(values => {
    setNewSlaves(values);

    const reducedRegions = values.reduce((result, item) => {
      if (result.includes(item.region)) {
        return result;
      }
      result.push(item.region);
      return result;
    }, []);

    if (reducedRegions.length === 1) {
      masterRef.current.setFieldsValue({ region: reducedRegions[0] });
    }
  }, []);

  return (
    <div style={{ ...props.style }}>
      <Divider orientation="left">Federated Sites (Limit: {DEFAULT_SLAVES_QUOTA})</Divider>
      <FederatedSitesConfig
        ref={slavesRef}
        defaultItems={newSlaves}
        quota={slaveQuota}
        seats={remainingSiteSeats - 1}
        cloudRegions={cloudRegions}
        onChange={handleChange}
        minLength={1}
      />
      <br />
      <Divider orientation="left">
        Leader Region{/* <Tooltip title={''}>
          <InfoCircleOutlined />
        </Tooltip> */}
      </Divider>
      <MasterConfigForm
        ref={masterRef}
        data={master}
        cloudRegions={leaderRegions}
        onChange={values => setMaster(values)}
      />
    </div>
  );
});

SiteRegionConfig.propTypes = {
  data: PropTypes.object.isRequired,
  cloudRegions: PropTypes.array,
};

export default SiteRegionConfig;
