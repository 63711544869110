import { Form, Select, Switch, Tooltip } from 'antd';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from 'redux/GlobalSetting/globalSetting.actions';
import { RESOURCE_STATUS, TYPES } from 'utils/constant';
import useEntitlements from 'utils/hooks/useEntitlements';
import { defaultSorter } from 'utils/util';

const layoutOptions = {
  labelCol: { span: 6, xs: 24, sm: 6 },
  wrapperCol: { span: 18, xs: 24, sm: 18 },
};

const LogConfigForm = forwardRef(({ data, regionOptions, modelOptions, ...formOptions }, ref) => {
  return (
    <Form ref={ref} {...layoutOptions} initialValues={data || {}} {...formOptions}>
      <Form.Item
        key="region"
        name={['log_config', 'region_name']}
        label="Region"
        rules={[
          {
            required: true,
            message: 'Please Select a Region',
          },
        ]}
      >
        <Select style={{ width: 220 }}>{regionOptions}</Select>
      </Form.Item>

      <Form.Item
        key="type"
        name={['log_config', 'resource_model']}
        label="Type"
        rules={[
          {
            required: true,
            message: 'Please Select a Type',
          },
        ]}
      >
        <Select style={{ width: 220 }}>{modelOptions}</Select>
      </Form.Item>
    </Form>
  );
});

const LogConfig = forwardRef(({ data }, ref) => {
  const dispatch = useDispatch();
  const regions = useSelector(state => state.globalSetting.regions);
  const { entitledLogModels } = useEntitlements();
  const [isLogEnabled, setIsLogEnabled] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  const regionOptions = useMemo(
    () =>
      regions.sort(defaultSorter('alias')).map((region, index) => {
        const disabled = !region.log_available || region.disabled;
        let message;
        if (region.disabled) {
          message = 'This region is not supported by your license.';
        }

        if (!region.log_available) {
          message = region.log_tips;
        }

        return (
          <Select.Option key={index} value={region.name} disabled={disabled}>
            <Tooltip title={disabled && message}>
              <span style={{ display: 'inline-block', width: '100%' }}>{region.alias}</span>
            </Tooltip>
          </Select.Option>
        );
      }),
    [regions]
  );

  const modelOptions = useMemo(
    () =>
      TYPES.map(item => ({ ...item, disabled: !entitledLogModels.includes(item.value) })).map(
        (type, index) => (
          <Select.Option key={index} value={type.value} disabled={type.disabled}>
            <Tooltip title={type.disabled && 'This type is not supported by your license.'}>
              <span style={{ display: 'inline-block', width: '100%' }}>{type.label}</span>
            </Tooltip>
          </Select.Option>
        )
      ),
    [entitledLogModels]
  );

  const validate = useCallback(() => {
    if (isLogEnabled) {
      return (
        formRef &&
        formRef.current
          .validateFields()
          .then(values => Promise.resolve({ log_enable: true, ...values }))
      );
    } else {
      return new Promise((resolve, reject) => {
        resolve({ log_enable: false });
      });
    }
  }, [isLogEnabled]);

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <div className="flex-col flex-item" style={{ padding: '24px 0', overflow: 'auto' }}>
      <Form.Item
        label="Analytics"
        labelCol={{ span: 6, xs: 24, sm: 6 }}
        wrapperCol={{ span: 18, xs: 24, sm: 18 }}
      >
        <Switch checked={isLogEnabled} onChange={value => setIsLogEnabled(value)} />
      </Form.Item>
      {isLogEnabled && (
        <LogConfigForm
          ref={formRef}
          data={data}
          regionOptions={regionOptions}
          modelOptions={modelOptions}
        />
      )}
    </div>
  );
});

LogConfig.propTypes = {};

export default LogConfig;
