import './FailureDetail.module.less';

import { WarningOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Popover, Typography } from 'antd';
import SvgIcon from 'components/SvgIcon';
import _isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { toLocalTime } from 'utils/util';
const { Text, Paragraph } = Typography;

class FailureDetail extends Component {
  state = { popoverVisible: false };

  renderContent() {
    const {
      resourceFailedType,
      resourceFailedMessage,
      timestamp,
      action,
      isRecreatable,
    } = this.props.data;
    let messages;
    try {
      messages = JSON.parse(resourceFailedMessage);
    } catch (err) {
      console.log(err);
      // if the message cannot be parsed, display it as raw data
      messages = resourceFailedMessage;
    }

    if (isRecreatable && !this.props.onRecreate) {
      console.warn('missing recreate function');
    }

    return (
      <div styleName="failure-detail">
        <p>
          <Text strong>Failed Stage: </Text>{' '}
          <span className="capitalize">{resourceFailedType}</span> &nbsp;
          {isRecreatable && (
            <Button
              type="primary"
              size="small"
              onClick={() => this.props.onRecreate && this.props.onRecreate()}
            >
              <SvgIcon type="retry"></SvgIcon>
              Recreate
            </Button>
          )}
        </p>
        <p>
          <Text strong>Time: </Text> <span>{toLocalTime(timestamp)}</span>
        </p>
        <div styleName="error-detail">
          <Text strong>Detail: </Text>

          <ConfigProvider locale={{ Text: { expand: 'show more' } }}>
            <Paragraph ellipsis={{ rows: 1, expandable: true }}>
              {_isArray(messages) &&
                messages.map((message, index) => (
                  <Fragment key={index}>
                    • {message}
                    <br />{' '}
                  </Fragment>
                ))}

              {typeof messages === 'string' && messages}
            </Paragraph>
          </ConfigProvider>
        </div>
      </div>
    );
  }

  render() {
    const { popoverVisible } = this.state;
    const { iconStyle } = this.props;

    const content = this.renderContent();
    return (
      <Popover
        onVisibleChange={visible => this.setState({ popoverVisible: visible })}
        content={popoverVisible && content}
        overlayStyle={{ maxWidth: 400 }}
        mouseLeaveDelay={0.5}
        getPopupContainer={() => document.querySelector('.page-content')}
        arrowPointAtCenter={true}
      >
        &nbsp;
        <WarningOutlined style={iconStyle || { color: '#f5222d' }} />
      </Popover>
    );
  }
}

FailureDetail.propTypes = {
  data: PropTypes.object,
  onRecreate: PropTypes.func,
};

export default FailureDetail;
