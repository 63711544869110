import axios from 'axios';
import { fetchWrap } from 'utils/fetch';

const resource = '/v1/fortinetone';

/**
 * @description - get FortinetOne accounts by email
 * @author Stan Guo <shitaoguo@fortinet.com>
 * @date 2019-04-15
 * @param {string} email
 * @param {callback} successCallback
 * @param {callback} errorCallback
 * @returns
 */
function getFortinetOneAccounts(email, { auth_token, ...data }) {
  return axios({
    url: `${resource}/account/${email}`,
    headers: {
      'auth-token': auth_token,
    },
    baseURL: '/api',
    method: 'post',
    data,
  });
}

function getFortiOnePortals(xAuthToken, ...cb) {
  return fetchWrap(
    {
      url: `${resource}/portals`,
      headers: {
        'auth-token': xAuthToken,
      },
    },
    ...cb
  );
}

function getRegisterUrl(...cb) {
  return fetchWrap(
    {
      url: `${resource}/registration/url`,
    },
    ...cb
  );
}

export default {
  getFortinetOneAccounts,
  getFortiOnePortals,
  getRegisterUrl,
};
